import type { AMSCustomer, CoverageOption, Policy, User } from "./";

namespace PolicyTemplateTypes {
    export enum Unit {
        FLEET = "Fleet",
        NON_FLEET = "Non-Fleet",
        INDEPENDENT_CONTRACTOR = "Independent Contractor",
    }

    export enum Type {
        COMPOSITE = "Composite",
        SCHEDULED = "Scheduled",
        CAPTIVE = "Captive",
    }
}

export class PolicyTemplate {
    static Unit = PolicyTemplateTypes.Unit;
    static Type = PolicyTemplateTypes.Type;
    static accountId = "00000000000000000000000000000000" as const;
    static amsClientNumber = 0 as const;

    static getType(policyTemplate: PolicyTemplate): PolicyTemplate.Type | null {
        if (policyTemplate.isComposite) return PolicyTemplate.Type.COMPOSITE;
        if (policyTemplate.isScheduled) return PolicyTemplate.Type.SCHEDULED;
        if (policyTemplate.isCaptive) return PolicyTemplate.Type.CAPTIVE;
        return null;
    }

    static getUnit(policyTemplate: PolicyTemplate): PolicyTemplate.Unit | null {
        if (policyTemplate.isFleet) return PolicyTemplate.Unit.FLEET;
        if (policyTemplate.isNonFleet) return PolicyTemplate.Unit.NON_FLEET;
        if (policyTemplate.isIndependentContractor) return PolicyTemplate.Unit.INDEPENDENT_CONTRACTOR;
        return null;
    }

    static getCoverageOptionIds(policyTemplate: PolicyTemplate): number[] {
        if (policyTemplate.coverageOptionIds) return policyTemplate.coverageOptionIds;
        if (policyTemplate.coverageOptions) return policyTemplate.coverageOptions.map((x) => x.id);
        return [];
    }

    id: number;
    policyId: string;
    policyCreatedDate: Date;
    policy?: Policy;

    parentCustomerCode: string;
    parentCustomer?: AMSCustomer;

    writingCustomerCode?: string;
    writingCustomer?: AMSCustomer;

    coverageOptionIds: number[];
    coverageOptions?: CoverageOption[];

    states: string[];

    isFleet: boolean;
    isNonFleet: boolean;
    isIndependentContractor: boolean;

    isComposite: boolean;
    isScheduled: boolean;
    isCaptive: boolean;

    createdDate: Date;
    createdByGuid: string;
    createdBy?: User;
    updatedDate?: Date;
    updatedByGuid: string;
    updatedBy?: User;
}

export declare namespace PolicyTemplate {
    export type Unit = PolicyTemplateTypes.Unit;
    export type Type = PolicyTemplateTypes.Type;
}
