import { CoverageOption, Policy, Property } from "@deathstar/types/northstar";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { forwardRef, useEffect, useRef } from "react";
import api from "../../../api/api";
import { useAccountId } from "../../../api/useAccountId";
import { blazar } from "../../../api/util/blazar";
import { ResponseError } from "../../../api/util/exceptions";
import BackButton from "../../../components/back-button/BackButton";
import Unauthorized from "../../../components/error-screens/Unauthorized";
import Loader from "../../../components/loader/Loader";
import PolicyDecPage from "./PolicyDecPage";

const PolicyView = forwardRef<HTMLDivElement, { policyId: string }>(function ({ policyId }, ref) {
    const accountId = useAccountId();
    const {
        data: policy,
        error,
        isError,
        dataUpdatedAt,
    } = useQuery<Policy, ResponseError>({
        queryKey: ["policies", accountId!, policyId],
        queryFn: () => blazar.fetchJson<Policy>(`waypoint/orgs/${accountId!}/policies/${policyId}`),
        enabled: !!accountId! && !!policyId,

        retry: (count, error) => {
            return count < 3 && error.status !== 403 && error.status !== 404;
        },

        staleTime: 1000 * 60,
    });
    const { data: properties } = useQuery<Property[], ResponseError>({
        queryKey: ["properties", accountId!],
        queryFn: () => blazar.fetchJson<Property[]>(`waypoint/orgs/${accountId!}/properties`),
        enabled: !!accountId!,

        retry: (count, error) => {
            return count < 3 && error.status !== 403 && error.status !== 404;
        },

        staleTime: 1000 * 60,
    });

    const { data: organization } = api.organization.useById(accountId!);

    const hasTracked = useRef(false);
    useEffect(() => {
        if (!hasTracked.current && policy) {
            hasTracked.current = true;
            try {
                window.trace.trackEvent("dec_page_viewed", {
                    writing_company: policy.writingCompany?.name,
                    coverages: policy.coverages.map((c) => CoverageOption.getMetadata(c.coverageOptionId).defaultAbbreviation).join(","),
                    full_term_premium: policy.fullTermPremium,
                });
            } catch (e) {
                console.error(e);
            }
        }
    }, [policy, policyId]);

    if (isError) {
        if (error.status === 403) {
            return <Unauthorized />;
        }
        if (error.status === 404) {
            return (
                <div className="p-4 text-stone-800">
                    <BackButton />
                    <p>Oops! We can't seem to find that policy</p>
                </div>
            );
        }
    }

    if (!policy || !properties || !accountId || !organization) {
        return <Loader />;
    }

    return (
        <div ref={ref}>
            <p className="hidden text-right text-xs italic text-stone-600 print:block">
                Data retrieved {moment(dataUpdatedAt).toISOString()}
            </p>
            <PolicyDecPage policy={policy} properties={properties} company={organization.company} />
        </div>
    );
});
export default PolicyView;
