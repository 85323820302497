import { Expose } from "class-transformer";
import { IsArray, IsBoolean, IsDateString, IsInt, IsOptional, IsString, Length, Max, Min, ValidateBy } from "class-validator";
import { GlobalCertificateHolder } from "../northstar";

export type EquipmentRow<T> = Omit<T, "value" | "spare" | "acv" | "ownerOperator" | "comprehensive" | "collision">;

export class EquipmentDriverChangeDto {
    @Expose()
    @ValidateBy({
        name: "isArrayOfIDs",
        validator: {
            validate: (value: object[]) =>
                value.every(
                    (x) =>
                        "policyId" in x &&
                        typeof x["policyId"] === "string" &&
                        "coverageOptionId" in x &&
                        typeof x["coverageOptionId"] === "number" &&
                        (x["remove"] === undefined || typeof x["remove"] === "boolean" || x["remove"] === null)
                ),
            defaultMessage: () => "Each item must have an policyId and coverageOptionId",
        },
    })
    coverages: { policyId: string; coverageOptionId: number; remove?: boolean }[];

    @Expose()
    @IsDateString()
    atDate: string;
}

export class BusinessAutoDto extends EquipmentDriverChangeDto {
    @Expose()
    @IsOptional()
    @IsString()
    unitNumber: string;

    @Expose()
    @IsInt()
    @Max(new Date().getFullYear() + 5)
    @Min(1900)
    year: number;

    @Expose()
    @IsString()
    make: string;

    @Expose()
    @IsOptional()
    @IsInt()
    typeId: number;

    @Expose()
    @IsString()
    @Length(17, 17)
    vin: string;

    @Expose()
    @IsOptional()
    @IsInt()
    @Min(0)
    value: number;

    @Expose()
    @IsInt()
    propertyId: number;
}

export class TrailerDto extends BusinessAutoDto {
    @Expose()
    @IsBoolean()
    ownerOperator: boolean;

    @Expose()
    @IsOptional()
    @IsArray()
    @ValidateBy({
        name: "isArrayOfIDs",
        validator: { validate: (value: object[]) => value.every((x) => "id" in x), defaultMessage: () => "Each item must have an id" },
    })
    drivers: { id: number }[];

    @Expose()
    apdLossPayees?: ({ additionalInterestId?: number; remove?: boolean } & Partial<
        Pick<GlobalCertificateHolder, "name" | "street" | "street2" | "city" | "state" | "zip" | "fax" | "email">
    >)[];
}

export class TractorDto extends TrailerDto {
    @Expose()
    @IsBoolean()
    wpUserConfirmationIsNotPpvVanPickup: boolean;
}

export class ToolDto extends EquipmentDriverChangeDto {
    @Expose()
    @IsOptional()
    @IsInt()
    @Max(new Date().getFullYear() + 5)
    @Min(1900)
    year: number;

    @Expose()
    @IsString()
    make: string;

    @Expose()
    @IsString()
    model: string;

    @Expose()
    @IsString()
    serialNumber: string;

    @Expose()
    @IsOptional()
    @IsInt()
    @Min(0)
    value: number;

    @Expose()
    @IsOptional()
    @IsString()
    description: string;

    @Expose()
    @IsInt()
    propertyId: number;
}
