import { MotorCarrier } from "@deathstar/motor-carrier";
import { IftaQuarter, IftaQuarterUpload } from "@deathstar/types/northstar";
import { classNames, IFTAUploadDialog, Popover } from "@deathstar/ui";
import {
    ArrowUpTrayIcon,
    CheckIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    MagnifyingGlassCircleIcon as MagnifyingGlassCircleIconOutline,
    ScaleIcon as ScaleIconOutline,
} from "@heroicons/react/24/outline";
import { MagnifyingGlassCircleIcon as MagnifyingGlassCircleIconSolid, ScaleIcon as ScaleIconSolid } from "@heroicons/react/24/solid";
import { Tooltip } from "@material-ui/core";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useMemo, useRef, useState } from "react";
import { PrintSectionName } from "../Components/PrintSectionName";
import { Section } from "../Components/Section";
import { TitleGroup } from "../Components/TitleGroup";
import { STATE_FIPS } from "../Map/constants";
import { Map as MapElement, USMapOptions } from "../Map/Map";
import { MapLocationType, USMapLocation } from "../Map/types";
import { useMap } from "../Map/useMap";
import { pluralize } from "../utils/pluralize";
import { useIFTAsPageStore } from "./useIFTAsMap";

const FILL_COLORS = {
    light: "#d8f3dc",
    lightMedium: "#95d5b2",
    medium: "#52b788",
    mediumHeavy: "#40916c",
    heavy: "#1b4332",
    default: "#f7f9f6",
};

function IFTAsMap({ motorCarrier, options }: { motorCarrier: MotorCarrier; options?: USMapOptions }): JSX.Element {
    const isPrinting = useIFTAsPageStore((s) => s.isPrinting);
    const selectedIFTAs = useIFTAsPageStore((s) => s.selectedIFTAs);
    const showInspections = useIFTAsPageStore((s) => s.showInspections);
    const showCrashes = useIFTAsPageStore((s) => s.showCrashes);

    const totals = useMemo<Record<string, number>>(() => {
        return Object.values(STATE_FIPS).reduce((acc, { abbr }) => {
            const total = motorCarrier.iftas
                .filter((i) => selectedIFTAs.some((i2) => i2.year === i.year && i2.quarter === i.quarter))
                .reduce((a, i) => a + ((i[`miles${abbr}` as keyof IftaQuarter] as number) || 0), 0);
            acc[abbr] = total;
            return acc;
        }, {} as Record<string, number>);
    }, [motorCarrier.iftas, selectedIFTAs]);
    const totalMiles = Object.values(totals).reduce((a, t) => a + t, 0);

    const locations: USMapLocation[] = useMemo(() => {
        const locs: USMapLocation[] = [];

        if (motorCarrier.details.PhysicalCountyLatitude && motorCarrier.details.PhysicalCountyLongitude) {
            locs.push({
                lat: parseFloat(motorCarrier.details.PhysicalCountyLatitude),
                lng: parseFloat(motorCarrier.details.PhysicalCountyLongitude),
                type: MapLocationType.PrimaryTerminal,
                tooltip: "Primary Terminal",
            });
        }

        if (motorCarrier.inspections.total && showInspections) {
            const plots = Array.from(motorCarrier.inspections).reduce((map, insp) => {
                const lat = insp.get("CountyLatitude");
                const long = insp.get("CountyLongitude");

                if (!lat || !long) {
                    console.warn("Missing lat or long for inspection", insp);
                }
                const key = `${lat},${long}`;
                const current = map.get(key)?.total || 0;
                map.set(key, {
                    lat,
                    long,
                    total: current + 1,
                });
                return map;
            }, new Map<string, { lat: string; long: string; total: number }>());

            Array.from(plots.values())
                .sort((a, b) => b.total - a.total)
                .forEach(({ lat, long, total }) => {
                    if (!lat || !long) return;
                    locs.push({
                        lat: parseFloat(lat as string),
                        lng: parseFloat(long as string),
                        color: "orange",
                        size: getPlotRadius(total),
                        tooltip: `${total} ${pluralize(total, "inspection", "s")}`,
                    });
                });
        }

        if (motorCarrier.crashes.total && showCrashes) {
            const plots = Array.from(motorCarrier.crashes.filterByIncludedInSms()).reduce((map, insp) => {
                const lat = insp.get("CountyLatitude");
                const long = insp.get("CountyLongitude");

                if (!lat || !long) {
                    console.warn("Missing lat or long for inspection", insp);
                    return map;
                }
                const key = `${lat},${long}`;
                const current = map.get(key)?.total || 0;
                map.set(key, {
                    lat,
                    long,
                    total: current + 1,
                });
                return map;
            }, new Map<string, { lat: string; long: string; total: number }>());

            Array.from(plots.values())
                .sort((a, b) => b.total - a.total)
                .forEach(({ lat, long, total }) => {
                    if (!lat || !long) return;
                    locs.push({
                        lat: parseFloat(lat as string),
                        lng: parseFloat(long as string),
                        type: MapLocationType.Crash,
                        size: getPlotRadius(total),
                        tooltip: `${total} ${pluralize(total, "crash", "es")}`,
                    });
                });
        }

        return locs;
    }, [
        motorCarrier.crashes,
        motorCarrier.details.PhysicalCountyLatitude,
        motorCarrier.details.PhysicalCountyLongitude,
        motorCarrier.inspections,
        showCrashes,
        showInspections,
    ]);

    const map = useMap({
        locations,
        isPrinting,
    });

    return (
        <MapElement
            className="print:max-h-[450px]"
            mapController={map}
            backgroundColor="#f0f9ff"
            selectedLatitude={null}
            options={{
                ...options,
                getStateLabelColor: (state) => {
                    const miles = totals[state.abbr];
                    if (!miles) return FILL_COLORS.default;
                    const percent = totalMiles ? miles / totalMiles : 0;
                    if (percent >= 0.6) return "#DDD";
                    return "#000";
                },
                getStateColor: (state) => {
                    if (options?.getStateColor) {
                        const color = options.getStateColor(state);
                        if (color) {
                            return color;
                        }
                    }
                    const miles = totals[state.abbr];
                    if (!miles) return FILL_COLORS.default;
                    const percent = totalMiles ? miles / totalMiles : 0;
                    if (percent >= 0.6) return FILL_COLORS.heavy;
                    if (percent >= 0.4) return FILL_COLORS.mediumHeavy;
                    if (percent >= 0.2) return FILL_COLORS.medium;
                    if (percent >= 0.1) return FILL_COLORS.lightMedium;
                    if (percent > 0) return FILL_COLORS.light;
                    else return FILL_COLORS.default;
                },
                getStateLabel: (state) => {
                    if (options?.getStateLabel) {
                        const label = options.getStateLabel(state);
                        if (label) {
                            return label;
                        }
                    }
                    const miles = totals[state.abbr];
                    if (miles) {
                        const percent = totalMiles ? miles / totalMiles : 0;
                        let percentString = percent.toLocaleString("en-us", { style: "percent", maximumFractionDigits: 1 });
                        if (percent !== 0 && percent < 0.001) {
                            percentString = "< 0.1%";
                        }
                        if (["NH", "VT", "RI", "MA", "CT", "NJ", "DE", "MD", "DC"].includes(state.abbr)) {
                            return `${state.abbr} | ${percentString}`;
                        }
                        return percentString;
                    }
                    return ["NH", "VT", "RI", "MA", "CT", "NJ", "DE", "MD", "DC"].includes(state.abbr) ? state.abbr : "";
                },
            }}
        />
    );
}

export function IFTAs({ motorCarrier, fetcher }: { motorCarrier: MotorCarrier; fetcher: MotorCarrier.IFetcher }): JSX.Element {
    const state = useIFTAsPageStore();
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

    useEffect(() => {
        if (!state.iftaNavigationYear) {
            const maxYear = Math.max(...motorCarrier.iftas.map((i) => i.year), new Date().getFullYear());
            useIFTAsPageStore.getState().setIFTANavigationYear(maxYear - 4);
        }
    }, [motorCarrier.iftas, state.iftaNavigationYear]);

    const hasSetDefaultSelectedIFTAs = useRef(false);
    useEffect(() => {
        if (!hasSetDefaultSelectedIFTAs.current) {
            hasSetDefaultSelectedIFTAs.current = true;
            state.setSelectedIFTAs(motorCarrier.iftas.slice(0, 4));
        }
    }, [motorCarrier.iftas, state]);

    const minYear = Math.min(...motorCarrier.iftas.map((i) => i.year), new Date().getFullYear() - 10);
    const maxYear = Math.max(...motorCarrier.iftas.map((i) => i.year), new Date().getFullYear());
    const byYear = useMemo<{ year: number; iftas: MotorCarrier.IFetcher.fetchIFTAs.Return }[]>(() => {
        const byYear = Object.values(
            motorCarrier.iftas.reduce((acc, i) => {
                if (!acc[i.year]) {
                    acc[i.year] = [];
                }
                acc[i.year].push(i);
                return acc;
            }, {} as Record<number, MotorCarrier.IFetcher.fetchIFTAs.Return>)
        );
        const years = Array.from({ length: maxYear - minYear + 1 }, (_, i) => i + minYear);
        return years.map((year) => ({ year, iftas: byYear.find((i) => i[0].year === year) || [] }));
    }, [motorCarrier.iftas, minYear, maxYear]);
    const maxMiles = Math.max(...motorCarrier.iftas.map((i) => i.totalMiles));
    const fileInputRef = useRef<HTMLInputElement>(null);

    const uploadIFTAs = useMutation<IftaQuarterUpload[], unknown, File[]>({
        mutationFn: (files) => (fetcher.uploadIFTAs ? fetcher.uploadIFTAs(motorCarrier.dot, files) : Promise.resolve([])),
        onMutate: () => {
            setUploadDialogOpen(true);
        },
    });

    return (
        <div className="page relative">
            <PrintSectionName name="IFTAs" />

            <Section data-page-break="after">
                <TitleGroup title="IFTAs" description="All inspections within the report range." />
                <div className="mb-4 flex justify-between">
                    <div className="flex h-full flex-col">
                        <div>Drive radius</div>
                        <div className="flex grow flex-col justify-evenly rounded border p-2 text-sm">
                            {motorCarrier.driveDistances.map((r) => (
                                <div key={r.radius} className="flex items-center justify-between gap-8 tabular-nums">
                                    <span>{r.radius}</span>
                                    <span>{r.percent}%</span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        <div className="flex gap-6">
                            <div>IFTA Records</div>
                            <button
                                className="flex items-center gap-2 rounded border border-navigator-blue px-1 text-sm text-navigator-blue hover:bg-gray-50 hover:shadow-md"
                                onClick={() => fileInputRef.current?.click()}
                            >
                                <ArrowUpTrayIcon className="h-4 w-4" />
                                Import
                            </button>
                            <input
                                type="file"
                                ref={fileInputRef}
                                onInput={(e) => {
                                    const fileList = (e.target as HTMLInputElement).files;
                                    if (fileList) {
                                        uploadIFTAs.mutate(Array.from(fileList));
                                    }
                                }}
                                accept=".pdf"
                                hidden
                            />
                        </div>
                        <div className="flex items-center gap-8">
                            <button onClick={() => state.setIFTANavigationYear(Math.max(state.iftaNavigationYear - 1, minYear))}>
                                <ChevronLeftIcon className="h-6 w-6" />
                            </button>
                            <div className="flex items-end gap-8">
                                {byYear
                                    .filter(({ year }) => year >= state.iftaNavigationYear && year < state.iftaNavigationYear + 5)
                                    .map(({ year, iftas }) => (
                                        <div key={year} className="flex h-full flex-col">
                                            <div className="flex gap-1 px-1">
                                                {[1, 2, 3, 4].map((q) => {
                                                    const ifta = iftas.find((i) => i.quarter === q);
                                                    if (ifta) {
                                                        return (
                                                            <div key={q} className="flex flex-col justify-end" style={{ height: 128 }}>
                                                                <div className="text-center text-sm font-medium leading-4 tracking-wide small-caps">
                                                                    {shortenMiles(ifta.totalMiles)}
                                                                </div>
                                                                <div
                                                                    className={classNames(
                                                                        "flex w-10 flex-col items-center justify-end gap-2 rounded rounded-b-none border-b-0 border-navigator-blue bg-navigator-blue/5",
                                                                        ifta.totalMiles > 0 && "border"
                                                                    )}
                                                                    style={{
                                                                        height: Math.round((ifta.totalMiles / maxMiles) * 112),
                                                                    }}
                                                                >
                                                                    <div className="font-medium text-gray-400 small-caps">Q{q}</div>
                                                                </div>
                                                            </div>
                                                        );
                                                    } else {
                                                        return (
                                                            <div key={q} className="flex flex-col justify-end" style={{ height: 128 }}>
                                                                <div
                                                                    className="flex w-10 flex-col items-center justify-end gap-2 rounded border border-dashed border-gray-200"
                                                                    style={{
                                                                        height: 56,
                                                                    }}
                                                                >
                                                                    <div className="text-lg font-medium leading-3 text-gray-300">?</div>
                                                                    <div className="font-medium text-gray-400 small-caps">Q{q}</div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                })}
                                            </div>
                                            <div className="w-full rounded border border-navigator-blue p-2 text-center">
                                                <div className="font-mono text-sm font-medium leading-3">{year}</div>
                                                <div className="font-mono text-xs text-gray-700">
                                                    {iftas.length
                                                        ? iftas.reduce((acc, i) => acc + i.totalMiles, 0).toLocaleString("en-us")
                                                        : "-"}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                            <button onClick={() => state.setIFTANavigationYear(Math.min(state.iftaNavigationYear + 1, maxYear - 4))}>
                                <ChevronRightIcon className="h-6 w-6" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex bg-sky-50">
                    <IFTAsMap
                        motorCarrier={motorCarrier}
                        options={{
                            getStateColor: state.showHighRiskStates
                                ? ({ abbr }) => (HIGH_RISK_STATES.includes(abbr) ? "#d96060" : undefined)
                                : undefined,
                        }}
                    />
                    <div className="flex flex-col items-end py-4">
                        <div className="w-max min-w-56 rounded border border-gray-300 bg-white p-2">
                            <div className="mb-1 flex items-center justify-between gap-2">
                                <p className="text-sm">Mapped IFTAs</p>
                                <Popover placement="left-start">
                                    <Popover.Button className="rounded border border-navigator-blue px-1 text-sm text-navigator-blue hover:bg-gray-50 hover:shadow-md">
                                        <span>Select...</span>
                                    </Popover.Button>
                                    <Popover.Content>
                                        {motorCarrier.iftas
                                            .sort((a, b) => b.year - a.year || b.quarter - a.quarter)
                                            .map((i, index) => (
                                                <button
                                                    key={`${i.year}-${i.quarter}`}
                                                    className={classNames(
                                                        "flex w-full items-center gap-2 rounded px-2 py-1 text-base tabular-nums text-stone-700 disabled:opacity-50 enabled:hover:bg-gray-100",
                                                        index > 0 && i.year !== motorCarrier.iftas[index - 1].year && "mt-1 border-t"
                                                    )}
                                                    onClick={() => {
                                                        if (state.selectedIFTAs.some((s) => s.year === i.year && s.quarter === i.quarter)) {
                                                            state.setSelectedIFTAs(
                                                                state.selectedIFTAs
                                                                    .filter((s) => s.year !== i.year || s.quarter !== i.quarter)
                                                                    .sort((a, b) => a.year - b.year || a.quarter - b.quarter)
                                                            );
                                                        } else {
                                                            if (state.selectedIFTAs.length >= 12) {
                                                                state.setSelectedIFTAs(state.selectedIFTAs);
                                                            } else {
                                                                state.setSelectedIFTAs(
                                                                    [...state.selectedIFTAs, { year: i.year, quarter: i.quarter }].sort(
                                                                        (a, b) => a.year - b.year || a.quarter - b.quarter
                                                                    )
                                                                );
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <div className="h-4 w-4">
                                                        {state.selectedIFTAs.some((s) => s.year === i.year && s.quarter === i.quarter) ? (
                                                            <CheckIcon className="h-4 w-4" />
                                                        ) : null}
                                                    </div>
                                                    <span className="font-medium">
                                                        {i.year} Q{i.quarter}
                                                    </span>
                                                    <div className="text-right text-gray-600">{i.totalMiles} mi</div>
                                                </button>
                                            ))}
                                    </Popover.Content>
                                </Popover>
                            </div>
                            {state.selectedIFTAs.length === 0 && (
                                <div className="text-center text-sm text-gray-500">No quarters selected</div>
                            )}
                            {state.selectedIFTAs.map((i) => (
                                <div key={`${i.year}-${i.quarter}`} className="flex items-end gap-2">
                                    <div className="font-medium tabular-nums text-gray-800">
                                        {i.year} Q{i.quarter}
                                    </div>
                                    <div className="w-32 text-right text-base tabular-nums text-gray-600">
                                        {motorCarrier.iftas
                                            .find((i2) => i2.year === i.year && i2.quarter === i.quarter)
                                            ?.totalMiles.toLocaleString("en-us", { maximumFractionDigits: 0 })}{" "}
                                        <span className="text-xs text-gray-500">mi</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div>
                            <div className="text-sm">Overlay</div>
                            <div className="flex items-center gap-4">
                                <button
                                    className={classNames(
                                        "flex items-center gap-2 rounded border border-navigator-blue p-1 text-sm transition-all hover:shadow-md",
                                        state.showInspections && "bg-navigator-blue text-white hover:bg-navigator-blue/90",
                                        !state.showInspections && "bg-white text-navigator-blue hover:bg-gray-50"
                                    )}
                                    onClick={() => {
                                        state.toggleShowInspections();
                                    }}
                                >
                                    {state.showInspections ? (
                                        <MagnifyingGlassCircleIconSolid className="h-5 w-5" />
                                    ) : (
                                        <MagnifyingGlassCircleIconOutline className="h-5 w-5" />
                                    )}
                                    Inspections
                                </button>
                                <button
                                    className={classNames(
                                        "flex items-center gap-2 rounded border border-navigator-blue p-1 text-sm transition-all hover:shadow-md",
                                        state.showCrashes && "bg-navigator-blue text-white hover:bg-navigator-blue/90",
                                        !state.showCrashes && "bg-white text-navigator-blue hover:bg-gray-50"
                                    )}
                                    onClick={() => {
                                        state.toggleShowCrashes();
                                    }}
                                >
                                    <svg width="16" height="16" viewBox="0 0 1280 1280" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M682.7 85.125L841.1 416.725L1194.4 97.925L1013.8 554.325L1210.5 501.225L1024 766.325L1194.3 825.025L1020.3 907.725L1189.6 1198.03L867.7 998.925L798.7 1214.32L696.5 984.025L406.5 1201.82L494.7 953.325L95.0004 1033.73L394.2 811.025L78.9004 584.025L438.5 615.125L106 105.225L640 455.125L682.7 85.125Z"
                                            className={classNames(state.showCrashes ? "fill-current" : "stroke-current")}
                                            strokeWidth="80"
                                        />
                                    </svg>
                                    Crashes
                                </button>
                                <Tooltip title="Shows states identified by the American Tort Reform Foundation's yearly review of places where judges in civil cases systematically apply laws and court procedures in an unfair and unbalanced manner, generally to the disadvantage of defendants.">
                                    <button
                                        className={classNames(
                                            "flex items-center gap-2 rounded border border-navigator-blue p-1 text-sm transition-all hover:shadow-md",
                                            state.showHighRiskStates && "bg-navigator-blue text-white hover:bg-navigator-blue/90",
                                            !state.showHighRiskStates && "bg-white text-navigator-blue hover:bg-gray-50"
                                        )}
                                        onClick={() => {
                                            state.toggleShowHighRiskStates();
                                        }}
                                    >
                                        {state.showHighRiskStates ? (
                                            <ScaleIconSolid className="h-5 w-5" />
                                        ) : (
                                            <ScaleIconOutline className="h-5 w-5" />
                                        )}
                                        Judicial Hell
                                    </button>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
            <IFTAUploadDialog
                open={uploadDialogOpen}
                onClose={() => setUploadDialogOpen(false)}
                filesLength={uploadIFTAs.variables?.length || 0}
                isLoading={uploadIFTAs.isPending}
                results={uploadIFTAs.data || null}
            />
        </div>
    );
}

const HIGH_RISK_STATES: string[] = ["NY", "CA", "GA", "LA", "PA", "IL", "MO", "MI", "WA"];

function shortenMiles(miles: number): string {
    if (miles < 1_000) {
        return miles.toLocaleString("en-us", { maximumFractionDigits: 0 });
    }
    if (miles < 1_000_000) {
        return `${(miles / 1_000).toLocaleString("en-us", { maximumFractionDigits: 0 })}k`;
    }
    return `${(miles / 1_000_000).toLocaleString("en-us", { maximumFractionDigits: 1 })}m`;
}

const PLOT_SIZES = [
    [1, 1, 4],
    [2, 5, 6],
    [6, 9, 10],
    [10, 19, 15],
    [20, Infinity, 20],
];

function getPlotRadius(total: number): number {
    for (const [min, max, size] of PLOT_SIZES) {
        if (total >= min && total <= max) {
            return size;
        }
    }

    return 3;
}
