import { sortBy } from "lodash";
import moment from "moment";
import {
    AttributeOption,
    Coverage,
    CoverageOption,
    Driver,
    MonthlyReport,
    MonthlyReportAvailableMonth,
    MonthlyReportChanges,
    MonthlyReportCoveragePremium,
    MonthlyReportData,
    MonthlyReportDataUnit,
    MonthlyReportStatus,
    PolicyLayer,
    PolicyLayerComparison,
    Tractor,
    Trailer,
} from "../../northstar";

export class MonthlyReportHelper {
    /** Get the units that will affect the premium of the policy, taking into account the 15 day rule if necessary */
    public static getFinalUnits(
        data: Pick<MonthlyReportData, "month" | "year"> & {
            equipment: Pick<MonthlyReportData["equipment"], "tractors" | "trailers" | "drivers">;
            endLayer: { policy: Pick<MonthlyReportData["endLayer"]["policy"], "attributes"> };
        },
        coverage: Pick<Coverage, "coverageOptionId"> & {
            linkedTractors: Pick<Coverage["linkedTractors"][number], "tractorId">[];
            linkedTrailers: Pick<Coverage["linkedTrailers"][number], "trailerId">[];
            linkedDrivers: Pick<Coverage["linkedDrivers"][number], "driverId">[];
        }
    ): {
        tractors: MonthlyReportDataUnit<Tractor>[];
        trailers: MonthlyReportDataUnit<Trailer>[];
        drivers: MonthlyReportDataUnit<Driver>[];
    } {
        const is15DayRuleApplied = data.endLayer.policy.attributes.some(
            (att) => att.attributeOptionId === AttributeOption.Id.FIFTEEN_DAY_RULE_APPLIES
        );
        const fifteenthDayOfMonth = moment()
            .date(15)
            .month(data.month - 1)
            .year(data.year);

        let tractors: MonthlyReportDataUnit<Tractor>[] = [];
        let trailers: MonthlyReportDataUnit<Trailer>[] = [];
        let drivers: MonthlyReportDataUnit<Driver>[] = [];

        if (is15DayRuleApplied) {
            tractors = data.equipment.tractors.filter((t) => {
                const isActive = coverage.linkedTractors.some((lt) => lt.tractorId === t.id);
                const isRemovedOnOrBefore15th = t.changes.some(
                    (c) =>
                        c.changesByCoverage.has(coverage.coverageOptionId) &&
                        c.changesByCoverage.get(coverage.coverageOptionId).changes.some((cc) => cc.type === "delete") &&
                        moment.utc(c.date).isSameOrBefore(fifteenthDayOfMonth, "day")
                );
                const isRemovedAfter15th = t.changes.some(
                    (c) =>
                        c.changesByCoverage.has(coverage.coverageOptionId) &&
                        c.changesByCoverage.get(coverage.coverageOptionId).changes.some((cc) => cc.type === "delete") &&
                        moment.utc(c.date).isAfter(fifteenthDayOfMonth, "day")
                );
                const isAddedAfter15th = t.changes.some(
                    (c) =>
                        c.changesByCoverage.has(coverage.coverageOptionId) &&
                        c.changesByCoverage.get(coverage.coverageOptionId).changes.some((cc) => cc.type === "new") &&
                        moment.utc(c.date).isAfter(fifteenthDayOfMonth, "day")
                );

                if (isRemovedOnOrBefore15th && !isActive) return false;
                if (isAddedAfter15th && isActive) return false;
                if (isRemovedAfter15th && !isActive) return true;
                return isActive;
            });

            trailers = data.equipment.trailers.filter((t) => {
                const isActive = coverage.linkedTrailers.some((lt) => lt.trailerId === t.id);
                const isRemovedOnOrBefore15th = t.changes.some(
                    (c) =>
                        c.changesByCoverage.has(coverage.coverageOptionId) &&
                        c.changesByCoverage.get(coverage.coverageOptionId).changes.some((cc) => cc.type === "delete") &&
                        moment.utc(c.date).isSameOrBefore(fifteenthDayOfMonth, "day")
                );
                const isRemovedAfter15th = t.changes.some(
                    (c) =>
                        c.changesByCoverage.has(coverage.coverageOptionId) &&
                        c.changesByCoverage.get(coverage.coverageOptionId).changes.some((cc) => cc.type === "delete") &&
                        moment.utc(c.date).isAfter(fifteenthDayOfMonth, "day")
                );
                const isAddedAfter15th = t.changes.some(
                    (c) =>
                        c.changesByCoverage.has(coverage.coverageOptionId) &&
                        c.changesByCoverage.get(coverage.coverageOptionId).changes.some((cc) => cc.type === "new") &&
                        moment.utc(c.date).isAfter(fifteenthDayOfMonth, "day")
                );

                if (isRemovedOnOrBefore15th && !isActive) return false;
                if (isAddedAfter15th && isActive) return false;
                if (isRemovedAfter15th && !isActive) return true;
                return isActive;
            });

            drivers = data.equipment.drivers.filter((t) => {
                const isActive = coverage.linkedDrivers.some((lt) => lt.driverId === t.id);
                const isRemovedOnOrBefore15th = t.changes.some(
                    (c) =>
                        c.changesByCoverage.has(coverage.coverageOptionId) &&
                        c.changesByCoverage.get(coverage.coverageOptionId).changes.some((cc) => cc.type === "delete") &&
                        moment.utc(c.date).isSameOrBefore(fifteenthDayOfMonth, "day")
                );
                const isRemovedAfter15th = t.changes.some(
                    (c) =>
                        c.changesByCoverage.has(coverage.coverageOptionId) &&
                        c.changesByCoverage.get(coverage.coverageOptionId).changes.some((cc) => cc.type === "delete") &&
                        moment.utc(c.date).isAfter(fifteenthDayOfMonth, "day")
                );
                const isAddedAfter15th = t.changes.some(
                    (c) =>
                        c.changesByCoverage.has(coverage.coverageOptionId) &&
                        c.changesByCoverage.get(coverage.coverageOptionId).changes.some((cc) => cc.type === "new") &&
                        moment.utc(c.date).isAfter(fifteenthDayOfMonth, "day")
                );

                if (isRemovedOnOrBefore15th && !isActive) return false;
                if (isAddedAfter15th && isActive) return false;
                if (isRemovedAfter15th && !isActive) return true;
                return isActive;
            });
        } else {
            tractors = data.equipment.tractors.filter((t) => coverage.linkedTractors.some((lt) => lt.tractorId === t.id));
            trailers = data.equipment.trailers.filter((t) => coverage.linkedTrailers.some((lt) => lt.trailerId === t.id));
            drivers = data.equipment.drivers.filter((t) => coverage.linkedDrivers.some((lt) => lt.driverId === t.id));
        }

        return {
            tractors,
            trailers,
            drivers,
        };
    }

    public static getProrateFactor(
        report: Pick<MonthlyReport, "year" | "month">,
        policy: Pick<PolicyLayer["policy"], "effectiveDate" | "expirationDate" | "cancelEffectiveDate">
    ): number {
        const expirationDate = moment.utc(policy.cancelEffectiveDate || policy.expirationDate).subtract(1, "day");
        const daysInMonth = moment
            .utc()
            .year(report.year)
            .month(report.month - 1)
            .daysInMonth();
        const startOfReportMonth = moment
            .utc()
            .year(report.year)
            .month(report.month - 1)
            .startOf("month")
            .startOf("day");
        const endOfReportMonth = moment
            .utc()
            .year(report.year)
            .month(report.month - 1)
            .endOf("month")
            .endOf("day");
        if (
            moment.utc(policy.effectiveDate).month() === startOfReportMonth.month() &&
            moment.utc(policy.effectiveDate).diff(startOfReportMonth, "days") > 0
        ) {
            const daysBeforeEffectiveDate = moment.utc(policy.effectiveDate).diff(startOfReportMonth, "days");
            return daysBeforeEffectiveDate / daysInMonth;
        } else if (expirationDate.month() === endOfReportMonth.month() && expirationDate.diff(endOfReportMonth, "days") < 0) {
            const daysAfterExpirationDate = Math.abs(expirationDate.diff(endOfReportMonth, "days"));
            return daysAfterExpirationDate / daysInMonth;
        }

        return 0;
    }

    public static getPremiumByCoverage(
        report: Pick<MonthlyReport, "revenue" | "mileage" | "premiumModifications" | "payroll">,
        data: Pick<MonthlyReportData, "endLayer" | "month" | "year" | "equipment">
    ): Map<CoverageOption.Id, MonthlyReportCoveragePremium> {
        const result = new Map<CoverageOption.Id, MonthlyReportCoveragePremium>();
        for (const coverage of data.endLayer.policy.coverages) {
            const coveragePremium: MonthlyReportCoveragePremium = {
                minimumPremium: 0,
                minimumPremiumApplied: 0,
                totalPremium: 0,
                subtotalPremium: 0,
                proratedPremiumApplied: 0,
                rates: [],
            };

            const ratings: {
                description: string;
                basis: Coverage["basis"];
                rate: number;
                tractorIds: number[];
                trailerIds: number[];
            }[] = [];

            if (coverage.compositeRatings?.length) {
                for (const rating of coverage.compositeRatings) {
                    if (rating.frequency !== Coverage.Frequency.MONTHLY) continue;
                    ratings.push({
                        description: rating.description,
                        rate: rating.rate,
                        basis: rating.basis,
                        tractorIds: rating.linkedTractors?.map((t) => t.tractorId) || [],
                        trailerIds: rating.linkedTrailers?.map((t) => t.trailerId) || [],
                    });
                }
            } else if (coverage.frequency === Coverage.Frequency.MONTHLY) {
                ratings.push({ description: "", rate: coverage.rate, basis: coverage.basis, tractorIds: [], trailerIds: [] });
            }

            if (!ratings.length) continue;

            const { tractors, trailers, drivers } = MonthlyReportHelper.getFinalUnits(data, coverage as Required<Coverage>);

            const prorateFactor = MonthlyReportHelper.getProrateFactor(data, data.endLayer.policy);

            const isUniqueDeductibleRatingPresent = ratings.some((r) => r.description.toLowerCase() === "equipment with unique deductible");
            const selectedUnitsRatings = ratings.filter(
                (r) => r.description.toLowerCase() === "selected vins" && (r.tractorIds.length || r.trailerIds.length)
            );

            for (const rating of ratings) {
                let rate: number;
                let factor: number;

                if (rating.basis === Coverage.Basis.UNITS) {
                    rate = rating.rate || 0;
                    if (
                        coverage.coverageOptionId === CoverageOption.Id.OCCUPATIONAL_ACCIDENT ||
                        coverage.coverageOptionId === CoverageOption.Id.CONTINGENT_LIABILITY
                    ) {
                        factor = drivers.length;
                    } else if (coverage.coverageOptionId === CoverageOption.Id.NON_TRUCKING_LIABILITY) {
                        factor = tractors.length;
                    } else {
                        if (rating.description.toLowerCase() === "tractors") {
                            factor = tractors.filter((t) => {
                                // if there is a rate for equipment with unique deductibles on this coverage, we only need to count
                                // the units _without_ unique deductibles here
                                if (isUniqueDeductibleRatingPresent && t.hasUniqueDeductible) {
                                    return false;
                                }
                                // if there is a rate for selected vins on this coverage, we do not need to count those units here
                                if (selectedUnitsRatings.some((r) => r.tractorIds.includes(t.id))) {
                                    return false;
                                }
                                return true;
                            }).length;
                        } else if (rating.description.toLowerCase() === "trailers") {
                            // see comments above about filtering tractors
                            factor = trailers.filter((t) => {
                                if (isUniqueDeductibleRatingPresent && t.hasUniqueDeductible) {
                                    return false;
                                }
                                if (selectedUnitsRatings.some((r) => r.trailerIds.includes(t.id))) {
                                    return false;
                                }
                                return true;
                            }).length;
                        } else if (rating.description.toLowerCase() === "equipment with unique deductible") {
                            factor =
                                tractors.filter((t) => {
                                    if (selectedUnitsRatings.some((r) => r.tractorIds.includes(t.id))) {
                                        return false;
                                    }
                                    return t.hasUniqueDeductible;
                                }).length +
                                trailers.filter((t) => {
                                    if (selectedUnitsRatings.some((r) => r.trailerIds.includes(t.id))) {
                                        return false;
                                    }
                                    return t.hasUniqueDeductible;
                                }).length;
                        } else if (rating.description.toLowerCase() === "selected vins") {
                            factor =
                                tractors.filter((t) => rating.tractorIds.includes(t.id)).length +
                                trailers.filter((t) => rating.trailerIds.includes(t.id)).length;
                        } else {
                            factor =
                                tractors.filter((t) => {
                                    if (selectedUnitsRatings.some((r) => r.tractorIds.includes(t.id))) {
                                        return false;
                                    }
                                    if (isUniqueDeductibleRatingPresent && t.hasUniqueDeductible) {
                                        return false;
                                    }
                                    return true;
                                }).length +
                                trailers.filter((t) => {
                                    if (selectedUnitsRatings.some((r) => r.trailerIds.includes(t.id))) {
                                        return false;
                                    }
                                    if (isUniqueDeductibleRatingPresent && t.hasUniqueDeductible) {
                                        return false;
                                    }
                                    return true;
                                }).length;
                        }
                    }
                } else if (rating.basis === Coverage.Basis.VALUES) {
                    rate = (rating.rate || 0) / 100;
                    if (rating.description.toLowerCase() === "tractors") {
                        factor = tractors.reduce((acc, t) => {
                            if (isUniqueDeductibleRatingPresent && t.hasUniqueDeductible) {
                                return acc;
                            }
                            if (selectedUnitsRatings.some((r) => r.tractorIds.includes(t.id))) {
                                return acc;
                            }
                            return acc + (t.isACV ? 0 : t.statedValue || 0);
                        }, 0);
                    } else if (rating.description.toLowerCase() === "trailers") {
                        factor = trailers.reduce((acc, t) => {
                            if (isUniqueDeductibleRatingPresent && t.hasUniqueDeductible) {
                                return acc;
                            }
                            if (selectedUnitsRatings.some((r) => r.trailerIds.includes(t.id))) {
                                return acc;
                            }
                            return acc + (t.isACV ? 0 : t.statedValue || 0);
                        }, 0);
                    } else if (rating.description.toLowerCase() === "equipment with unique deductible") {
                        factor =
                            tractors.reduce((acc, t) => {
                                if (selectedUnitsRatings.some((r) => r.tractorIds.includes(t.id))) {
                                    return acc;
                                }
                                if (!t.hasUniqueDeductible) {
                                    return acc;
                                }
                                return acc + (t.isACV ? 0 : t.statedValue || 0);
                            }, 0) +
                            trailers.reduce((acc, t) => {
                                if (selectedUnitsRatings.some((r) => r.trailerIds.includes(t.id))) {
                                    return acc;
                                }
                                if (!t.hasUniqueDeductible) {
                                    return acc;
                                }
                                return acc + (t.isACV ? 0 : t.statedValue || 0);
                            }, 0);
                    } else if (rating.description.toLowerCase() === "selected vins") {
                        factor =
                            tractors.reduce(
                                (acc, t) => (rating.tractorIds.includes(t.id) ? acc + (t.isACV ? 0 : t.statedValue || 0) : acc),
                                0
                            ) +
                            trailers.reduce(
                                (acc, t) => (rating.trailerIds.includes(t.id) ? acc + (t.isACV ? 0 : t.statedValue || 0) : acc),
                                0
                            );
                    } else {
                        factor =
                            tractors.reduce((acc, t) => {
                                if (selectedUnitsRatings.some((r) => r.tractorIds.includes(t.id))) {
                                    return acc;
                                }
                                if (isUniqueDeductibleRatingPresent && t.hasUniqueDeductible) {
                                    return acc;
                                }
                                return acc + (t.isACV ? 0 : t.statedValue || 0);
                            }, 0) +
                            trailers.reduce((acc, t) => {
                                if (selectedUnitsRatings.some((r) => r.trailerIds.includes(t.id))) {
                                    return acc;
                                }
                                if (isUniqueDeductibleRatingPresent && t.hasUniqueDeductible) {
                                    return acc;
                                }
                                return acc + (t.isACV ? 0 : t.statedValue || 0);
                            }, 0);
                    }
                } else if (rating.basis === Coverage.Basis.MILEAGE) {
                    rate = rating.rate || 0;
                    factor = report.mileage;
                } else if (rating.basis === Coverage.Basis.REVENUE) {
                    rate = (rating.rate || 0) / 100;
                    factor = report.revenue;
                } else if (rating.basis === Coverage.Basis.OTHER) {
                    rate = 1;
                    factor = rating.rate || 0;
                } else if (rating.basis === Coverage.Basis.FEE) {
                    rate = 1;
                    factor = rating.rate || 0;
                } else if (rating.basis === Coverage.Basis.PREMIUM) {
                    rate = (rating.rate || 0) / 100;
                    factor = 0;
                } else if (rating.basis === Coverage.Basis.PAYROLL) {
                    rate = rating.rate || 0;
                    factor = report.payroll.find((crp) => crp.description === rating.description)?.amount || 0;
                } else {
                    continue;
                }
                const total = rate * factor;
                let prorated = 0;
                switch (rating.basis) {
                    case Coverage.Basis.REVENUE:
                    case Coverage.Basis.MILEAGE:
                    case Coverage.Basis.FEE:
                    case Coverage.Basis.PREMIUM:
                    case Coverage.Basis.PAYROLL:
                        prorated = 0;
                        break;
                    default:
                        prorated = total * prorateFactor;
                }
                coveragePremium.rates.push({
                    description: rating.description,
                    basis: rating.basis,
                    rate,
                    factor,
                    prorated,
                    total,
                    isModification: false,
                });
            }

            for (const modification of report.premiumModifications) {
                if (modification.coverageOptionId === coverage.coverageOptionId) {
                    coveragePremium.rates.push({
                        description: modification.description,
                        basis: Coverage.Basis.FLAT,
                        rate: 1,
                        factor: modification.total,
                        prorated: 0,
                        total: modification.total,
                        isModification: true,
                    });
                }
            }

            const premiumRateFactor = coveragePremium.rates.reduce(
                (acc, r) => acc + (r.basis !== Coverage.Basis.FEE && r.basis !== Coverage.Basis.PREMIUM ? r.total : 0),
                0
            );
            for (const rate of coveragePremium.rates) {
                if (rate.basis === Coverage.Basis.PREMIUM) {
                    rate.factor = premiumRateFactor;
                    rate.total = rate.rate * rate.factor;
                    if (rate.description !== "Surplus Lines Tax") {
                        rate.prorated = rate.total * prorateFactor;
                    }
                }
            }

            coveragePremium.proratedPremiumApplied = coveragePremium.rates.reduce((acc, r) => acc + r.prorated, 0);
            coveragePremium.subtotalPremium = coveragePremium.rates.reduce((acc, r) => acc + r.total, 0);

            const minimumPremiumAttribute = coverage.attributes.find(
                (att) => att.attributeOptionId === AttributeOption.Id.MINIMUM_MONTHLY_PREMIUM_COVERAGE
            );
            if (minimumPremiumAttribute) {
                const minimumPremium = (minimumPremiumAttribute.valueNumber || 0) * (1 - prorateFactor);
                coveragePremium.minimumPremium = minimumPremium;

                // only some Basis types are applicable to be included in the minimum premium calculation
                // so we cannot just use `coveragePremium.subtotalPremium`
                const minimumPremiumApplicableTotal = coveragePremium.rates.reduce(
                    (acc, r) =>
                        acc +
                        (r.basis !== Coverage.Basis.FEE && !(r.basis === Coverage.Basis.PREMIUM && r.description === "Surplus Lines Tax")
                            ? r.total
                            : 0),
                    0
                );

                const minimumPremiumApplied = Math.max(0, minimumPremium - minimumPremiumApplicableTotal);
                coveragePremium.minimumPremiumApplied = minimumPremiumApplied;
            }

            coveragePremium.totalPremium =
                coveragePremium.subtotalPremium + coveragePremium.minimumPremiumApplied - coveragePremium.proratedPremiumApplied;

            result.set(coverage.coverageOptionId, coveragePremium);
        }
        return result;
    }

    public static getFillableMonths(
        effectiveDate: Date,
        expirationDate: Date,
        isAdvanced: boolean,
        isNTL: boolean,
        isRenewal: boolean,
        reports: MonthlyReport[],
        cancelDate?: Date
    ): MonthlyReportAvailableMonth[] {
        const startDate = moment.utc(effectiveDate);
        const endDate = moment.utc(expirationDate);
        const months: MonthlyReportAvailableMonth[] = [];
        if (isAdvanced) {
            if (!isRenewal) {
                // hide the first month of advanced policies if they are not renewals
                startDate.add(1, "month");
            }
            while (startDate.isSameOrBefore(endDate, "month")) {
                if (startDate.date() === 1 && startDate.isSame(endDate, "day")) break; // expiration date is exclusive not inclusive
                if (cancelDate && startDate.isAfter(moment.utc(cancelDate), "month")) {
                    break;
                }
                if (startDate.isAfter(moment.utc("2024-10-01"), "month")) {
                    const report = reports.find((r) => r.year === startDate.year() && r.month === startDate.month() + 1);
                    months.push({
                        month: startDate.month() + 1,
                        year: startDate.year(),
                        status: MonthlyReportHelper.getReportStatus(
                            startDate.year(),
                            startDate.month() + 1,
                            !!report?.isFinalized,
                            !!report?.isSubmitted,
                            isAdvanced,
                            cancelDate
                        ),
                    });
                }
                startDate.add(1, "month");
            }
        } else {
            while (startDate.isSameOrBefore(endDate, "month")) {
                if (startDate.date() === 1 && startDate.isSame(endDate, "day")) break; // expiration date is exclusive not inclusive
                if (cancelDate && startDate.isAfter(moment.utc(cancelDate), "month")) {
                    break;
                }
                const report = reports.find((r) => r.year === startDate.year() && r.month === startDate.month() + 1);
                if (isNTL) {
                    if (startDate.isSameOrAfter(moment.utc("2025-01-01"), "month")) {
                        months.push({
                            month: startDate.month() + 1,
                            year: startDate.year(),
                            status: MonthlyReportHelper.getReportStatus(
                                startDate.year(),
                                startDate.month() + 1,
                                !!report?.isFinalized,
                                !!report?.isSubmitted,
                                isAdvanced,
                                cancelDate
                            ),
                        });
                    }
                } else {
                    if (startDate.isAfter(moment.utc("2024-06-01"), "month")) {
                        months.push({
                            month: startDate.month() + 1,
                            year: startDate.year(),
                            status: MonthlyReportHelper.getReportStatus(
                                startDate.year(),
                                startDate.month() + 1,
                                !!report?.isFinalized,
                                !!report?.isSubmitted,
                                isAdvanced,
                                cancelDate
                            ),
                        });
                    }
                }
                startDate.add(1, "month");
            }
        }
        return months;
    }

    public static getAllAvailableMonths(
        effectiveDate: Date,
        expirationDate: Date,
        isAdvanced: boolean,
        reports: MonthlyReport[],
        cancelDate?: Date
    ): MonthlyReportAvailableMonth[] {
        const startDate = moment.utc(effectiveDate);
        const endDate = moment.utc(expirationDate);
        const months: MonthlyReportAvailableMonth[] = [];
        if (isAdvanced) {
            while (startDate.isBefore(endDate, "month")) {
                if (startDate.date() === 1 && startDate.isSame(endDate, "day")) break; // expiration date is exclusive not inclusive
                const report = reports.find((r) => r.year === startDate.year() && r.month === startDate.month() + 1);
                months.push({
                    month: startDate.month() + 1,
                    year: startDate.year(),
                    status: MonthlyReportHelper.getReportStatus(
                        startDate.year(),
                        startDate.month() + 1,
                        !!report?.isFinalized,
                        !!report?.isSubmitted,
                        isAdvanced,
                        cancelDate
                    ),
                });
                startDate.add(1, "month");
            }
        } else {
            while (startDate.isSameOrBefore(endDate, "month")) {
                if (startDate.date() === 1 && startDate.isSame(endDate, "day")) break; // expiration date is exclusive not inclusive
                const report = reports.find((r) => r.year === startDate.year() && r.month === startDate.month() + 1);
                months.push({
                    month: startDate.month() + 1,
                    year: startDate.year(),
                    status: MonthlyReportHelper.getReportStatus(
                        startDate.year(),
                        startDate.month() + 1,
                        !!report?.isFinalized,
                        !!report?.isSubmitted,
                        isAdvanced,
                        cancelDate
                    ),
                });
                startDate.add(1, "month");
            }
        }
        return months;
    }

    /**
     *
     * @param layers an array of all available PolicyLayers for the policy
     * @param month the month, where January is 1 and December is 12
     * @param year the year
     * @returns an array of layers that are to be used when calculating the changes for the given month. if exists, the earliest layer (the last in the array) will be from a month before the provided month, so as to provide a base for comparison for the first layer of the month. it is possible that fewer than two layers will be returned.
     */
    public static getLayersForMonth(layers: PolicyLayer[], month: number, year: number, finalizedDate: Date | null): PolicyLayer[] {
        layers = finalizedDate ? layers.filter((layer) => layer.createdDate.getTime() <= finalizedDate.getTime()) : layers;
        const m = moment.utc({ year, month: month - 1, date: 1, hour: 12, minute: 0, millisecond: 0 });
        let earliestLayer = layers[layers.length - 1];
        for (let i = layers.length - 2; i > 1; i--) {
            if (moment.utc(layers[i].effectiveDate).isSameOrAfter(m, "month")) {
                break;
            }
            earliestLayer = layers[i];
        }
        let latestLayer = layers[0];
        for (let i = 0; i < layers.length - 1; i++) {
            latestLayer = layers[i];
            if (layers[i].effectiveDate.getTime() <= earliestLayer.effectiveDate.getTime()) {
                break;
            }
            if (moment.utc(layers[i].effectiveDate).isSameOrBefore(m, "month")) {
                break;
            }
        }

        return layers.filter(
            (layer) =>
                layer.effectiveDate.getTime() >= earliestLayer.effectiveDate.getTime() &&
                layer.effectiveDate.getTime() <= latestLayer.effectiveDate.getTime()
        );
    }

    /**
     *
     * @param layers The array of layers to compare.
     * @param equipment The equipment to link to the changes.
     */
    private static getChanges(layers: PolicyLayer[], equipment: PolicyLayerComparison.IEquipment): MonthlyReportChanges {
        const result: MonthlyReportChanges = {
            tractors: [],
            trailers: [],
            drivers: [],
        };

        if (layers.length < 2) {
            return result;
        }

        for (let i = layers.length - 1; i > 0; i--) {
            const baseLayer = layers[i];
            const comparisonLayer = layers[i - 1];
            const comparison = PolicyLayer.compare(
                {
                    baseLayer,
                    comparisonLayer,
                },
                {
                    data: {
                        equipment,
                    },
                    separateEquipment: true,
                    accounting: false,
                    additionalInterests: false,
                    coverages: false,
                }
            );

            const changes = Array.from(comparison.equipment.getChangeList().values());

            for (const change of changes) {
                if ("driverId" in change.entity) {
                    result.drivers.push({
                        date: comparisonLayer.effectiveDate,
                        unitId: change.entity.driverId,
                        changesByCoverage: change.changeMap,
                        isNewToPolicy: !!change.entity.comparison?.isNewToPolicy,
                        isRemovedFromPolicy: !!change.entity.comparison?.isDeletedFromPolicy,
                    });
                } else if ("tractorId" in change.entity) {
                    result.tractors.push({
                        date: comparisonLayer.effectiveDate,
                        unitId: change.entity.tractorId,
                        changesByCoverage: change.changeMap,
                        isNewToPolicy: !!change.entity.comparison?.isNewToPolicy,
                        isRemovedFromPolicy: !!change.entity.comparison?.isDeletedFromPolicy,
                    });
                } else if ("trailerId" in change.entity) {
                    result.trailers.push({
                        date: comparisonLayer.effectiveDate,
                        unitId: change.entity.trailerId,
                        changesByCoverage: change.changeMap,
                        isNewToPolicy: !!change.entity.comparison?.isNewToPolicy,
                        isRemovedFromPolicy: !!change.entity.comparison?.isDeletedFromPolicy,
                    });
                }
            }
        }

        return result;
    }

    public static getIsAdvancedReporter(policy: PolicyLayer["policy"]): boolean {
        return policy.attributes.some((a) => a.attributeOptionId === AttributeOption.Id.COMPOSITE_ADVANCED);
    }

    public static getMonthlyReportData(
        report: Pick<MonthlyReport, "year" | "month" | "mileage" | "revenue" | "finalizedDate" | "premiumModifications" | "payroll">,
        layers: PolicyLayer[],
        equipment: PolicyLayerComparison.IEquipment
    ): MonthlyReportData {
        const layersForMonth = MonthlyReportHelper.getLayersForMonth(layers, report.month, report.year, report.finalizedDate);
        const startLayer = layersForMonth[layersForMonth.length - 1];
        const endLayer = layersForMonth[0];

        const changes = MonthlyReportHelper.getChanges(layersForMonth, equipment);
        const result: MonthlyReportData = {
            month: report.month,
            year: report.year,
            startLayer,
            endLayer,
            subtotalPremium: 0,
            totalPremium: 0,
            minimumPremium: 0,
            minimumPremiumApplied: 0,
            proratedPremiumApplied: 0,
            premiumByCoverage: new Map(),
            isAdvancedReporter: this.getIsAdvancedReporter(endLayer.policy),
            isDriverReporter: endLayer.policy.coverages.some(
                (cov) =>
                    cov.coverageOptionId === CoverageOption.Id.OCCUPATIONAL_ACCIDENT ||
                    cov.coverageOptionId === CoverageOption.Id.CONTINGENT_LIABILITY ||
                    cov.coverageOptionId === CoverageOption.Id.NON_TRUCKING_LIABILITY
            ),
            isUnitReporter: endLayer.policy.coverages.some(
                (c) =>
                    Coverage.isComposite(endLayer.policy, c) &&
                    (c.basis === Coverage.Basis.UNITS ||
                        c.basis === Coverage.Basis.VALUES ||
                        (c.compositeRatings &&
                            c.compositeRatings.some((cr) => cr.basis === Coverage.Basis.UNITS || cr.basis === Coverage.Basis.VALUES)))
            ),
            isMileageReporter: endLayer.policy.coverages.some(
                (c) =>
                    Coverage.isComposite(endLayer.policy, c) &&
                    (c.basis === Coverage.Basis.MILEAGE ||
                        (c.compositeRatings && c.compositeRatings.some((cr) => cr.basis === Coverage.Basis.MILEAGE)))
            ),
            isRevenueReporter: endLayer.policy.coverages.some(
                (c) =>
                    Coverage.isComposite(endLayer.policy, c) &&
                    (c.basis === Coverage.Basis.REVENUE ||
                        (c.compositeRatings && c.compositeRatings.some((cr) => cr.basis === Coverage.Basis.REVENUE)))
            ),
            isPayrollReporter: endLayer.policy.coverages.some(
                (c) =>
                    Coverage.isComposite(endLayer.policy, c) &&
                    (c.basis === Coverage.Basis.PAYROLL ||
                        (c.compositeRatings && c.compositeRatings.some((cr) => cr.basis === Coverage.Basis.PAYROLL)))
            ),
            equipment: {
                tractors: equipment.tractors.map((u) => ({
                    ...u,
                    changes: [],
                    isNewToPolicy: false,
                    dateAddedToPolicy: null,
                    isRemovedFromPolicy: false,
                    dateRemovedFromPolicy: null,
                    isNewToCoverage: new Map(),
                    isRemovedFromCoverage: new Map(),
                    isACV: false,
                    isSpare: false,
                    statedValue: null,
                    hasUniqueDeductible: false,
                    experience: undefined as never,
                    employer: undefined as never,
                    employerId: undefined as never,
                    isOwnerOperator: undefined as never,
                    isTractor: true,
                    isTrailer: false,
                    isDriver: false,
                    coverages: [],
                    additionalInterests: [],
                })),
                trailers: equipment.trailers.map((u) => ({
                    ...u,
                    changes: [],
                    isNewToPolicy: false,
                    dateAddedToPolicy: null,
                    isRemovedFromPolicy: false,
                    dateRemovedFromPolicy: null,
                    isNewToCoverage: new Map(),
                    isRemovedFromCoverage: new Map(),
                    isACV: false,
                    isSpare: false,
                    statedValue: null,
                    hasUniqueDeductible: false,
                    experience: undefined as never,
                    employer: undefined as never,
                    employerId: undefined as never,
                    isOwnerOperator: undefined as never,
                    isTractor: false,
                    isTrailer: true,
                    isDriver: false,
                    coverages: [],
                    additionalInterests: [],
                })),
                drivers: equipment.drivers.map((u) => ({
                    ...u,
                    changes: [],
                    isNewToPolicy: false,
                    dateAddedToPolicy: null,
                    isRemovedFromPolicy: false,
                    dateRemovedFromPolicy: null,
                    isNewToCoverage: new Map(),
                    isRemovedFromCoverage: new Map(),
                    isACV: undefined as never,
                    isSpare: undefined as never,
                    statedValue: undefined as never,
                    hasUniqueDeductible: undefined as never,
                    isOwnerOperator: false,
                    experience: null,
                    employer: u.employer,
                    employerId: u.employerId,
                    isTractor: false,
                    isTrailer: false,
                    isDriver: true,
                    coverages: [],
                    additionalInterests: [],
                })),
                activeTractors: [],
                activeTrailers: [],
                activeDrivers: [],
            },
        };

        for (const change of changes.drivers) {
            result.equipment.drivers.find((d) => d.id === change.unitId)?.changes.push(change);
        }
        for (const change of changes.tractors) {
            result.equipment.tractors.find((t) => t.id === change.unitId)?.changes.push(change);
        }
        for (const change of changes.trailers) {
            result.equipment.trailers.find((t) => t.id === change.unitId)?.changes.push(change);
        }

        result.equipment.drivers = result.equipment.drivers
            .filter((driver) => driver.changes.length > 0 || MonthlyReportHelper.isDriverOnPolicy(driver, endLayer))
            .map((unit) => {
                const isRemovedFromPolicy = unit.changes.some((change) => change.isRemovedFromPolicy);
                return {
                    ...unit,
                    isOwnerOperator: isRemovedFromPolicy
                        ? getIsDriverOwnerOperator(unit, startLayer)
                        : getIsDriverOwnerOperator(unit, endLayer),
                    experience: isRemovedFromPolicy ? getDriverExperience(unit, startLayer) : getDriverExperience(unit, endLayer),
                    isNewToPolicy: unit.changes.some((change) => change.isNewToPolicy),
                    dateAddedToPolicy: unit.changes.find((change) => change.isNewToPolicy)?.date || null,
                    isRemovedFromPolicy,
                    dateRemovedFromPolicy: unit.changes.find((change) => change.isRemovedFromPolicy)?.date || null,
                    isRemovedFromCoverage: unit.changes.reduce((acc, change) => {
                        for (const [coverageId, value] of change.changesByCoverage) {
                            if (value.changes.some((ch) => ch.type === "delete")) {
                                acc.set(coverageId, true);
                            }
                        }
                        return acc;
                    }, new Map()),
                    isNewToCoverage: unit.changes.reduce((acc, change) => {
                        for (const [coverageId, value] of change.changesByCoverage) {
                            if (value.changes.some((ch) => ch.type === "new")) {
                                acc.set(coverageId, true);
                            }
                        }
                        return acc;
                    }, new Map()),
                    coverages: endLayer.policy.coverages
                        .filter((c) => c.linkedDrivers?.some((d) => d.driverId === unit.id))
                        .map((c) => ({
                            policyId: c.policyId,
                            coverageOptionId: c.coverageOptionId,
                            effectiveDate: endLayer.policy.effectiveDate.toISOString().substring(0, 10),
                            isUpcoming: moment(endLayer.policy.effectiveDate).isAfter(moment(), "day"),
                        })),
                };
            });
        result.equipment.tractors = result.equipment.tractors
            .filter((tractor) => tractor.changes.length > 0 || MonthlyReportHelper.isTractorOnPolicy(tractor, endLayer))
            .map((unit) => {
                const isSpare = endLayer.policy.coverages.some((coverage) =>
                    coverage.attributes.some(
                        (attr) => attr.tractorId === unit.id && attr.attributeOptionId === AttributeOption.Id.UNIT_SPARE_RATED
                    )
                );
                const endLayerAPD = endLayer.policy.coverages.find(
                    (cov) => cov.coverageOptionId === CoverageOption.Id.TRUCKERS_PHYSICAL_DAMAGE
                );
                const isACV = endLayerAPD?.attributes.some(
                    (attr) => attr.tractorId === unit.id && attr.attributeOptionId === AttributeOption.Id.VALUATION_ACTUAL_CASH_VALUE
                );
                const statedValue = endLayerAPD?.attributes.find(
                    (attr) => attr.tractorId === unit.id && attr.attributeOptionId === AttributeOption.Id.STATED_VALUE
                )?.valueNumber;
                const hasUniqueDeductible = endLayerAPD?.attributes.some(
                    (attr) =>
                        attr.tractorId === unit.id &&
                        (attr.attributeOptionId === AttributeOption.Id.UNIT_COMPREHENSIVE ||
                            attr.attributeOptionId === AttributeOption.Id.UNIT_COLLISION) &&
                        attr.valueNumber
                );

                const startLayerAPD = startLayer.policy.coverages.find(
                    (cov) => cov.coverageOptionId === CoverageOption.Id.TRUCKERS_PHYSICAL_DAMAGE
                );
                const previousIsACV = startLayerAPD?.attributes.some(
                    (attr) => attr.tractorId === unit.id && attr.attributeOptionId === AttributeOption.Id.VALUATION_ACTUAL_CASH_VALUE
                );
                const previousStatedValue = startLayerAPD?.attributes.find(
                    (attr) => attr.tractorId === unit.id && attr.attributeOptionId === AttributeOption.Id.STATED_VALUE
                )?.valueNumber;

                const isRemovedFromPolicy = unit.changes.some((change) => change.isRemovedFromPolicy);

                return {
                    ...unit,
                    isNewToPolicy: unit.changes.some((change) => change.isNewToPolicy),
                    dateAddedToPolicy: unit.changes.find((change) => change.isNewToPolicy)?.date || null,
                    isRemovedFromPolicy,
                    dateRemovedFromPolicy: unit.changes.find((change) => change.isRemovedFromPolicy)?.date || null,
                    isRemovedFromCoverage: unit.changes.reduce((acc, change) => {
                        for (const [coverageId, value] of change.changesByCoverage) {
                            if (value.changes.some((ch) => ch.type === "delete")) {
                                acc.set(coverageId, true);
                            }
                        }
                        return acc;
                    }, new Map()),
                    isNewToCoverage: unit.changes.reduce((acc, change) => {
                        for (const [coverageId, value] of change.changesByCoverage) {
                            if (value.changes.some((ch) => ch.type === "new")) {
                                acc.set(coverageId, true);
                            }
                        }
                        return acc;
                    }, new Map()),
                    isSpare: !!isSpare,
                    isACV: isRemovedFromPolicy ? !!previousIsACV : !!isACV,
                    statedValue: isRemovedFromPolicy ? previousStatedValue || null : statedValue || null,
                    hasUniqueDeductible: !!hasUniqueDeductible,
                    coverages: endLayer.policy.coverages
                        .filter((c) => c.linkedTractors?.some((t) => t.tractorId === unit.id))
                        .map((c) => ({
                            policyId: c.policyId,
                            coverageOptionId: c.coverageOptionId,
                            effectiveDate: endLayer.policy.effectiveDate.toISOString().substring(0, 10),
                            isUpcoming: moment(endLayer.policy.effectiveDate).isAfter(moment(), "day"),
                        })),
                    additionalInterests: endLayer.policy.additionalInterests.filter((ai) =>
                        ai.linkedTractors?.some((lt) => lt.tractorId === unit.id)
                    ),
                };
            });
        result.equipment.trailers = result.equipment.trailers
            .filter((trailer) => trailer.changes.length > 0 || MonthlyReportHelper.isTrailerOnPolicy(trailer, endLayer))
            .map((unit) => {
                const isSpare = endLayer.policy.coverages.some((coverage) =>
                    coverage.attributes.some(
                        (attr) => attr.trailerId === unit.id && attr.attributeOptionId === AttributeOption.Id.UNIT_SPARE_RATED
                    )
                );
                const endLayerAPD = endLayer.policy.coverages.find(
                    (cov) => cov.coverageOptionId === CoverageOption.Id.TRUCKERS_PHYSICAL_DAMAGE
                );
                const isACV = endLayerAPD?.attributes.some(
                    (attr) => attr.trailerId === unit.id && attr.attributeOptionId === AttributeOption.Id.VALUATION_ACTUAL_CASH_VALUE
                );
                const statedValue = endLayerAPD?.attributes.find(
                    (attr) => attr.trailerId === unit.id && attr.attributeOptionId === AttributeOption.Id.STATED_VALUE
                )?.valueNumber;
                const hasUniqueDeductible = endLayerAPD?.attributes.some(
                    (attr) =>
                        attr.trailerId === unit.id &&
                        (attr.attributeOptionId === AttributeOption.Id.UNIT_COMPREHENSIVE ||
                            attr.attributeOptionId === AttributeOption.Id.UNIT_COLLISION) &&
                        attr.valueNumber
                );

                const startLayerAPD = startLayer.policy.coverages.find(
                    (cov) => cov.coverageOptionId === CoverageOption.Id.TRUCKERS_PHYSICAL_DAMAGE
                );
                const previousIsACV = startLayerAPD?.attributes.some(
                    (attr) => attr.trailerId === unit.id && attr.attributeOptionId === AttributeOption.Id.VALUATION_ACTUAL_CASH_VALUE
                );
                const previousStatedValue = startLayerAPD?.attributes.find(
                    (attr) => attr.trailerId === unit.id && attr.attributeOptionId === AttributeOption.Id.STATED_VALUE
                )?.valueNumber;

                const isRemovedFromPolicy = unit.changes.some((change) => change.isRemovedFromPolicy);

                return {
                    ...unit,
                    isNewToPolicy: unit.changes.some((change) => change.isNewToPolicy),
                    dateAddedToPolicy: unit.changes.find((change) => change.isNewToPolicy)?.date || null,
                    isRemovedFromPolicy,
                    dateRemovedFromPolicy: unit.changes.find((change) => change.isRemovedFromPolicy)?.date || null,
                    isRemovedFromCoverage: unit.changes.reduce((acc, change) => {
                        for (const [coverageId, value] of change.changesByCoverage) {
                            if (value.changes.some((ch) => ch.type === "delete")) {
                                acc.set(coverageId, true);
                            }
                        }
                        return acc;
                    }, new Map()),
                    isNewToCoverage: unit.changes.reduce((acc, change) => {
                        for (const [coverageId, value] of change.changesByCoverage) {
                            if (value.changes.some((ch) => ch.type === "new")) {
                                acc.set(coverageId, true);
                            }
                        }
                        return acc;
                    }, new Map()),
                    isSpare: !!isSpare,
                    isACV: isRemovedFromPolicy ? !!previousIsACV : !!isACV,
                    statedValue: isRemovedFromPolicy ? previousStatedValue || null : statedValue || null,
                    hasUniqueDeductible: !!hasUniqueDeductible,
                    coverages: endLayer.policy.coverages
                        .filter((c) => c.linkedTrailers?.some((t) => t.trailerId === unit.id))
                        .map((c) => ({
                            policyId: c.policyId,
                            coverageOptionId: c.coverageOptionId,
                            effectiveDate: endLayer.policy.effectiveDate.toISOString().substring(0, 10),
                            isUpcoming: moment(endLayer.policy.effectiveDate).isAfter(moment(), "day"),
                        })),
                    additionalInterests: endLayer.policy.additionalInterests.filter((ai) =>
                        ai.linkedTrailers?.some((lt) => lt.trailerId === unit.id)
                    ),
                };
            });

        result.equipment.activeTractors = result.equipment.tractors.filter((t) => MonthlyReportHelper.isTractorOnPolicy(t, endLayer));
        result.equipment.activeTrailers = result.equipment.trailers.filter((t) => MonthlyReportHelper.isTrailerOnPolicy(t, endLayer));
        result.equipment.activeDrivers = result.equipment.drivers.filter((d) => MonthlyReportHelper.isDriverOnPolicy(d, endLayer));

        result.premiumByCoverage = MonthlyReportHelper.getPremiumByCoverage(report, result);
        result.subtotalPremium = Array.from(result.premiumByCoverage.values())
            .flat()
            .reduce((acc, r) => acc + r.subtotalPremium, 0);

        const prorateFactor = MonthlyReportHelper.getProrateFactor(report, endLayer.policy);

        result.minimumPremium =
            (endLayer.policy.attributes.find((att) => att.attributeOptionId === AttributeOption.Id.MINIMUM_MONTHLY_PREMIUM)?.valueNumber ||
                0) *
            (1 - prorateFactor);

        result.totalPremium = Array.from(result.premiumByCoverage.values())
            .flat()
            .reduce((acc, r) => acc + r.totalPremium, 0);

        result.proratedPremiumApplied = Array.from(result.premiumByCoverage.values())
            .map((p) => p.proratedPremiumApplied)
            .reduce((acc, val) => acc + val, 0);

        if (Array.from(result.premiumByCoverage.values()).some((p) => p.minimumPremiumApplied > 0)) {
            result.minimumPremiumApplied = Array.from(result.premiumByCoverage.values())
                .map((p) => p.minimumPremiumApplied)
                .reduce((acc, val) => acc + val, 0);
        }

        if (result.minimumPremium && result.totalPremium < result.minimumPremium) {
            result.minimumPremiumApplied = result.minimumPremium - result.totalPremium;
            result.totalPremium = result.minimumPremium;
        }

        return result;
    }

    public static getUnitsForCoverage(
        data: MonthlyReportData,
        unitType: "tractors" | "trailers" | "drivers",
        coverageId?: CoverageOption.Id
    ) {
        const unitsFilteredByCoverage = (
            unitType === "tractors"
                ? data.equipment.tractors.filter((tractor) => {
                      if (!coverageId) {
                          return true;
                      }
                      return data.endLayer.policy.coverages.some(
                          (cov) =>
                              cov.coverageOptionId === coverageId &&
                              (cov.linkedTractors.some((lt) => lt.tractorId === tractor.id) ||
                                  tractor.changes.some((change) => change.changesByCoverage.has(coverageId)))
                      );
                  })
                : unitType === "trailers"
                ? data.equipment.trailers.filter((unit) => {
                      if (!coverageId) {
                          return true;
                      }
                      return data.endLayer.policy.coverages.some(
                          (cov) =>
                              cov.coverageOptionId === coverageId &&
                              (cov.linkedTrailers.some((lt) => lt.trailerId === unit.id) ||
                                  unit.changes.some((change) => change.changesByCoverage.has(coverageId)))
                      );
                  })
                : data.equipment.drivers.filter((driver) => {
                      if (!coverageId) {
                          return true;
                      }
                      return data.endLayer.policy.coverages.some(
                          (cov) =>
                              cov.coverageOptionId === coverageId &&
                              (cov.linkedDrivers.some((ld) => ld.driverId === driver.id) ||
                                  driver.changes.some((change) => change.changesByCoverage.has(coverageId)))
                      );
                  })
        ) as MonthlyReportDataUnit<Tractor | Trailer | Driver>[];

        return sortBy(
            unitsFilteredByCoverage,
            (x) => (coverageId ? x.isNewToCoverage.has(coverageId) : x.isNewToPolicy),
            (x) => (coverageId ? x.isRemovedFromCoverage.has(coverageId) : x.isRemovedFromPolicy),
            (x) => x.changes.length > 0
        ).reverse();
    }
    public static isDriverOnPolicy(driver: Driver, layer: PolicyLayer): boolean {
        return layer && layer.policy.coverages.some((cov) => cov.linkedDrivers.some((ld) => ld.driverId === driver.id));
    }
    public static isTractorOnPolicy(tractor: Tractor, layer: PolicyLayer): boolean {
        return layer && layer.policy.coverages.some((cov) => cov.linkedTractors.some((lt) => lt.tractorId === tractor.id));
    }
    public static isTrailerOnPolicy(trailer: Trailer, layer: PolicyLayer): boolean {
        return layer && layer.policy.coverages.some((cov) => cov.linkedTrailers.some((lt) => lt.trailerId === trailer.id));
    }

    public static getHasReportDue(
        availableMonths: MonthlyReportAvailableMonth[],
        reports: MonthlyReport[],
        isAdvanced: boolean,
        cancelDate?: Date
    ): boolean {
        if (!availableMonths.length) return false;
        return availableMonths.some(({ year, month }) => {
            const report = reports.find((r) => r.year === year && r.month === month);
            return (
                MonthlyReportHelper.getReportStatus(year, month, !!report?.isFinalized, !!report?.isSubmitted, isAdvanced, cancelDate) ===
                MonthlyReportStatus.Due
            );
        });
    }

    public static getReportStatus(
        year: number,
        month: number,
        isFinalized: boolean,
        isSubmitted: boolean,
        isAdvanced: boolean,
        cancelDate?: Date
    ): MonthlyReportStatus {
        if (isFinalized) {
            return MonthlyReportStatus.Finalized;
        }
        if (isSubmitted) {
            return MonthlyReportStatus.Submitted;
        }
        const m = moment.utc({
            year: year,
            month: month - 1,
            date: 1,
            hour: 12,
            minute: 0,
            second: 0,
            millisecond: 0,
        });
        if (cancelDate && m.isAfter(moment.utc(cancelDate), "month")) {
            return MonthlyReportStatus.NotRequired;
        }

        if (isAdvanced) {
            if (m.isAfter(moment.utc(), "month")) {
                return MonthlyReportStatus.Upcoming;
            } else if (m.isBefore(moment.utc().subtract(1, "months"), "month")) {
                return MonthlyReportStatus.Overdue;
            } else {
                return MonthlyReportStatus.Due;
            }
        } else {
            if (m.isAfter(moment.utc().subtract(1, "month"), "month")) {
                return MonthlyReportStatus.Upcoming;
            } else if (m.isBefore(moment.utc().subtract(1, "month"), "month")) {
                return MonthlyReportStatus.Overdue;
            } else {
                return MonthlyReportStatus.Due;
            }
        }
    }
}

function getDriverExperience(unit: Driver, layer: PolicyLayer): number | null {
    for (const coverage of layer.policy.coverages) {
        if (coverage.linkedDrivers.some((ld) => ld.driverId === unit.id)) {
            const exp = coverage.attributes.find(
                (attr) => attr.driverId === unit.id && attr.attributeOptionId === AttributeOption.Id.DRIVER_YEARS_EXPERIENCE
            );
            if (exp) {
                return exp.valueNumber || 0;
            }
        }
    }
    return null;
}

function getIsDriverOwnerOperator(driver: Driver, layer: PolicyLayer): boolean {
    for (const coverage of layer.policy.coverages) {
        if (coverage.linkedDrivers.some((ld) => ld.driverId === driver.id)) {
            if (
                coverage.attributes.some(
                    (attr) => attr.driverId === driver.id && attr.attributeOptionId === AttributeOption.Id.UNIT_OWNER_OPERATOR
                )
            ) {
                return true;
            }
        }
    }
    return false;
}
