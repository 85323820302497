import { Bars3Icon } from "@heroicons/react/24/outline";
import { Button, Popover, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import { IConfig } from "./Config";
import { Context } from "./Context";

export function Navigation({ config }: { config: IConfig }): JSX.Element {
    const options = useMemo(() => {
        const opts = ["Summary", "History", "Insights", "Crashes", "Inspections", "Violations"];
        if (!config.iftas.hide) {
            opts.push("IFTAs");
        }
        return opts;
    }, [config.iftas.hide]);
    const { activeTab, setActiveTab } = React.useContext(Context);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleOpen = React.useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        setMenuOpen(true);
        setAnchorEl(e.currentTarget);
    }, []);
    const handleClose = React.useCallback(() => {
        setMenuOpen(false);
        setAnchorEl(null);
    }, []);

    return (
        <Button
            className="rounded-full bg-navigator-primary bg-primary text-white print:hidden"
            onClick={(e) => {
                if (menuOpen) {
                    return handleClose();
                }
                handleOpen(e);
            }}
        >
            <div className="flex items-center gap-2 px-2">
                <Bars3Icon className="h-6 w-6 print:hidden" />
                <Typography className="capitalize">{options[activeTab]}</Typography>
            </div>
            <Popover
                open={menuOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <ul className="bg-navigator-primary bg-primary p-4 text-white">
                    {options.map((option, i) => (
                        <li key={option}>
                            <button
                                type="button"
                                className="w-full rounded px-2 py-1 text-left hover:bg-white hover:text-navigator-primary hover:text-primary"
                                onClick={() => setActiveTab(i)}
                            >
                                {option}
                            </button>
                        </li>
                    ))}
                </ul>
            </Popover>
        </Button>
    );
}
