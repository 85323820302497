import { BusinessAuto, CoverageOption, formatVIN } from "@deathstar/types/northstar";
import { EquipmentRow } from "@deathstar/types/waypoint";
import { ActionButton, DataTable, fuzzyFilter, useSnackbar } from "@deathstar/ui";
import { PlusIcon, TableCellsIcon } from "@heroicons/react/24/outline";
import { CircularProgress } from "@material-ui/core";
import { useMutation } from "@tanstack/react-query";
import {
    ColumnDef,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from "@tanstack/react-table";
import moment from "moment";
import { useMemo, useState } from "react";
import { animated, useSpring } from "react-spring";
import Loader from "../../components/loader/Loader";
import EquipmentColumns from "./EquipmentColumns";
import { NewUnitDialog } from "./NewUnitDialog";

const columns = [
    EquipmentColumns.mobileBusinessAutoCell,
    EquipmentColumns.pending,
    EquipmentColumns.upcomingDate,
    EquipmentColumns.unitNumber,
    EquipmentColumns.year,
    EquipmentColumns.make,
    EquipmentColumns.type,
    EquipmentColumns.vin,
    EquipmentColumns.value,
    EquipmentColumns.coverages,
    EquipmentColumns.additionalInterests,
    EquipmentColumns.businessAutoActions,
] as ColumnDef<EquipmentRow<BusinessAuto>>[];

const AnimatedDataTable = animated(DataTable<EquipmentRow<BusinessAuto>>);

export default function BusinessAutosTable({ data }: { data: EquipmentRow<BusinessAuto>[] | undefined }) {
    const [adding, setAdding] = useState(false);
    const [sorting, setSorting] = useState<SortingState>([]);

    const memoizedData = useMemo(() => data || [], [data]);

    const spring = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        pause: !data,
    });

    const sortingState = useMemo(() => {
        return [{ id: "pending", desc: true }, ...sorting];
    }, [sorting]);

    const table = useReactTable({
        data: memoizedData,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getColumnCanGlobalFilter: () => true,
        globalFilterFn: fuzzyFilter,
        state: {
            sorting: sortingState,
        },
        initialState: {
            pagination: {
                pageSize: 30,
            },
        },
        onSortingChange: setSorting,
    });

    const downloadCSV = useMutation({
        mutationFn: async () => {
            if (memoizedData.length === 0) {
                useSnackbar.add("No data to export", { variant: "info" });
                return;
            }

            const rows: string[] = [];
            memoizedData.forEach((row) => {
                rows.push(
                    [
                        row.unitNumber,
                        row.year,
                        row.make,
                        row.type?.name,
                        row.vin,
                        row.policyAcv ? "ACV" : row.policyValue,
                        row.coverages
                            ?.filter((c) => !c.isUpcoming)
                            .map((c) => CoverageOption.getMetadata(c.coverageOptionId).defaultAbbreviation)
                            .join(",") || "",
                    ]
                        .map((x) => `"${x ?? ""}"`)
                        .join(",")
                );
            });

            const csv = [["Unit Number", "Year", "Make", "Type", "VIN", "Value", "Coverage"].join(","), ...rows].join("\n");

            const blob = new Blob([csv], { type: "text/csv" });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.download = "business_autos.csv";
            a.href = url;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },
    });

    return (
        <div>
            {data ? (
                <>
                    <AnimatedDataTable
                        style={spring}
                        table={table}
                        headerRow={
                            <div className="flex flex-wrap items-end justify-end gap-4">
                                <ActionButton disabled={downloadCSV.isPending} onClick={() => downloadCSV.mutate()}>
                                    {downloadCSV.isPending ? <CircularProgress size="1rem" /> : <TableCellsIcon className="h-5 w-5" />}
                                    Export
                                </ActionButton>
                                <ActionButton onClick={() => setAdding(true)}>
                                    <PlusIcon className="h-5 w-5" />
                                    Add business auto
                                </ActionButton>
                                <DataTable.Search table={table} />
                            </div>
                        }
                    />
                    <NewUnitDialog
                        open={adding}
                        onClose={() => setAdding(false)}
                        unitType="autos"
                        defaultValues={{ atDate: moment().format("YYYY-MM-DD") }}
                        getExistingUnit={(vin) => memoizedData.find((r) => formatVIN(r.vin) === formatVIN(vin)) as BusinessAuto}
                    />
                </>
            ) : (
                <Loader />
            )}
        </div>
    );
}
