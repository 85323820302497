import { CertificateWithHolderCount, GlobalCertificateHolder } from "@deathstar/types/northstar";
import { TractorDto, TrailerDto } from "@deathstar/types/waypoint";
import { InputField, PrimaryButton } from "@deathstar/ui";
import { ArrowUpIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Dialog } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import { isEmail, isPhoneNumber, isPostalCode } from "class-validator";
import { capitalize, debounce, uniqBy } from "lodash";
import { useMemo, useRef, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";
import { stringifyAddress } from "../certificates/certificatesUtil";

export function LossPayeeSelect({ open, onClose }: { open: boolean; onClose(): void }) {
    const accountId = useAccountId();
    const inputRef = useRef<HTMLInputElement>(null);
    const close = () => {
        if (inputRef.current) {
            inputRef.current.value = "";
        }
        _setSearch("");
        setCreating(null);
        onClose();
    };
    const [search, _setSearch] = useState("");
    const setSearch = useMemo(() => debounce(_setSearch, 300), []);
    const { data: certificates } = api.certificates.useFind(accountId!);
    const { data: currentLossPayees } = useQuery({
        queryFn: () =>
            Promise.all(
                certificates?.map((c) => api.certificates.findOne(accountId!, c.id)) || ([] as Promise<CertificateWithHolderCount>[])
            ),
        queryKey: [...api.certificates.queryKeys.find(accountId!), certificates?.map((c) => c.id)],
        enabled: !!certificates?.length,
        select: (data) =>
            uniqBy(
                data?.flatMap((c) => c.holders.filter((h) => h.globalHolder.policyAdditionalInterests?.some((pai) => pai.isLossPayee))) ||
                    [],
                "globalHolder.id"
            ),
    });
    const { data: holders } = api.certificates.useSearchGlobalHolders({ keyword: search }, { enabled: search.length > 0 });
    const form = useFormContext<TractorDto | TrailerDto>();
    const [creating, setCreating] = useState<number | null>(null);

    const fieldArray = useFieldArray({
        control: form.control,
        name: "apdLossPayees",
    });

    const apdLossPayees = form.watch("apdLossPayees") || [];

    return (
        <Dialog open={open} onClose={close} classes={{ paper: "p-8 w-screen max-w-lg text-sm" }}>
            {creating !== null ? (
                <div className="mt-2 grid grid-cols-6 gap-4">
                    <InputField
                        classes={{ root: "col-span-6" }}
                        label="Loss payee name"
                        placeholder="Huntington National Bank"
                        {...form.register(`apdLossPayees.${creating}.name`, { required: "Name is required" })}
                        autoFocus
                    />
                    <InputField
                        classes={{ root: "col-span-full" }}
                        label="Street"
                        placeholder="100 N. Capitol Ave."
                        {...form.register(`apdLossPayees.${creating}.street`, { required: "Street is required" })}
                        required
                    />
                    <InputField
                        classes={{ root: "col-span-full" }}
                        label="Street 2"
                        placeholder="Suite 200"
                        {...form.register(`apdLossPayees.${creating}.street2`)}
                    />
                    <InputField
                        classes={{ root: "col-span-3" }}
                        label="City"
                        placeholder="Lansing"
                        {...form.register(`apdLossPayees.${creating}.city`, { required: "City is required" })}
                        required
                    />
                    <InputField
                        classes={{ root: "col-span-3" }}
                        label="State/Province"
                        placeholder="MI"
                        {...form.register(`apdLossPayees.${creating}.state`, {
                            required: "State is required",
                            maxLength: 2,
                        })}
                        required
                    />
                    <InputField
                        classes={{ root: "col-span-3" }}
                        label="Postal code"
                        placeholder="48933"
                        {...form.register(`apdLossPayees.${creating}.zip`, {
                            required: "Postal code is required",
                            validate: (v) => isPostalCode(v, "any") || "Invalid postal code",
                        })}
                        required
                    />

                    <InputField
                        label="Email"
                        classes={{ root: "col-span-full" }}
                        {...form.register(`apdLossPayees.${creating}.email`, {
                            validate: (v) => !v || isEmail(v) || "Invalid email",
                        })}
                        placeholder="jsmith@example.com"
                    />

                    <InputField
                        label="Fax"
                        classes={{ root: "col-span-full" }}
                        {...form.register(`apdLossPayees.${creating}.fax`, {
                            onChange: (e) =>
                                form.setValue(
                                    `apdLossPayees.${creating}.fax`,
                                    e.target.value ? e.target.value.replace(/[^\d]/g, "") : e.target.value
                                ),
                            validate: (v) => !v || isPhoneNumber(v, "US") || "Invalid fax number",
                        })}
                        placeholder="16165551234"
                    />
                    <div className="col-span-full flex justify-center gap-4">
                        <button
                            className="text-stone-500"
                            onClick={() => {
                                fieldArray.remove(creating);
                                setCreating(null);
                            }}
                        >
                            Cancel
                        </button>
                        <PrimaryButton
                            type="button"
                            onClick={() => {
                                setCreating(null);
                            }}
                        >
                            Add loss payee
                        </PrimaryButton>
                    </div>
                </div>
            ) : (
                <div className="flex flex-col space-y-4 overflow-hidden">
                    <div className="">
                        <p className="text-stone-400">This unit's loss payees</p>
                        <div className="ml-4">
                            {apdLossPayees
                                .filter((field) => !field.remove)
                                .map((field, i) => (
                                    <div key={i} className="flex items-center space-x-2 p-1">
                                        <div className="flex-grow">
                                            <p>{field.name}</p>
                                            <p className="text-xs text-stone-500">
                                                {stringifyAddress(field as Required<GlobalCertificateHolder>)}
                                            </p>
                                            <p className="text-xs text-stone-500">{field.email || field.fax}</p>
                                        </div>
                                        <button
                                            type="button"
                                            className="flex items-center gap-2 text-red-600 small-caps"
                                            onClick={() => {
                                                if (
                                                    "additionalInterestId" in field &&
                                                    form.formState.defaultValues?.apdLossPayees?.some(
                                                        (ai) => ai?.additionalInterestId === field.additionalInterestId
                                                    )
                                                ) {
                                                    form.setValue(`apdLossPayees.${i}.remove`, true, {
                                                        shouldDirty: true,
                                                        shouldTouch: true,
                                                    });
                                                } else {
                                                    fieldArray.remove(i);
                                                }
                                            }}
                                        >
                                            <XMarkIcon className="h-3 w-3" />
                                            remove
                                        </button>
                                    </div>
                                ))}
                        </div>
                    </div>
                    <hr />
                    <div>
                        <p className="text-stone-400">Your other loss payees</p>
                        <div className="ml-4">
                            {currentLossPayees
                                ?.filter(
                                    (holder) => !apdLossPayees.some((x) => x.additionalInterestId === holder.globalHolder.id && !x.remove)
                                )
                                .map((holder) => (
                                    <button
                                        type="button"
                                        className="group flex w-full justify-between rounded p-1 text-left hover:bg-stone-50"
                                        key={holder.globalHolder.id}
                                        onClick={() => {
                                            const existingIndex = apdLossPayees.findIndex(
                                                (ai) => ai.additionalInterestId === holder.globalHolder.id
                                            );
                                            if (existingIndex >= 0) {
                                                if (apdLossPayees[existingIndex].remove) {
                                                    form.setValue(`apdLossPayees.${existingIndex}.remove`, false, {
                                                        shouldDirty: true,
                                                        shouldTouch: true,
                                                    });
                                                }
                                            } else {
                                                form.setValue(
                                                    `apdLossPayees.${apdLossPayees.length}`,
                                                    {
                                                        additionalInterestId: holder.globalHolder.id,
                                                        name: holder.globalHolder.name,
                                                        street: holder.globalHolder.street,
                                                        street2: holder.globalHolder.street2,
                                                        city: holder.globalHolder.city,
                                                        state: holder.globalHolder.state,
                                                        zip: holder.globalHolder.zip,
                                                        email: holder.globalHolder.email,
                                                        fax: holder.globalHolder.fax,
                                                        remove: false,
                                                    },
                                                    { shouldDirty: true, shouldTouch: true }
                                                );
                                            }
                                        }}
                                    >
                                        <p>{holder.globalHolder.name}</p>
                                        <div className="flex items-center gap-1 text-blue-600 opacity-0 transition-opacity small-caps group-hover:opacity-100">
                                            Add to unit
                                            <ArrowUpIcon className="h-3 w-3" />
                                        </div>
                                    </button>
                                ))}
                        </div>
                    </div>
                    <hr />
                    <div className="col-span-full flex flex-col gap-4 overflow-hidden p-0.5">
                        <InputField
                            classes={{ root: "w-full" }}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="Search loss payees by name..."
                        />
                        <div className="w-full space-y-1 overflow-y-auto">
                            {holders?.data
                                .filter((holder) => !apdLossPayees.some((x) => x.additionalInterestId === holder.id && !x.remove))
                                .map((holder) => (
                                    <button
                                        type="button"
                                        className="group flex w-full items-center justify-between rounded p-1 text-left hover:bg-stone-50"
                                        key={holder.id}
                                        onClick={() => {
                                            const existingIndex = apdLossPayees.findIndex((ai) => ai.additionalInterestId === holder.id);
                                            if (existingIndex >= 0) {
                                                if (apdLossPayees[existingIndex].remove) {
                                                    form.setValue(`apdLossPayees.${existingIndex}.remove`, false, {
                                                        shouldDirty: true,
                                                        shouldTouch: true,
                                                    });
                                                }
                                            } else {
                                                form.setValue(
                                                    `apdLossPayees.${apdLossPayees.length}`,
                                                    {
                                                        additionalInterestId: holder.id,
                                                        name: holder.name,
                                                        street: holder.street,
                                                        street2: holder.street2,
                                                        city: holder.city,
                                                        state: holder.state,
                                                        zip: holder.zip,
                                                        email: holder.email,
                                                        fax: holder.fax,
                                                        remove: false,
                                                    },
                                                    { shouldDirty: true, shouldTouch: true }
                                                );
                                            }
                                        }}
                                    >
                                        <div>
                                            <p>{holder.name}</p>
                                            <p className="text-stone-500">{stringifyAddress(holder)}</p>
                                        </div>
                                        <div className="flex items-center gap-1 text-blue-600 opacity-0 transition-opacity small-caps group-hover:opacity-100">
                                            Add to unit
                                            <ArrowUpIcon className="h-3 w-3" />
                                        </div>
                                    </button>
                                ))}
                        </div>
                        {search.length > 0 && (
                            <div className="text-center">
                                <p className="text-stone-500">Don't see what you're looking for?</p>
                                <button
                                    className="text-blue-600"
                                    type="button"
                                    onClick={() => {
                                        form.setValue(
                                            `apdLossPayees.${apdLossPayees.length}`,
                                            {
                                                name: capitalize(search),
                                                street: "",
                                                street2: "",
                                                city: "",
                                                state: "",
                                                zip: "",
                                                email: "",
                                                fax: "",
                                            },
                                            { shouldDirty: true, shouldTouch: true }
                                        );
                                        setCreating(apdLossPayees.length);
                                    }}
                                >
                                    Create a new loss payee
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </Dialog>
    );
}
