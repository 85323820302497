import { Transform } from "class-transformer";
import { IsBoolean, IsEmail, IsOptional, IsPhoneNumber, IsPostalCode, IsString, Matches } from "class-validator";
import type { PolicyAdditionalInterest, User } from ".";
import type { User as WaypointUser } from "../waypoint";

export class GlobalCertificateHolder {
    id: number;
    createDate: Date;
    updateDate: Date;
    approvedByGuid: string;
    approvedBy: User;
    updatedByGuid: string;
    updatedBy: User;
    accountId?: string;

    submittedByWaypointUserId: string;
    submittedByWaypointUser: WaypointUser;

    @IsOptional({ groups: ["update"] })
    @IsString()
    name: string;

    @IsOptional({ groups: ["update"] })
    @IsBoolean()
    pending: boolean;

    @IsOptional({ groups: ["update"] })
    @IsBoolean()
    hidden: boolean;

    @IsOptional({ groups: ["update"] })
    @IsString()
    street: string;

    @Transform(({ value }) => (value === "" ? null : value))
    @IsOptional()
    @IsString()
    street2: string;

    @IsOptional({ groups: ["update"] })
    @IsString()
    city: string;

    @IsOptional({ groups: ["update"] })
    @IsString()
    @Matches(/^[A-Z]{2}$/)
    state: string;

    @IsOptional({ groups: ["update"] })
    @IsString()
    @IsPostalCode("any")
    zip: string;

    @Transform(({ value }) => (value === "" ? null : value))
    @IsOptional()
    @IsEmail()
    email: string;

    @Transform(({ value }) => (value === "" ? null : value))
    @IsOptional()
    @IsString()
    @IsPhoneNumber("US")
    fax: string;

    @IsOptional()
    @IsBoolean()
    isOwnerOperatorEmployer: boolean;

    policyAdditionalInterests: PolicyAdditionalInterest[];

    potentialDuplicates?: GlobalCertificateHolder[];
}

type OmittedFields = "policyAdditionalInterests" | "approvedBy" | "updatedBy" | "deletedBy" | "submittedByWaypointUser";

export type GlobalCertificateHolderCreateDTO = Omit<
    GlobalCertificateHolder,
    "id" | "createDate" | "updateDate" | "deleteDate" | "updatedByGuid" | "deletedByGuid" | "hidden" | OmittedFields
>;

export type GlobalCertificateHolderUpdateDTO = Partial<Omit<GlobalCertificateHolder, OmittedFields>>;

export interface GlobalHolderSaveResult {
    data: Omit<GlobalCertificateHolder, OmittedFields>;
    saved: true;
    duplicateOf?: never;
}

export interface GlobalHolderDuplicateResult {
    data: GlobalCertificateHolderCreateDTO;
    saved: false;
    duplicateOf: {
        holder: Omit<GlobalCertificateHolder, OmittedFields>;
        distance: number;
    };
}

export type GlobalHolderResult<Saved extends boolean = boolean> = Saved extends true ? GlobalHolderSaveResult : GlobalHolderDuplicateResult;

export interface GlobalCertificateHolderSaveResponse {
    results: GlobalHolderResult[];
}

export class GlobalCertificateHolderQuery {
    @Transform(({ value }) => (value ? parseInt(value) : null))
    pageIndex?: number;

    @Transform(({ value }) => (value ? parseInt(value) : null))
    pageSize?: number;

    keyword?: string;

    @Transform(({ value }) => value === "true" || value === "1")
    checkForDuplicates?: boolean;
}

export interface GlobalCertificateHolderPagination {
    data: GlobalCertificateHolder[];
    pageIndex: number;
    pageSize: number;
    totalCount: number;
}
