import { CircularProgress } from "@material-ui/core";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";
import { stringifyAddress } from "../certificates/certificatesUtil";

export default function AddressSelector({ disabled }: { disabled?: boolean }) {
    const form = useFormContext<{ propertyId: number | null }>();
    const accountId = useAccountId();
    const { data } = api.properties.useFind(accountId!, {
        select: (properties) => properties.filter((p) => p.typeString?.toLowerCase().includes("terminal")),
    });

    useEffect(() => {
        if (data && !form.getFieldState("propertyId").isTouched) {
            form.setValue("propertyId", data.find((p) => p.typeString?.toLowerCase().includes("primary terminal"))?.id ?? data[0].id);
        }
    }, [data, form]);

    return (
        <div className="col-span-6">
            <p>Terminal</p>
            {data ? (
                <select
                    {...form.register("propertyId", {
                        setValueAs: (value) => (value ? parseInt(value) : null),
                        required: !disabled && "Please select a terminal",
                    })}
                    disabled={disabled}
                    className="form-select w-full rounded-lg border-stone-300 text-sm enabled:cursor-pointer"
                >
                    {data.map((property) => (
                        <option key={property.id} value={property.id}>
                            {stringifyAddress({
                                street: property.street1,
                                street2: property.street2,
                                city: property.city,
                                state: property.state,
                                zip: property.zip,
                            })}
                        </option>
                    ))}
                </select>
            ) : (
                <div className="flex w-full justify-center">
                    <CircularProgress />
                </div>
            )}
        </div>
    );
}
