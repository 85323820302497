import { CircularProgress, Paper, Typography } from "@material-ui/core";
import {
    ArcElement,
    BarController,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    LinearScale,
    LineController,
    LineElement,
    PieController,
    PointElement,
    Title,
    Tooltip,
} from "chart.js";
import Annotation from "chartjs-plugin-annotation";
import React from "react";

import { MotorCarrier } from "@deathstar/motor-carrier";
import { DateTime } from "@deathstar/types/util";
import "./InspectionAnalysis.css";

import { Context } from "./Context";
// import { Icon } from "./Icon";
import { printElement } from "@deathstar/ui";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FmcsaLogoIcon } from "../icons/fmcsa-logo";
import { FmcsaLogoInvertedIcon } from "../icons/fmcsa-logo-inverted";
import { Panel } from "./Components/Panel";
import { IConfig } from "./Config";
import { Crashes } from "./Crashes/Crashes";
import { useCrashPage } from "./Crashes/useCrashPage";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { History } from "./History/History";
import { IFTAs } from "./IFTAs/IFTAs";
import { useIFTAsPageStore } from "./IFTAs/useIFTAsMap";
import { Insights } from "./Insights/Insights";
import { Inspections } from "./Inspections/Inspections";
import { useInspectionPage } from "./Inspections/useInspectionPage";
import { AccidentRate } from "./Summary/AccidentRate";
import { AuditResult } from "./Summary/AuditResult";
import { CurrentScores } from "./Summary/CurrentScores";
import { IssScores } from "./Summary/IssScores";
import { OutOfServiceRates } from "./Summary/OutOfServiceRates";
import { Summary } from "./Summary/Summary";
import { useViolationPage } from "./Violations/useViolationPage";
import { Violations } from "./Violations/Violations";

export const Icon = {
    FmcsaLogoIcon,
    FmcsaLogoInvertedIcon,
};

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    ArcElement,
    PointElement,
    PieController,
    BarController,
    LineController,
    Tooltip,
    Title,
    Annotation
);

function generateSmsScoreDates({ snapshotDate, totalMonths }: { snapshotDate: DateTime; totalMonths: number }): DateTime[] {
    const smsScoreDates: DateTime[] = [snapshotDate];
    for (let i = 1; i < totalMonths; i++) {
        smsScoreDates.push(DateTime.subtractMonths(snapshotDate, i));
    }
    return smsScoreDates.reverse();
}

function useMotorCarrier({ dot, smsScoreDates, fetcher }: { dot: number; smsScoreDates?: Date[]; fetcher: MotorCarrier.IFetcher }) {
    return useMutation({
        mutationFn: async (): Promise<MotorCarrier> => {
            const latestSnapshotDate = await fetcher.fetchLatestSnapshotDate();
            const snapshotDate = DateTime.fromDate(latestSnapshotDate);
            const mc = await MotorCarrier.new({
                dot,
                snapshotDate: snapshotDate,
                smsScoreDates: smsScoreDates?.map((d) => DateTime.fromDate(d)) ?? generateSmsScoreDates({ snapshotDate, totalMonths: 24 }),
                fetcher,
                config: {
                    // forceCalculateBasicMeasures: true,
                },
            });

            return mc;
        },
    });
}

export function WebIAFMCSASnapshot({
    dot,
    smsScoreDates,
    fetcher,
    loadingMessage,
    currentScores,
}: {
    dot: number;
    smsScoreDates?: Date[];
    fetcher: MotorCarrier.IFetcher;
    loadingMessage?: string | JSX.Element;
    currentScores?: boolean;
}): JSX.Element {
    const { mutateAsync: fetchMotorCarrier } = useMotorCarrier({ dot, smsScoreDates, fetcher });

    const {
        data: motorCarrier,
        isLoading,
        isError,
        error,
    } = useQuery({
        queryKey: ["inspection-analysis", dot],
        queryFn: () =>
            fetchMotorCarrier().then((mc) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (window as any).mc = mc;
                return mc.filterByDateRange({
                    dateRange: MotorCarrier.calculateDateRange({ snapshotDate: mc.dateRange.to }),
                    keepAllSmsResults: true,
                });
            }),
        refetchInterval: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        staleTime: Infinity,
    });

    return (
        <>
            {isLoading && (
                <div className="flex gap-2" style={{ transform: "scale(1.3)", transformOrigin: "top left" }}>
                    <CircularProgress color="inherit" className="text-primary" size="20px" />
                    {typeof loadingMessage === "string" && <Typography>{loadingMessage}</Typography>}
                    {typeof loadingMessage === "function" && loadingMessage}
                    {!loadingMessage && (
                        <Typography>
                            Loading <span className="italic">Web</span>IA... ({dot})
                        </Typography>
                    )}
                </div>
            )}
            {isError && <code>{String(error)}</code>}

            {motorCarrier && !isLoading && <FMCSASnapshot motorCarrier={motorCarrier} currentScores={currentScores} />}
        </>
    );
}

export function WebIA({
    dot,
    smsScoreDates,
    fetcher,
    loadingMessage,
    config,
}: {
    dot: number;
    smsScoreDates?: Date[];
    fetcher: MotorCarrier.IFetcher;
    loadingMessage?: string | JSX.Element;
    config?: Partial<IConfig>;
}): JSX.Element {
    const { mutateAsync: fetchMotorCarrier } = useMotorCarrier({ dot, smsScoreDates, fetcher });

    const {
        data: motorCarrier,
        isLoading,
        isError,
        error,
    } = useQuery({
        queryKey: ["inspection-analysis", dot],
        queryFn: () =>
            fetchMotorCarrier().then((mc) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (window as any).mc = mc;
                return mc.filterByDateRange({
                    dateRange: MotorCarrier.calculateDateRange({ snapshotDate: mc.dateRange.to }),
                    keepAllSmsResults: true,
                });
            }),
        refetchInterval: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        staleTime: Infinity,
    });

    return (
        <div id="ia-print" className="ia-print flex w-full flex-col gap-4 overflow-hidden">
            {isLoading && (
                <div className="flex gap-2">
                    <CircularProgress color="inherit" className="text-primary" size="20px" />
                    {typeof loadingMessage === "string" && <Typography>{loadingMessage}</Typography>}
                    {typeof loadingMessage === "function" && loadingMessage}
                    {!loadingMessage && (
                        <Typography>
                            Loading <span className="italic">Web</span>IA... ({dot})
                        </Typography>
                    )}
                </div>
            )}
            {isError && <code>{String(error)}</code>}

            {motorCarrier && !isLoading && <App motorCarrier={motorCarrier} appConfig={config} fetcher={fetcher} />}
        </div>
    );
}

export function App({
    motorCarrier,
    appConfig,
    fetcher,
}: {
    motorCarrier: MotorCarrier;
    appConfig?: Partial<IConfig>;
    fetcher: MotorCarrier.IFetcher;
}): JSX.Element {
    const [activeTab, setActiveTab] = React.useState(0);
    const ref = React.useRef<HTMLDivElement>(null);
    const config: IConfig = React.useMemo(() => {
        return {
            insuranceHistory: {
                hide: appConfig?.insuranceHistory?.hide ?? false,
            },
            iftas: {
                hide: appConfig?.iftas?.hide ?? false,
            },
        };
    }, [appConfig]);

    const {
        table: crashTable,
        data: crashData,
        mapController: crashMapController,
        setTableFilter: setCrashTableFilter,
    } = useCrashPage(motorCarrier);
    const {
        table: inspectionTable,
        data: inspectionData,
        mapController: inspectionMapController,
        setTableFilter: setInspectionTableFilter,
    } = useInspectionPage(motorCarrier);
    const {
        table: violationTable,
        data: violationData,
        mapController: violationMapController,
        setTableFilter: setViolationTableFilter,
    } = useViolationPage(motorCarrier);

    const onPrint = React.useCallback(() => {
        if (!ref.current) return;

        const docTitle = document.title;
        const resetCrashMap = crashMapController.onPrint();
        const resetInspectionMap = inspectionMapController.onPrint();
        const resetViolationMap = violationMapController.onPrint();
        useIFTAsPageStore.setState({ isPrinting: true });
        setTimeout(() => {
            if (!ref.current) return;

            document.title = `${motorCarrier.name} DOT Analysis, ${motorCarrier.dateRange.to.format("Mmmm YYYY")}`;

            const iaPrintElement = document.getElementById("ia-print");
            const canvasElements = iaPrintElement?.querySelectorAll("canvas") || [];
            const printElements = (iaPrintElement?.querySelectorAll(".ia-transition-element") || []) as HTMLDivElement[];

            const undoChartStyles = Array.from(printElements)?.map((el) => {
                const originalOpacity = el.style.opacity;
                const originalVisibility = el.style.visibility;
                const originalTransition = el.style.transition;
                const originalTransform = el.style.transform;
                const originalClassName = el.className;
                el.style.opacity = "1";
                el.style.visibility = "visible";
                el.style.transition = "none";
                el.style.transform = "none";
                el.className += " print:block ";

                return () => {
                    el.style.opacity = originalOpacity;
                    el.style.visibility = originalVisibility;
                    el.style.transition = originalTransition;
                    el.style.transform = originalTransform;
                    el.className = originalClassName;
                };
            });

            const undoPrintableImage = Array.from(canvasElements).map((canvas) => {
                const dataUrl = canvas.toDataURL("image/png", 1.0);

                const img = new Image();
                img.src = dataUrl;
                img.style.height = "100%";
                // img.className = " print:block";

                const printHeight = canvas.getAttribute("data-print-height");
                const resetHeight = canvas.height;
                if (printHeight) {
                    img.style.height = printHeight;
                }

                // canvas.replaceWith(img);
                canvas.style.display = "none";
                canvas.parentNode?.append(img);
                return () => {
                    if (printHeight) {
                        canvas.height = resetHeight;
                        canvas.style.height = `${resetHeight}px`;
                    }
                    canvas.style.display = "block";
                    // img.replace(canvas);
                    img.remove();
                };
            });

            printElement(ref.current, { margin: "0px" }).then(() => {
                resetCrashMap();
                resetInspectionMap();
                resetViolationMap();
                useIFTAsPageStore.setState({ isPrinting: false });
                undoPrintableImage.forEach((undo) => undo());
                undoChartStyles.forEach((undo) => undo());
                setTimeout(() => {
                    document.title = docTitle;
                }, 2000);
            });
        }, 0);
    }, [crashMapController, inspectionMapController, motorCarrier.dateRange.to, motorCarrier.name, violationMapController]);

    React.useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if ((e.ctrlKey || e.metaKey) && e.key === "p") {
                e.preventDefault();
                onPrint();
            }
        };

        document.addEventListener("keydown", handleKeyDown);
        return () => document.removeEventListener("keydown", handleKeyDown);
    }, [onPrint]);

    return (
        <Context.Provider
            value={{
                activeTab,
                setActiveTab,
                motorCarrier,
                config,
            }}
        >
            <Paper id="dot-analysis" className="w-fill mb-2 max-w-[1600px] rounded-lg print:shadow-none" elevation={6} ref={ref}>
                <div className="page-header">
                    <Header onPrint={onPrint} motorCarrier={motorCarrier} config={config} />
                </div>

                <table className="w-full">
                    <thead>
                        <tr>
                            <td>
                                <div className="page-header-space"></div>
                            </td>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>
                                <Panel panelIndex={0} activeTabIndex={activeTab}>
                                    <Summary />
                                    <Footer className="print:hidden" gray />
                                </Panel>

                                <Panel panelIndex={1} activeTabIndex={activeTab}>
                                    <History />
                                    <Footer className="print:hidden" gray={config.insuranceHistory.hide} />
                                </Panel>

                                <Panel panelIndex={2} activeTabIndex={activeTab}>
                                    <Insights />
                                    <Footer className="print:hidden" gray />
                                </Panel>

                                <Panel panelIndex={3} activeTabIndex={activeTab}>
                                    <Crashes
                                        mapController={crashMapController}
                                        table={crashTable}
                                        data={crashData}
                                        resetTableFilter={() => setCrashTableFilter(undefined)}
                                    />
                                    <Footer className="print:hidden" />
                                </Panel>

                                <Panel panelIndex={4} activeTabIndex={activeTab}>
                                    <Inspections
                                        mapController={inspectionMapController}
                                        table={inspectionTable}
                                        data={inspectionData}
                                        resetTableFilter={() => setInspectionTableFilter(undefined)}
                                    />
                                    <Footer className="print:hidden" />
                                </Panel>

                                <Panel panelIndex={5} activeTabIndex={activeTab}>
                                    <Violations
                                        mapController={violationMapController}
                                        table={violationTable}
                                        data={violationData}
                                        resetTableFilter={() => setViolationTableFilter(undefined)}
                                    />
                                    <Footer className="print:hidden" />
                                </Panel>

                                {!config.iftas.hide && (
                                    <Panel panelIndex={6} activeTabIndex={activeTab}>
                                        <IFTAs motorCarrier={motorCarrier} fetcher={fetcher} />
                                        <Footer className="print:hidden" />
                                    </Panel>
                                )}
                            </td>
                        </tr>
                    </tbody>

                    <tfoot>
                        <tr>
                            <td>
                                <div className="page-footer-space"></div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </Paper>
        </Context.Provider>
    );
}

export function FMCSASnapshot({ motorCarrier, currentScores }: { motorCarrier: MotorCarrier; currentScores?: boolean }): JSX.Element {
    const [activeTab, setActiveTab] = React.useState(0);
    const config: IConfig = { insuranceHistory: { hide: true }, iftas: { hide: true } };

    return (
        <Context.Provider
            value={{
                activeTab,
                setActiveTab,
                motorCarrier,
                config,
            }}
        >
            <div className="flex w-full justify-between [&_*]:!text-sm [&_h5]:!text-base [&_section]:bg-white [&_section]:pt-0">
                <div className="flex grow justify-between gap-8 whitespace-nowrap px-6">
                    <IssScores className="w-fit" />
                    <OutOfServiceRates className="w-fit" />
                    <AuditResult className="w-fit" />
                    <AccidentRate className="w-fit" />
                </div>
                {currentScores && (
                    <div className="relative top-[-80px] w-1/2 [&>section]:grid-cols-[1fr_2fr]">
                        <CurrentScores />
                    </div>
                )}
            </div>
        </Context.Provider>
    );
}
