import React from "react";
import { IOptions as PaymentsTabOptions } from "./Payments";
import { IOptions as PoliciesTabOptions } from "./Policies";
import { IOptions as SettingsTabOptions } from "./Settings";

export interface IContext extends PoliciesTabOptions, PaymentsTabOptions, Omit<SettingsTabOptions, "billingInfo"> {}

export const Context = React.createContext<IContext>({
    isLoading: false,
    paymentHistoryData: [],
    policies: [],
    stripeDashboardLink: "",
    user: undefined,
    billingInfo: undefined,
    isError: false,
    account: undefined as unknown as SettingsTabOptions["account"],
    createCustomer: undefined as unknown as SettingsTabOptions["createCustomer"],
    onAutoMaticPaymentsChange: () => Promise.resolve(),
    onSubmitBillingProfileChange: () => Promise.resolve(),
    onWaypointsSubscriptionChange: () => Promise.resolve(),
    subscription: undefined as unknown as SettingsTabOptions["subscription"],
    createPayment: undefined,
    enableActionMenu: undefined,
    enablePayNow: undefined,
    isLoadingBillingInfo: undefined,
    viewSpecialPaymentTerms: undefined,
});
