import create from "zustand";

interface IFTAPageStore {
    isPrinting: boolean;
    showInspections: boolean;
    showCrashes: boolean;
    showHighRiskStates: boolean;
    selectedIFTAs: { year: number; quarter: number }[];
    iftaNavigationYear: number;

    toggleShowInspections(): void;
    toggleShowCrashes(): void;
    toggleShowHighRiskStates(): void;
    setSelectedIFTAs(iftas: { year: number; quarter: number }[]): void;
    setIFTANavigationYear(year: number): void;
}

export const useIFTAsPageStore = create<IFTAPageStore>((set, get) => ({
    isPrinting: false,
    showInspections: false,
    showCrashes: false,
    showHighRiskStates: false,
    selectedIFTAs: [],
    iftaNavigationYear: 0,

    toggleShowInspections() {
        set({ showInspections: !get().showInspections });
    },
    toggleShowCrashes() {
        set({ showCrashes: !get().showCrashes });
    },
    toggleShowHighRiskStates() {
        set({ showHighRiskStates: !get().showHighRiskStates });
    },
    setSelectedIFTAs(iftas) {
        set({ selectedIFTAs: iftas });
    },
    setIFTANavigationYear(year) {
        set({ iftaNavigationYear: year });
    },
}));
