import { classNames, Img, PrimaryButton } from "@deathstar/ui";
import { ChatBubbleLeftRightIcon } from "@heroicons/react/24/outline";
import Title from "../../components/title/Title";

import { Link } from "react-router-dom";
import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";
import { blazar } from "../../api/util/blazar";
import jillSrc from "../../assets/images/jill_hendrickson.jpg";
import mikeSrc from "../../assets/images/mike_dantuma.jpg";
import HelpCenterCard from "./HelpCenterCard";
import OOProgramMembershipCard from "./OOProgramMembershipCard";

export default function Dashboard() {
    return (
        <div className="p-4 lg:p-12">
            <Title className="mb-2 text-waypoint-blue-dark">Welcome to Waypoint</Title>
            <hr />

            <div className="mt-8 space-y-12">
                <OOProgramMembershipCard />
                <div className="flex flex-col gap-8 rounded bg-waypoint-blue-light/10 p-4 md:flex-row">
                    <AskAnythingImagesGrid className="!hidden md:!grid" />
                    <div className="space-y-4">
                        <div className="flex items-center gap-4">
                            <span className="h-max shrink-0 grow-0 rounded border border-pink-100 bg-pink-50 px-2 py-1 text-sm leading-3 text-pink-700 small-caps">
                                New!
                            </span>

                            <p className="text-lg font-medium">Ask anything, any time</p>
                        </div>
                        <AskAnythingImagesGrid className="md:hidden" />
                        <p className="max-w-prose text-sm font-light">
                            Ask any question about compliance, legal matters, crash and inspection violations, and more. Lean on our
                            trucking experience; we'll walk with you every step of the way.
                        </p>
                        <Link
                            to="advice"
                            className="block"
                            onClick={() => {
                                try {
                                    window.trace.trackEvent("dashboard.ask-anything.click");
                                } catch (e) {
                                    console.error(e);
                                }
                            }}
                        >
                            <PrimaryButton className="!bg-waypoint-blue !py-1 !text-sm">
                                <ChatBubbleLeftRightIcon className="h-4 w-4" />
                                Ask a question
                            </PrimaryButton>
                        </Link>
                    </div>
                </div>

                <HelpCenterCard />
            </div>
        </div>
    );
}

function AskAnythingImagesGrid({ className }: { className?: string }) {
    const accountId = useAccountId();
    const { data: organization } = api.organization.useById(accountId!);

    return (
        <div
            className={classNames("grid grid-cols-[repeat(4,1fr)] gap-2 md:grid-cols-[100px,100px] md:grid-rows-[100px,100px]", className)}
        >
            <Img width={100} className="w-[100px] shrink-0 rounded" src={jillSrc} title="Jill Hendrickson">
                <div className="h-full w-full rounded bg-black/10" />
            </Img>
            <Img width={100} className="w-[100px] shrink-0 rounded" src={mikeSrc} title="Mike Dantuma">
                <div className="h-full w-full rounded bg-black/10" />
            </Img>
            {organization ? (
                <Img
                    width={100}
                    className="w-[100px] shrink-0 rounded"
                    src={`${blazar.getBaseUrl()}api/waypoint/personnel/${organization.rnManager.upn}/img`}
                    title={organization.rnManager.displayName || undefined}
                >
                    <div className="h-full w-full rounded bg-black/10" />
                </Img>
            ) : (
                <div className="h-full w-full rounded bg-black/10" />
            )}
            {organization ? (
                <Img
                    width={100}
                    className="w-[100px] shrink-0 rounded"
                    src={`${blazar.getBaseUrl()}api/waypoint/personnel/${organization.agent.upn}/img`}
                    title={organization.agent.displayName || undefined}
                >
                    <div className="h-full w-full rounded bg-black/10" />
                </Img>
            ) : (
                <div className="h-full w-full rounded bg-black/10" />
            )}
        </div>
    );
}
