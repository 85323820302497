import type { Account } from "./Account";
import { Driver } from "./Driver";
import type { EquipmentType } from "./EquipmentType";
import { PendingChange } from "./PendingChange";
import { PolicyAdditionalInterest } from "./PolicyAdditionalInterest";
import { Property } from "./Property";
import { AccountEquipmentStatus } from "./Tractor";
import { User } from "./User";
import { Vehicle } from "./Vehicle";

export class Trailer {
    id: number;

    createDate: Date;

    updateDate: Date;

    createdByGuid: string;

    createdBy: User;

    submit: boolean;

    isDeleted: boolean;

    accountId: string;

    year: number | null;

    make: string | null;

    typeId: EquipmentType["id"];

    type: EquipmentType;

    vin: string | null;

    acv: boolean;

    comprehensive: boolean;

    collision: boolean;

    value: number | null;

    ownerOperator: boolean;

    vinValid: number | null;

    spare: boolean;

    unitNumber: string | null;

    comments: string | null;

    createdByWaypointUserId: string;

    drivers?: Driver[];

    account: Account;

    additionalInterests?: PolicyAdditionalInterest[];

    propertyId: number;

    property?: Property;

    policyUpcomingDate?: string | null | undefined;
    policyValue?: number | null | undefined;
    policyOwnerOperator?: boolean | null | undefined;
    policySpare?: boolean | null | undefined;
    policyAcv?: boolean | null | undefined;
    policyComprehensive?: boolean | null | undefined;
    policyCollision?: boolean | null | undefined;
    statuses?: AccountEquipmentStatus[];
    coverages?: { policyId: string; coverageOptionId: number; effectiveDate: string; isUpcoming: boolean }[];

    pendingChanges?: PendingChange[];

    ownerOperatorAPDFirstNamedInsureds?: string[];

    toString(): string {
        return Vehicle.toString(this);
    }
}
