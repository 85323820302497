import { Account } from "@deathstar/types/northstar";
import { QueryKey, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { blazar } from "../util/blazar";
import { ResponseError } from "../util/exceptions";

export const organization = {
    queryKeys: {
        getById: (accountId: string) => ["organization", accountId] as QueryKey,
        getType: (accountId: string) => ["organization", accountId, "type"] as QueryKey,
    },

    getById: (accountId: string) => {
        return blazar.fetchJson<Account>(`waypoint/orgs/${accountId}`);
    },

    useById: <T = Account>(accountId: string, config?: Partial<UseQueryOptions<Account, ResponseError, T>>) =>
        useQuery({
            queryKey: organization.queryKeys.getById(accountId),
            queryFn: () => organization.getById(accountId),
            staleTime: 30_000,
            ...config,
        }),

    getType: (accountId: string) => {
        return blazar.fetchJson<{ type: Account["type"] }>(`waypoint/orgs/${accountId}/type`);
    },

    useType: (accountId: string, config?: Partial<UseQueryOptions<{ type: Account["type"] }, ResponseError>>) =>
        useQuery({
            queryKey: organization.queryKeys.getType(accountId),
            queryFn: () => organization.getType(accountId),
            staleTime: Infinity,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            ...config,
        }),
};
