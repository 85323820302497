import { MonthlyReport, MonthlyReportPolicyData, MonthlyReportQueryResult } from "@deathstar/types/northstar";
import { useSnackbar } from "@deathstar/ui";
import { QueryKey, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { queryClient } from "../../util/queryClient";
import { blazar } from "../util/blazar";
import { ResponseError } from "../util/exceptions";

export const monthlyReports = {
    queryKeys: {
        find: (accountId: string) => [accountId, "monthlyReports"] as QueryKey,
        findForMonth: (accountId: string, policyId: string, year: number, month: number) =>
            [accountId, "monthlyReports", policyId, year, month] as QueryKey,
    },

    async find(accountId: string): Promise<MonthlyReportPolicyData[]> {
        const data = await blazar.fetchJson<MonthlyReportPolicyData[]>(`/waypoint/orgs/${accountId}/monthly-reports`);
        return blazar.changeDateStringsToDateObjects(data);
    },

    useFind(accountId: string, config?: Partial<UseQueryOptions<MonthlyReportPolicyData[], ResponseError>>) {
        return useQuery<MonthlyReportPolicyData[], ResponseError>({
            queryKey: monthlyReports.queryKeys.find(accountId),
            queryFn: () => monthlyReports.find(accountId),
            ...config,
        });
    },

    async findForMonth(accountId: string, policyId: string, year: number, month: number): Promise<MonthlyReportQueryResult> {
        const data = await blazar.fetchJson<MonthlyReportQueryResult>(
            `/waypoint/orgs/${accountId}/policies/${policyId}/monthly-reports/${year}/${month}`
        );
        return blazar.changeDateStringsToDateObjects(data);
    },

    useFindForMonth(
        accountId: string,
        policyId: string,
        year: number,
        month: number,
        config?: Partial<UseQueryOptions<MonthlyReportQueryResult, ResponseError>>
    ) {
        return useQuery<MonthlyReportQueryResult, ResponseError>({
            queryKey: monthlyReports.queryKeys.findForMonth(accountId, policyId, year, month),
            queryFn: () => monthlyReports.findForMonth(accountId, policyId, year, month),
            ...config,
        });
    },

    async update(
        accountId: string,
        policyId: string,
        year: number,
        month: number,
        data: Partial<Pick<MonthlyReport, "mileage" | "revenue" | "payroll">>
    ) {
        return await blazar.fetchJson<void>(`/waypoint/orgs/${accountId}/policies/${policyId}/monthly-reports/${year}/${month}`, {
            method: "PATCH",
            body: JSON.stringify(data),
        });
    },

    /** Does not automatically invalidate the query and refresh the data */
    useUpdate(
        accountId: string,
        policyId: string,
        year: number,
        month: number,
        config?: UseMutationOptions<void, ResponseError, Partial<Pick<MonthlyReport, "mileage" | "revenue" | "payroll">>>
    ) {
        return useMutation<void, ResponseError, Partial<Pick<MonthlyReport, "mileage" | "revenue" | "payroll">>>({
            mutationFn: (data) => monthlyReports.update(accountId, policyId, year, month, data),
            ...config,
        });
    },

    async create(accountId: string, policyId: string, year: number, month: number) {
        return await blazar.fetchJson<void>(`/waypoint/orgs/${accountId}/policies/${policyId}/monthly-reports/${year}/${month}`, {
            method: "POST",
        });
    },

    useCreate: (accountId: string, policyId: string, config?: UseMutationOptions<void, ResponseError, { year: number; month: number }>) => {
        return useMutation<void, ResponseError, { year: number; month: number }>({
            mutationFn: ({ year, month }) => monthlyReports.create(accountId, policyId, year, month),
            ...config,
            onSuccess: (...args) => {
                config?.onSuccess?.(...args);
                queryClient.invalidateQueries({ queryKey: monthlyReports.queryKeys.find(accountId) });
            },
        });
    },

    async submit(accountId: string, policyId: string, year: number, month: number) {
        return blazar
            .fetchJson<void>(`/waypoint/orgs/${accountId}/policies/${policyId}/monthly-reports/${year}/${month}/submit`, {
                method: "POST",
            })
            .then(() => {
                try {
                    window.trace.trackEvent("monthly_report_submitted");
                } catch (e) {
                    console.error(e);
                }
            });
    },

    useSubmit: (accountId: string, config?: UseMutationOptions<void, ResponseError, { policyId: string; year: number; month: number }>) => {
        return useMutation<void, ResponseError, { policyId: string; year: number; month: number }>({
            mutationFn: ({ policyId, year, month }) => monthlyReports.submit(accountId, policyId, year, month),
            ...config,
            onSuccess: (data, vars, ...args) => {
                config?.onSuccess?.(data, vars, ...args);
                queryClient.invalidateQueries({ queryKey: monthlyReports.queryKeys.find(accountId) });
            },
        });
    },

    async generateIndependentContractorReport(accountId: string) {
        const response = await blazar.fetch(`/waypoint/orgs/${accountId}/independent-contractor-report`, {
            method: "POST",
        });
        if (response.ok) {
            const blob = await response.blob();
            const a = document.createElement("a");
            a.style.display = "none";
            document.body.appendChild(a);
            a.href = URL.createObjectURL(blob);
            a.download = "Independent Contractor Report.xlsx";
            a.click();
            document.body.removeChild(a);
        } else {
            useSnackbar.add("Could not open report. Please try again later.", { variant: "error" });
        }
    },

    useGenerateIndependentContractorReport: (accountId: string) => {
        return useMutation<void, ResponseError>({
            mutationFn: () => monthlyReports.generateIndependentContractorReport(accountId),
        });
    },
};
