import { Transform } from "class-transformer";
import { IsArray, IsBoolean, IsEnum, IsInt, IsOptional, IsString } from "class-validator";
import { Account, Company, CoverageOption, Deal, Proposal, Submission, User } from ".";

interface DashboardTaskCounts {
    totalCount: number;
    overdueCount: number;
    newCount: number;
}

export type DashboardUserInfo = Pick<User, "guid" | "givenName" | "surname" | "displayName"> & { tasks?: DashboardTaskCounts };

type DashAccountInfo = Pick<Account, "id" | "assistantGuid"> & {
    submissions: Submission[];
    proposals: Proposal[];
    trailerCount: number;
    tractorCount: number;
    driverCount: number;
    company: Pick<Company, "name" | "dot" | "dba">;
};

export interface DashboardDealResult {
    deal: Deal;
    account: DashAccountInfo;
    dealAgent: DashboardUserInfo;
    dealAM: DashboardUserInfo;
    assistant?: DashboardUserInfo;
}

export interface LostDealItem extends DashboardDealResult {
    highlighted: boolean;
}

export type OpenDealItem = DashboardDealResult;
export type WonDealItem = DashboardDealResult;

export class DashboardDealsQuery {
    @IsString()
    guid: string;

    @IsInt()
    @IsString()
    month?: number;

    @IsOptional()
    @IsString()
    startDate?: string;

    @IsOptional()
    @IsString()
    endDate?: string;

    @IsOptional()
    @IsInt()
    @Transform(({ value }) => (value ? parseInt(value) : undefined))
    minRevenue?: number;

    @IsOptional()
    @IsInt()
    @Transform(({ value }) => (value ? parseInt(value) : undefined))
    maxRevenue?: number;

    @IsOptional()
    @IsString()
    type?: Deal["type"];

    @IsOptional()
    @IsArray()
    @IsString({ each: true })
    stageNames?: Deal["stageName"][];
}

export class DashboardLostDealsQuery {
    @IsString()
    guid: string;

    @IsInt()
    @IsString()
    month?: number;

    @IsOptional()
    @IsString()
    startDate?: string;

    @IsOptional()
    @IsString()
    endDate?: string;

    @IsOptional()
    @IsBoolean()
    onlyUndocumented?: boolean;

    @IsOptional()
    @IsString()
    lossReason?: string;

    @IsOptional()
    @IsInt()
    @Transform(({ value }) => (value ? parseInt(value) : undefined))
    minRevenue?: number;

    @IsOptional()
    @IsInt()
    @Transform(({ value }) => (value ? parseInt(value) : undefined))
    maxRevenue?: number;

    @IsOptional()
    @IsString()
    type?: Deal["type"];
}

export interface DashboardClientItem {
    account: Pick<Account, "id" | "agentGuid" | "rnManagerGuid" | "assistantGuid" | "company">;
}

export interface UpcomingRenewalItem {
    isHidden: boolean;
    account: {
        id: string;
        name: string;
        dba: string;
    };
    nextEffectiveDate: string;
    policies: {
        number: string;
        id: string;
        description: string;
        effectiveDate: Date;
        expirationDate: Date;
        accountManager: User;
        agent: User;
        writingCompany: string;
        parentCompany: string;
        isBelowThreshold: boolean;
        premium: number;
    }[];
    coverageOptionIds: CoverageOption["id"][];
}

export enum RenewalVisibilityFilter {
    Hidden = "hidden",
    Visible = "visible",
    All = "all",
}

export class RenewalFilterState {
    @IsEnum(RenewalVisibilityFilter)
    visibility: RenewalVisibilityFilter;

    @IsOptional()
    @Transform(({ value }) => (value ? (isNaN(parseInt(value)) ? value : parseInt(value)) : null))
    effective?: number | { after?: string; before?: string } | null;

    @IsOptional()
    @IsInt()
    coverageOptionId?: CoverageOption["id"] | null;

    @IsOptional()
    @IsString()
    accountManagerGuid?: User["guid"] | null;

    @IsOptional()
    @IsString()
    agentGuid?: User["guid"] | null;

    @IsOptional()
    @IsString()
    writingCompany?: string | null;

    @IsOptional()
    @IsString()
    parentCompany?: string | null;
}

type CustomerAppetiteUnit = "Focused" | "Not Focused" | "Unlikely";
type CustomerAppetiteMotorCarrier = "Clean" | "Fuzzy" | "Bad";

interface DashboardCustomerPerson {
    id: string;
    name: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: {
        main: string;
        mobile: string;
    };
    responsibilities: Array<
        | "Certificate Authority"
        | "Decision Maker"
        | "Dispatch"
        | "Family"
        | "Financial"
        | "Gone"
        | "Human Resources"
        | "Insurance Contact"
        | "Legal"
        | "Maintenance"
        | "Owner"
        | "Safety"
        | "Sales"
        | "Driver"
        | "Monthly Reporting"
        | "Inspection Analysis"
        | "Mass Email"
        | "IFTAs"
        | "Cert CC Receiver"
        | "ACH Confirmation"
        | "Claims"
        | "Ask Anything"
        | "Underwriter"
        | "Loss Control"
        | "Marketing"
        | "Auditor"
    >;
    title: string;
    description: string;
    type: "Insured" | "Customer" | "Competitor"
}
export interface DashboardCustomer {
    id: string;
    name: string;
    agent?: {
        givenName: string;
        surname: string;
        guid: string;
        upn: string;
    };
    phone: {
        main: string;
        billing: string;
        claims: string;
    };

    appointments?: {
        id: string;
        name: string;
    }[];

    address: {
        street: string;
        city: string;
        state: string;
        zip: string;
    };

    appetite: {
        motorCarrierQuality: CustomerAppetiteMotorCarrier;
        states: string[];
        units: {
            "1-3": CustomerAppetiteUnit;
            "4-9": CustomerAppetiteUnit;
            "10-25": CustomerAppetiteUnit;
            "26-50": CustomerAppetiteUnit;
            "51-75": CustomerAppetiteUnit;
            "76-100": CustomerAppetiteUnit;
            "100+": CustomerAppetiteUnit;
        };
        driverRetention: "0%" | "10%" | "20%" | "30%" | "40%" | "50%" | "60%" | "70%" | "80%" | "90%" | "100%";
        radius: { short: boolean; regional: boolean; long: boolean };
    };

    people: DashboardCustomerPerson[];
}

export class DashboardCustomerQuery {
    @IsOptional()
    @IsString()
    name?: string;

    @IsOptional()
    @IsArray()
    @IsString({ each: true })
    "appetiteUnits1-3"?: CustomerAppetiteUnit[];

    @IsOptional()
    @IsArray()
    @IsString({ each: true })
    "appetiteUnits4-9"?: CustomerAppetiteUnit[];

    @IsOptional()
    @IsArray()
    @IsString({ each: true })
    "appetiteUnits10-25"?: CustomerAppetiteUnit[];

    @IsOptional()
    @IsArray()
    @IsString({ each: true })
    "appetiteUnits26-50"?: CustomerAppetiteUnit[];

    @IsOptional()
    @IsArray()
    @IsString({ each: true })
    "appetiteUnits51-75"?: CustomerAppetiteUnit[];

    @IsOptional()
    @IsArray()
    @IsString({ each: true })
    "appetiteUnits76-100"?: CustomerAppetiteUnit[];

    @IsOptional()
    @IsArray()
    @IsString({ each: true })
    "appetiteUnits100+"?: CustomerAppetiteUnit[];

    @IsOptional()
    @IsArray()
    @IsString({ each: true })
    appetiteStates?: string[];

    @IsOptional()
    @IsArray()
    @IsString({ each: true })
    appetiteRadius?: string[];

    @IsOptional()
    @IsString()
    appetiteDriverRetention?: string;

    @IsOptional()
    @IsString()
    appetiteMotorCarrierQuality?: CustomerAppetiteMotorCarrier;
}
