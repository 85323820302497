import { AttributeOption, Coverage, CoverageOption, Policy } from "@deathstar/types/northstar";
import { Popover, useSnackbar } from "@deathstar/ui";
import {
    ArrowTopRightOnSquareIcon,
    BanknotesIcon,
    BuildingOffice2Icon,
    BuildingStorefrontIcon,
    CalendarDaysIcon,
    CurrencyDollarIcon,
    DocumentIcon,
    HashtagIcon,
    IdentificationIcon,
    ReceiptPercentIcon,
    ReceiptRefundIcon,
    Square2StackIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import { useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAccountId } from "../../../api/useAccountId";
import PolicyDocumentsList from "../policy-view/PolicyDocumentsList";
import PolicyViewDialog from "../policy-view/PolicyViewDialog";
import PolicyStatusLabel from "../PolicyStatusLabel";
import { getBrandedCoverageLink } from "./BrandedCoverageLinks";
import { PolicyWithBillingInfo } from "./PoliciesList";

function CoverageItem({ policy, coverage, isComposite }: { policy: PolicyWithBillingInfo; coverage: Coverage; isComposite: boolean }) {
    const [displayAllAttributes, setDisplayAllAttributes] = useState(false);
    const displayedAttributes = useMemo(() => {
        if (displayAllAttributes) {
            return coverage.attributes;
        }
        return coverage.attributes.slice(0, 3);
    }, [displayAllAttributes, coverage.attributes]);

    return (
        <li key={coverage.id} className="border-b first:border-t">
            <div className="flex items-center gap-4 px-2 py-1">
                <span className="text-navigator-primary">{coverage.coverageOption?.name}</span>
                <div className="flex grow flex-wrap items-center gap-2 text-xs">
                    {displayedAttributes?.map((attr) =>
                        getBrandedCoverageLink(attr.attributeOptionId, coverage.coverageOptionId) ? (
                            <a
                                key={attr.id}
                                href={getBrandedCoverageLink(attr.attributeOptionId, coverage.coverageOptionId)}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <span key={attr.id} className="rounded bg-black/10 px-2 text-blue-600">
                                    {attr.attributeOption?.name}
                                </span>
                            </a>
                        ) : (
                            <span key={attr.id} className="rounded bg-black/10 px-2">
                                {attr.attributeOptionId === AttributeOption.Id.DEDUCTIBLE_COMBINED_WITH_COVERAGE
                                    ? "Deductible combined"
                                    : attr.attributeOption?.name}
                            </span>
                        )
                    )}
                    {policy.additionalInterests?.some(
                        (ai) =>
                            ai.coveragesAppliedTo?.some((c) => (c as unknown as { coverageId: number }).coverageId === coverage.id) &&
                            ai.isBlanket
                    ) && <span className="rounded bg-emerald-700/20 px-2 text-emerald-800">Blanket AI</span>}
                    {coverage.attributes?.length > 3 && (
                        <button
                            className="cursor-pointer text-blue-600 underline"
                            onClick={() => {
                                setDisplayAllAttributes(!displayAllAttributes);
                            }}
                        >
                            {displayAllAttributes ? "Hide" : `+${coverage.attributes.length - 3}`}
                        </button>
                    )}
                </div>
                {isComposite ? <span className="text-sm text-stone-600">{getCoverageRateText(coverage)}</span> : null}
            </div>
        </li>
    );
}

export default function PoliciesListItem({ policy }: { policy: PolicyWithBillingInfo }) {
    const isComposite = Policy.isComposite(policy);
    return (
        <li className="w-full border-b-2 border-b-waypoint-orange/20 px-4 py-12 text-stone-600 md:pl-12">
            <div className="flex gap-4">
                <div className="flex grow flex-col gap-4">
                    <div className="flex flex-col lg:flex-row lg:gap-8">
                        <div>
                            <div className="flex items-center gap-2">
                                <p className="whitespace-nowrap tabular-nums">
                                    <CalendarDaysIcon className="-mt-1 mr-2 inline-block h-4 w-4 text-orange-400" />
                                    {moment.utc(policy.effectiveDate).format("MM/DD/YY")} -{" "}
                                    {moment.utc(policy.expirationDate).format("MM/DD/YY")}
                                </p>
                                <PolicyStatusLabel policy={policy} />
                            </div>
                            <p className="whitespace-nowrap">
                                <BuildingOffice2Icon className="-mt-1 mr-2 inline-block h-4 w-4 text-waypoint-orange" />
                                {policy.writingCompany?.name || "Unknown"}
                            </p>
                            <div className="flex items-center gap-2 whitespace-nowrap">
                                <p>
                                    <HashtagIcon className="-mt-1 mr-2 inline-block h-4 w-4 shrink-0 text-orange-400" />
                                    {policy.number}
                                </p>
                                <button
                                    onClick={() => {
                                        navigator.clipboard
                                            .writeText(policy.number)
                                            .then(() => {
                                                useSnackbar.add("Copied to clipboard", { variant: "success" });
                                            })
                                            .catch(() => {
                                                useSnackbar.add("Could not copy. Please try again.", {
                                                    variant: "warning",
                                                });
                                            });
                                    }}
                                    className="rounded-full p-1  hover:bg-stone-100"
                                >
                                    <Square2StackIcon className="h-4 w-4" />
                                </button>
                            </div>
                        </div>
                        <div>
                            <p className="whitespace-nowrap">
                                <BuildingStorefrontIcon className="-mt-1 mr-2 inline-block h-4 w-4 text-orange-400" />
                                {policy.firstNamedInsured}
                            </p>
                            <p className="whitespace-nowrap">
                                <CurrencyDollarIcon className="-mt-1 mr-2 inline-block h-4 w-4 text-orange-400" />
                                {policy.billMethod} billed
                            </p>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 border-y lg:hidden">
                        <PolicyActions policy={policy} />
                    </div>
                    <div className="space-y-4">
                        <div className="flex flex-wrap gap-2 text-sm">
                            {policy.attributes?.map((attr) => (
                                <span key={attr.id} className="rounded-lg bg-purple-50 px-2 text-purple-700">
                                    {attr.attributeOption?.name}
                                </span>
                            ))}
                            {!isComposite && <span className="rounded-lg bg-purple-50 px-2 text-purple-700">Scheduled</span>}
                        </div>
                        <ul className="space-y-1">
                            {policy.coverages?.map((coverage) => (
                                <CoverageItem key={coverage.id} policy={policy} coverage={coverage} isComposite={isComposite} />
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="hidden flex-col items-end justify-start gap-1 lg:flex">
                    <PolicyActions policy={policy} />
                </div>
            </div>
        </li>
    );
}

function getCoverageRateText(coverage: Coverage) {
    if (!coverage.rate) return "";
    let rate: string | undefined;
    let basis: string;
    const frequency = (
        {
            Monthly: "mo",
            Quarterly: "qrt",
            Yearly: "yr",
        } as Record<Coverage.Frequency, string>
    )[coverage.frequency];

    switch (coverage.basis) {
        case Coverage.Basis.MILEAGE:
            rate = coverage.rate?.toLocaleString("en-us", {
                currency: "USD",
                style: "currency",
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
            });
            basis = "per mile ";
            break;
        case Coverage.Basis.UNITS:
            rate = coverage.rate?.toLocaleString("en-us", {
                currency: "USD",
                style: "currency",
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
            });
            basis = "per unit ";
            break;
        case Coverage.Basis.REVENUE:
            rate = coverage.rate?.toLocaleString("en-us", {
                currency: "USD",
                style: "currency",
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
            });
            basis = "per $100 receipts ";
            break;
        case Coverage.Basis.VALUES:
            rate = coverage.rate
                ? (coverage.rate / 100).toLocaleString("en-us", {
                      style: "percent",
                      minimumFractionDigits: 2,
                  })
                : undefined;
            basis = "of equip. value ";
            break;
        case Coverage.Basis.FLAT:
            rate = coverage.rate?.toLocaleString("en-us", {
                currency: "USD",
                style: "currency",
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
            });
            basis = "";
            break;
        case Coverage.Basis.NONE:
            rate = coverage.rate?.toLocaleString("en-us", {
                currency: "USD",
                style: "currency",
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
            });
            basis = "";
            break;
        case Coverage.Basis.PAYROLL:
            rate = coverage.rate?.toLocaleString("en-us", {
                currency: "USD",
                style: "currency",
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
            });
            basis = "";
            break;
        default:
            rate = coverage.rate?.toLocaleString("en-us", {
                currency: "USD",
                style: "currency",
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
            });
            basis = "";
    }

    return `${rate} ${basis}/ ${frequency}`;
}

function PolicyActions({ policy }: { policy: PolicyWithBillingInfo }) {
    const [open, setOpen] = useState(false);

    const accountId = useAccountId();
    const navigate = useNavigate();

    const reportingLabels = Policy.isMonthlyReporter(policy)
        ? [
              policy.coverages.some(
                  (c) => c.basis === Coverage.Basis.MILEAGE || c.compositeRatings?.some((r) => r.basis === Coverage.Basis.MILEAGE)
              ) && "Mileage",
              policy.coverages.some(
                  (c) => c.basis === Coverage.Basis.REVENUE || c.compositeRatings?.some((r) => r.basis === Coverage.Basis.REVENUE)
              ) && "Revenue",
              (policy.coverages.some(
                  (c) => c.basis === Coverage.Basis.VALUES || c.compositeRatings?.some((r) => r.basis === Coverage.Basis.VALUES)
              ) ||
                  policy.coverages.some(
                      (c) => c.basis === Coverage.Basis.UNITS || c.compositeRatings?.some((r) => r.basis === Coverage.Basis.UNITS)
                  )) &&
                  "Equipment",
          ].filter(Boolean)
        : [];

    return (
        <>
            <button
                className="flex w-full items-center gap-1 rounded px-2 py-1 text-navigator-primary hover:bg-stone-100"
                onClick={() => setOpen(true)}
                title="Declarations"
            >
                <ArrowTopRightOnSquareIcon className="h-5 w-5 shrink-0" />
                Declarations
            </button>
            {policy.attributes.some((a) => a.attributeOptionId === AttributeOption.Id.CONTRACTOR_BENEFITS_ADMIN) && (
                <a
                    href="https://cba.focussi.com/"
                    className="flex w-full items-center gap-1 rounded px-2 py-1 text-navigator-primary hover:bg-stone-100"
                >
                    <ArrowTopRightOnSquareIcon className="h-5 w-5 shrink-0" />
                    Manage in CBA
                </a>
            )}
            <PolicyViewDialog policyId={policy.id} open={open} onClose={() => setOpen(false)} />
            <Popover className="w-full">
                <Popover.Content>
                    <PolicyDocumentsList policy={policy} documents={policy?.documents || []} />
                </Popover.Content>
                <Popover.Button className="flex w-full items-center gap-1 rounded px-2 py-1 text-navigator-primary hover:bg-stone-100">
                    <DocumentIcon className="h-5 w-5 shrink-0" />
                    <span>Documents</span>
                    <span>({policy?.documents?.length})</span>
                </Popover.Button>
            </Popover>

            {Policy.isComposite(policy) && (
                <Popover className="w-full">
                    <Popover.Button className="flex w-full items-center gap-1 rounded px-2 py-1 text-navigator-primary hover:bg-stone-100">
                        <ReceiptPercentIcon className="h-5 w-5 shrink-0" />
                        <span>Reporting</span>
                    </Popover.Button>
                    <Popover.Content>
                        {policy.coverages.some((c) => c.coverageOptionId === CoverageOption.Id.WORKERS_COMPENSATION) && (
                            <button
                                className="w-full whitespace-nowrap rounded px-4 py-2 text-left hover:bg-stone-100"
                                onClick={() => {
                                    navigate(`/sol/${accountId}/monthly-reports/${policy.id}`);
                                }}
                            >
                                Workers' Comp.
                            </button>
                        )}
                        {policy.coverages.some(
                            (c) =>
                                c.coverageOptionId === CoverageOption.Id.OCCUPATIONAL_ACCIDENT ||
                                c.coverageOptionId === CoverageOption.Id.CONTINGENT_LIABILITY
                        ) ? (
                            <button
                                className="w-full whitespace-nowrap rounded px-4 py-2 text-left hover:bg-stone-100"
                                onClick={() => {
                                    navigate(`/sol/${accountId}/monthly-reports/${policy.id}`);
                                }}
                            >
                                Independent contractors
                            </button>
                        ) : reportingLabels.length ? (
                            <button
                                className="w-full whitespace-nowrap rounded px-4 py-2 text-left hover:bg-stone-100"
                                onClick={() => {
                                    navigate(`/sol/${accountId}/monthly-reports/${policy.id}`);
                                }}
                            >
                                {reportingLabels.join(" & ")}
                            </button>
                        ) : null}
                    </Popover.Content>
                </Popover>
            )}

            {policy.billMethod === Policy.BillMethod.DIRECT ? (
                <Popover className="w-full">
                    <Popover.Button className="flex w-full items-center gap-1 rounded px-2 py-1 text-navigator-primary hover:bg-stone-100">
                        <BanknotesIcon className="h-5 w-5 shrink-0" />
                        <span>Billing</span>
                    </Popover.Button>
                    <Popover.Content className="w-max">
                        {policy.billingInfo ? (
                            <>
                                <p>{policy.billingInfo.companyName}</p>
                                {policy.billingInfo.details.map((info, i) => (
                                    <div key={i} className="ml-4">
                                        <p>{info.description}</p>

                                        {info.address && (
                                            <p>
                                                {info.address.line1}, {info.address.line2 ? `${info.address.line2}, ` : ""}
                                                {info.address.city}, {info.address.state} {info.address.postalCode}
                                            </p>
                                        )}
                                        {info.phone && (
                                            <a
                                                href={`tel:${info.phone}`}
                                                target="_blank"
                                                rel="noreferrer noopener"
                                                className="block text-waypoint-blue hover:text-waypoint-blue-dark"
                                            >
                                                {info.phone}
                                            </a>
                                        )}
                                        <a
                                            href={info.website}
                                            target="_blank"
                                            rel="noreferrer noopener"
                                            className="text-waypoint-blue hover:text-waypoint-blue-dark"
                                        >
                                            {info.website}
                                        </a>
                                    </div>
                                ))}
                            </>
                        ) : (
                            <>
                                <p>No billing information was found</p>
                                <p className="mt-4 text-sm text-stone-500">Please contact our office for assistance</p>
                            </>
                        )}
                    </Popover.Content>
                </Popover>
            ) : (
                <Link
                    to={`../../billing`}
                    className="flex w-full items-center gap-1 rounded px-2 py-1 text-navigator-primary hover:bg-stone-100"
                >
                    <BanknotesIcon className="h-5 w-5 shrink-0" />
                    <span>Billing</span>
                </Link>
            )}

            <Popover className="w-full">
                <Popover.Button className="flex w-full items-center gap-1 rounded px-2 py-1 text-navigator-primary hover:bg-stone-100">
                    <IdentificationIcon className="h-5 w-5 shrink-0" />
                    <span>Driver standards</span>
                </Popover.Button>
                <Popover.Content className="w-max">
                    {policy.driverStandardsUrl ? (
                        <a
                            href={policy.driverStandardsUrl}
                            target="_blank"
                            rel="noreferrer noopener"
                            className="text-waypoint-blue hover:text-waypoint-blue-dark"
                        >
                            <ArrowTopRightOnSquareIcon className="-mt-1 mr-1 inline h-4 w-4" />
                            {getDriverStandardsDocumentName(policy.driverStandardsUrl)}
                        </a>
                    ) : (
                        <>
                            <p>No driver standards information was found</p>
                            <p className="mt-4 text-sm text-stone-500">Please contact our office for assistance</p>
                        </>
                    )}
                </Popover.Content>
            </Popover>

            <Popover className="w-full">
                <Popover.Content className="w-max">
                    {policy.claimsInfo ? (
                        <>
                            <p>{policy.claimsInfo.companyName}</p>
                            <div className="ml-4">
                                {policy.claimsInfo.phone && (
                                    <a
                                        href={`tel:${policy.claimsInfo.phone}`}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        className="block text-waypoint-blue hover:text-waypoint-blue-dark"
                                    >
                                        P: {policy.claimsInfo.phone}
                                    </a>
                                )}
                                {policy.claimsInfo.fax && <p>F: {policy.claimsInfo.fax}</p>}
                                <a
                                    href={`mailto:${policy.claimsInfo.email}`}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className="block text-waypoint-blue hover:text-waypoint-blue-dark"
                                >
                                    {policy.claimsInfo.email}
                                </a>
                                <a
                                    href={policy.claimsInfo.website}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className="block text-waypoint-blue hover:text-waypoint-blue-dark"
                                >
                                    {policy.claimsInfo.website}
                                </a>
                            </div>
                        </>
                    ) : (
                        <>
                            <p>No claims information was found</p>
                            <p className="mt-4 text-sm text-stone-500">Please contact our office for assistance</p>
                        </>
                    )}
                </Popover.Content>

                <Popover.Button className="flex w-full items-center gap-1 rounded px-2 py-1 text-navigator-primary hover:bg-stone-100">
                    <ReceiptRefundIcon className="h-5 w-5 shrink-0" />
                    <span>Claims</span>
                </Popover.Button>
            </Popover>
        </>
    );
}

function getDriverStandardsDocumentName(url: string) {
    return url
        .split("/")
        .pop()
        ?.replace(/\.pdf$/, "");
}
