import { Driver } from "@deathstar/types/northstar";
import { DriverDto, DriverRow } from "@deathstar/types/waypoint";
import { QueryKey, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { queryClient } from "../../util/queryClient";
import { blazar } from "../util/blazar";
import { ResponseError } from "../util/exceptions";
import { monthlyReports } from "./monthlyReports";

export const drivers = {
    queryKeys: {
        find: (accountId: string) => [accountId, "drivers"] as QueryKey,
    },

    /**
     * Get the driver with the given license. This will not include any policy data, even if the driver is on a policy.
     */
    findByLicense: async (accountId: string, license: string) => {
        const found = await blazar.fetchJson<Partial<Driver>>(`/waypoint/orgs/${accountId}/drivers/${license}`, { method: "GET" });
        return found || null;
    },

    find: (accountId: string) => {
        return blazar.fetchJson<DriverRow[]>(`/waypoint/orgs/${accountId}/drivers`);
    },

    useFind: <T = DriverRow[]>(accountId: string, config?: Partial<UseQueryOptions<DriverRow[], ResponseError, T>>) =>
        useQuery<DriverRow[], ResponseError, T>({
            queryKey: drivers.queryKeys.find(accountId),
            queryFn: () => drivers.find(accountId),
            enabled: !!accountId,
            retry: (count, error) => {
                return count < 3 && error.status !== 403 && error.status !== 429;
            },
            ...config,
        }),

    create: async (accountId: string, body: DriverDto) => {
        try {
            window.trace.trackEvent("driver_added");
        } catch (e) {
            console.error(e);
        }
        return await blazar.fetchJson<void>(`/waypoint/orgs/${accountId}/drivers`, {
            method: "POST",
            body: JSON.stringify(body),
        });
    },

    useCreate: (accountId: string, config?: UseMutationOptions<void, ResponseError, DriverDto>) => {
        return useMutation<void, ResponseError, DriverDto>({
            mutationFn: (data) => {
                return drivers.create(accountId, data);
            },
            ...config,
            onSuccess: (...args) => {
                queryClient.invalidateQueries({ queryKey: drivers.queryKeys.find(accountId) });
                queryClient.invalidateQueries({ queryKey: monthlyReports.queryKeys.find(accountId) });
                config?.onSuccess?.(...args);
            },
        });
    },

    update: async (
        accountId: string,
        id: number,
        changes: Partial<Pick<DriverDto, "tractors" | "trailers" | "employer" | "atDate" | "coverages">>
    ) => {
        try {
            window.trace.trackEvent("driver_updated", changes);
        } catch (e) {
            console.error(e);
        }
        return await blazar.fetchJson<void>(`/waypoint/orgs/${accountId}/drivers/${id}`, {
            method: "PATCH",
            body: JSON.stringify(changes),
        });
    },

    useUpdate: (
        accountId: string,
        config?: UseMutationOptions<
            void,
            ResponseError,
            { id: number } & Partial<Pick<DriverDto, "tractors" | "trailers" | "employer" | "atDate" | "coverages">>
        >
    ) => {
        return useMutation({
            mutationFn: ({ id, ...changes }) => {
                return drivers.update(accountId, id, changes);
            },
            ...config,
            onSuccess: (...args) => {
                queryClient.invalidateQueries({ queryKey: drivers.queryKeys.find(accountId) });
                queryClient.invalidateQueries({ queryKey: monthlyReports.queryKeys.find(accountId) });
                config?.onSuccess?.(...args);
            },
        });
    },
};
