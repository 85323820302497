import { MotorCarrier } from "@deathstar/motor-carrier";
import { createContext } from "react";
import { IConfig } from "./Config";

export interface IContext {
    activeTab: number;
    setActiveTab: (tab: number) => void;
    motorCarrier: MotorCarrier;
    config: IConfig;
}

export const Context = createContext<IContext>({
    activeTab: NaN,
    setActiveTab: null!,
    motorCarrier: null!,
    config: {
        insuranceHistory: {
            hide: false,
        },
        iftas: {
            hide: false,
        },
    },
});
