import { Company, PaymentIntentCreateParams, Property } from "@deathstar/types/northstar";
import { BillingInformation } from "@deathstar/types/waypoint";
import { UseMutationResult } from "@tanstack/react-query";
import React from "react";
import Stripe from "stripe";

export interface IContext {
    company?: Company;
    properties?: Property[];
    amsClientNumber?: number;
    viewSpecialPaymentTerms: boolean;
    enableActionMenu: boolean;
    enablePayNow: boolean;
    billingInfo?: BillingInformation;
    isLoadingBillingInfo?: boolean;
    createPayment?: UseMutationResult<Stripe.PaymentIntent, Error, PaymentIntentCreateParams, unknown>;
}

export const Context = React.createContext<IContext>({
    viewSpecialPaymentTerms: false,
    enableActionMenu: false,
    enablePayNow: false,
    billingInfo: undefined,
    isLoadingBillingInfo: false,
    createPayment: undefined,
    company: undefined,
    properties: [],
    amsClientNumber: undefined,
});
