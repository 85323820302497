import { AttributeOption, Coverage, CoverageOption, CoverageUnderlyingCoverage, Policy } from "@deathstar/types/northstar";
import { EquipmentDriverChangeDto } from "@deathstar/types/waypoint";
import { Checkbox } from "@deathstar/ui";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import api from "../../api/api";
import { EquipmentTypeString } from "../../api/queries/equipment";
import { useAccountId } from "../../api/useAccountId";
import { PolicyWithBillingInfo } from "../policies/policies-list/PoliciesList";

const COMPANY_DRIVER_COVERAGES = [CoverageOption.Id.TRUCKERS_AUTO_LIABILITY];
const OWNER_OPERATOR_COVERAGES = [
    CoverageOption.Id.TRUCKERS_AUTO_LIABILITY,
    CoverageOption.Id.OCCUPATIONAL_ACCIDENT,
    CoverageOption.Id.CONTINGENT_LIABILITY,
    CoverageOption.Id.NON_TRUCKING_LIABILITY,
];

function getAvailableCoverages(dataType: EquipmentTypeString | "drivers", ownerOperator: boolean, coverages: Coverage[]): Coverage[] {
    switch (dataType) {
        case "drivers":
            return coverages.filter((coverage) => {
                if (!ownerOperator) {
                    return (
                        coverage.coverageOptionId !== CoverageOption.Id.OCCUPATIONAL_ACCIDENT &&
                        coverage.coverageOptionId !== CoverageOption.Id.CONTINGENT_LIABILITY &&
                        coverage.coverageOptionId !== CoverageOption.Id.NON_TRUCKING_LIABILITY
                    );
                }
                return coverages;
            });
        case "tractors":
        case "trailers":
            return coverages.filter((coverage) => {
                if (!ownerOperator) {
                    return !coverage.policy!.attributes.some(
                        (a) =>
                            a.attributeOptionId === AttributeOption.Id.OWNER_OPERATOR_WAYPOINT_PROGRAM ||
                            a.attributeOptionId === AttributeOption.Id.CONTRACTOR_BENEFITS_ADMIN
                    );
                }
                return true;
            });
        case "autos":
            return coverages;
        case "tools":
            return coverages;
    }
}

const TRACTOR_COVERAGES = [
    CoverageOption.Id.TRUCKERS_AUTO_LIABILITY,
    CoverageOption.Id.TRUCKERS_PHYSICAL_DAMAGE,
    CoverageOption.Id.MOTOR_TRUCK_CARGO,
    CoverageOption.Id.EXCESS_CARGO,
];
const TRAILER_COVERAGES = [CoverageOption.Id.TRUCKERS_AUTO_LIABILITY, CoverageOption.Id.TRUCKERS_PHYSICAL_DAMAGE];
const BUSINESS_AUTO_COVERAGES = [CoverageOption.Id.BUSINESS_AUTO_LIABILITY];
const TOOLS_COVERAGES = [CoverageOption.Id.INLAND_MARINE];

export default function CoverageSelector({
    dataType,
    isEditing,
    coverageFilter,
}: {
    dataType: EquipmentTypeString | "drivers";
    isEditing?: boolean;
    coverageFilter?: (coverage: Coverage) => boolean;
}) {
    const form = useFormContext<{ coverages: EquipmentDriverChangeDto["coverages"]; ownerOperator: boolean }>();
    const accountId = useAccountId();
    const { data } = api.policies.useFind<(Coverage & { policy: Pick<Policy, "writingCompany"> })[]>(accountId!, "active", {
        select: (policies) => {
            return policies
                .flatMap((policy) =>
                    policy.coverages
                        .filter((coverage) => {
                            if (coverageFilter && !coverageFilter({ ...coverage, policyId: policy.id } as Coverage)) {
                                return false;
                            }

                            if (
                                coverage.underlyingCoverages?.some(
                                    (uc) => uc.description === CoverageUnderlyingCoverage.Description.GENERAL_LIABILITY
                                )
                            ) {
                                // do not allow adding units/drivers to XSGL
                                return false;
                            }

                            switch (dataType) {
                                case "tractors":
                                    return CoverageOption.getAvailableAttachments(coverage.coverageOptionId).tractors;
                                case "trailers":
                                    return CoverageOption.getAvailableAttachments(coverage.coverageOptionId).trailers;
                                case "autos":
                                    return CoverageOption.getAvailableAttachments(coverage.coverageOptionId).businessAutos;
                                case "tools":
                                    return CoverageOption.getAvailableAttachments(coverage.coverageOptionId).tools;
                                case "drivers":
                                    return CoverageOption.getAvailableAttachments(coverage.coverageOptionId).drivers;
                                default:
                                    return false;
                            }
                        })
                        .map(
                            (coverage) =>
                                ({ ...coverage, policyId: policy.id, policy } as Coverage & {
                                    policy: PolicyWithBillingInfo;
                                })
                        )
                )
                .sort((a, b) => moment(a.policy.effectiveDate).unix() - moment(b.policy.effectiveDate).unix());
        },
    });

    useEffect(() => {
        if (data && !isEditing && !form.formState.touchedFields.coverages) {
            if (dataType === "drivers") {
                if (form.getValues("ownerOperator")) {
                    form.setValue(
                        "coverages",
                        data
                            .filter((c) => OWNER_OPERATOR_COVERAGES.includes(c.coverageOptionId))
                            .map((c) => ({ policyId: c.policyId, coverageOptionId: c.coverageOptionId })),
                        { shouldTouch: false }
                    );
                } else {
                    form.setValue(
                        "coverages",
                        data
                            .filter((c) => COMPANY_DRIVER_COVERAGES.includes(c.coverageOptionId))
                            .map((c) => ({ policyId: c.policyId, coverageOptionId: c.coverageOptionId })),
                        { shouldTouch: false }
                    );
                }
            } else if (dataType === "tractors") {
                form.setValue(
                    "coverages",
                    data
                        .filter((c) => TRACTOR_COVERAGES.includes(c.coverageOptionId))
                        .map((c) => ({ policyId: c.policyId, coverageOptionId: c.coverageOptionId })),
                    { shouldTouch: false }
                );
            } else if (dataType === "trailers") {
                form.setValue(
                    "coverages",
                    data
                        .filter((c) => TRAILER_COVERAGES.includes(c.coverageOptionId))
                        .map((c) => ({ policyId: c.policyId, coverageOptionId: c.coverageOptionId })),
                    { shouldTouch: false }
                );
            } else if (dataType === "autos") {
                form.setValue(
                    "coverages",
                    data
                        .filter((c) => BUSINESS_AUTO_COVERAGES.includes(c.coverageOptionId))
                        .map((c) => ({ policyId: c.policyId, coverageOptionId: c.coverageOptionId })),
                    { shouldTouch: false }
                );
            } else if (dataType === "tools") {
                form.setValue(
                    "coverages",
                    data
                        .filter((c) => TOOLS_COVERAGES.includes(c.coverageOptionId))
                        .map((c) => ({ policyId: c.policyId, coverageOptionId: c.coverageOptionId })),
                    { shouldTouch: false }
                );
            }
        }
        // using `form` in the deps here causes an infinite rerender loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, dataType, form.setValue, form.getValues, form.formState.touchedFields.coverages, isEditing]);
    const isOwnerOperator = form.watch("ownerOperator");
    const availableCoverages = useMemo(
        () => getAvailableCoverages(dataType, isOwnerOperator, data || []),
        [data, dataType, isOwnerOperator]
    );
    const coverages = form.watch("coverages");
    useEffect(() => {
        const coverages = form.getValues("coverages");
        if (
            data &&
            !coverages.every((c) =>
                availableCoverages.some((ac) => ac.policyId === c.policyId && ac.coverageOptionId === c.coverageOptionId)
            )
        ) {
            // remove selected coverages that are no longer available
            form.setValue(
                "coverages",
                coverages.filter((c) =>
                    availableCoverages.some((ac) => ac.policyId === c.policyId && ac.coverageOptionId === c.coverageOptionId)
                ),
                { shouldTouch: true, shouldDirty: true }
            );
        }
    }, [availableCoverages, data, form]);

    form.register("coverages", { required: isEditing || "Please select at least one coverage" });

    return (
        <div className="ml-4 w-full space-y-2">
            {data ? (
                data.length ? (
                    data.map((coverage) => (
                        <Checkbox
                            classes={{ input: "w-4 h-4", label: "peer-disabled:opacity-50" }}
                            key={coverage.id}
                            checked={coverages.some(
                                (c) => c.policyId === coverage.policyId && c.coverageOptionId === coverage.coverageOptionId && !c.remove
                            )}
                            disabled={!availableCoverages.some((c) => c.id === coverage.id)}
                            onChange={() => {
                                if (
                                    coverages.some(
                                        (c) => c.policyId === coverage.policyId && c.coverageOptionId === coverage.coverageOptionId
                                    )
                                ) {
                                    if (isEditing) {
                                        form.setValue(
                                            "coverages",
                                            coverages.map((c) =>
                                                c.policyId === coverage.policyId && c.coverageOptionId === coverage.coverageOptionId
                                                    ? { ...c, remove: !c.remove }
                                                    : c
                                            ),
                                            { shouldTouch: true, shouldDirty: true }
                                        );
                                    } else {
                                        form.setValue(
                                            "coverages",
                                            coverages.filter(
                                                (c) =>
                                                    !(c.policyId === coverage.policyId && c.coverageOptionId === coverage.coverageOptionId)
                                            ),
                                            {
                                                shouldTouch: true,
                                                shouldDirty: true,
                                            }
                                        );
                                    }
                                } else {
                                    form.setValue(
                                        "coverages",
                                        [...coverages, { policyId: coverage.policyId, coverageOptionId: coverage.coverageOptionId }],
                                        {
                                            shouldTouch: true,
                                            shouldDirty: true,
                                        }
                                    );
                                }
                            }}
                            label={
                                <div>
                                    <p className="">
                                        {CoverageOption.getMetadata(coverage.coverageOptionId).name}
                                        {coverage.policy.attributes.some(
                                            (att) =>
                                                att.attributeOptionId === AttributeOption.Id.CONTRACTOR_BENEFITS_ADMIN ||
                                                att.attributeOptionId === AttributeOption.Id.OWNER_OPERATOR_WAYPOINT_PROGRAM
                                        ) && " (IC)"}
                                    </p>
                                    <p className="text-xs text-stone-500">
                                        {coverage.policy!.writingCompany?.name} • {moment(coverage.policy!.effectiveDate).format("M/D/YY")}
                                    </p>
                                </div>
                            }
                        />
                    ))
                ) : (
                    <p className="mt-4 text-stone-500">No available coverages</p>
                )
            ) : (
                <div className="flex items-center justify-center">
                    <CircularProgress size="2rem" />
                </div>
            )}
        </div>
    );
}
