import { Account, AccountEquipmentStatus, PendingChange, PolicyAdditionalInterest, Property, User } from ".";

export class InlandMarineEquipment {
    id: number;
    isDeleted: boolean;

    submit: boolean;

    accountId: string;
    account: Account;

    createdByGuid: string;
    createdBy: User;

    createDate: Date;
    updateDate: Date;

    year: number;

    make: string;

    model: string;

    description: string;

    serialNumber: string;

    value: number;
    acv: boolean;

    createdByWaypointUserId: string;

    propertyId: number;

    property: Property;

    additionalInterests?: PolicyAdditionalInterest[];

    policyUpcomingDate?: string | null | undefined;
    policyValue?: number | null | undefined;
    policyAcv?: boolean | null | undefined;
    statuses?: AccountEquipmentStatus[];
    coverages?: { policyId: string; coverageOptionId: number; effectiveDate: string; isUpcoming: boolean }[];
    pendingChanges?: PendingChange[];

    toString(): string {
        const _parts: (number | string)[] = [];
        if (this.year) _parts.push(this.year);
        if (this.make) _parts.push(this.make);
        if (this.model) _parts.push(this.model);
        if (this.description) _parts.push(this.description);
        if (this.serialNumber) _parts.push(this.serialNumber);

        return _parts.join(" ");
    }
}
