import { User as WpUser } from "../waypoint/User";
import { Coverage } from "./Coverage";
import { CoverageOption } from "./CoverageOption";
import { Driver } from "./Driver";
import { GlobalCertificateHolder } from "./GlobalCertificateHolder";
import { Policy } from "./Policy";
import { PolicyAdditionalInterest } from "./PolicyAdditionalInterest";
import { EquipmentComparison, PolicyLayer } from "./PolicyLayer";
import { Tractor } from "./Tractor";
import { Trailer } from "./Trailer";
import { User as NsUser } from "./User";

export class MonthlyReportPremiumModification {
    policyId: string;
    year: number;
    month: number;
    coverageOptionId: CoverageOption.Id;
    description: string;
    total: number;
}

export class MonthlyReport {
    policyId: string;
    year: number;
    month: number;
    createdDate: Date;
    createdByNorthstarUserGuid: string;
    createdByWaypointUserId: string;
    finalizedDate: Date;
    finalizedByNorthstarUserGuid: string;
    mileage: number;
    revenue: number;
    isFinalized: boolean;
    createdByNorthstarUser: NsUser;
    createdByWaypointUser: WpUser;
    finalizedByNorthstarUser: NsUser;
    submittedDate: Date;
    submittedByWaypointUserId: string;
    isSubmitted: boolean;

    premiumModifications: MonthlyReportPremiumModification[];
    payroll: MonthlyReportPayroll[];

    totalPremiumInCents: number;
}

export class MonthlyReportQueryResult {
    report: MonthlyReport;
    equipment: {
        tractors: Tractor[];
        trailers: Trailer[];
        drivers: Driver[];
    };
    layers: PolicyLayer[];
    totalMileage: number;
    totalRevenue: number;
}

export enum MonthlyReportStatus {
    Overdue,
    Due,
    Submitted,
    Finalized,
    Upcoming,
    NotRequired,
}

export interface MonthlyReportAvailableMonth {
    month: number;
    year: number;
    status: MonthlyReportStatus;
}

export interface MonthlyReportPolicyData {
    policyId: string;
    policyStatus: Policy["status"];
    covereageOptionIds: CoverageOption.Id[];
    writingCompanyName: string;
    policyNumber: string;
    effectiveDate: string;
    expirationDate: string;
    availableMonths: MonthlyReportAvailableMonth[];
    hasReportDue: boolean;
    reports: MonthlyReport[];
    isAdvancedReporter: boolean;
}

export type MonthlyReportDataUnit<T extends Tractor | Trailer | Driver> = T & {
    isDriver: T extends Driver ? true : false;
    isTractor: T extends Tractor ? true : false;
    isTrailer: T extends Trailer ? true : false;
    changes: Change[];
    isNewToPolicy: boolean;
    dateAddedToPolicy: Date | null;
    isRemovedFromPolicy: boolean;
    dateRemovedFromPolicy: Date | null;
    isNewToCoverage: Map<CoverageOption.Id, boolean>;
    isRemovedFromCoverage: Map<CoverageOption.Id, boolean>;
    isACV: T extends Tractor | Trailer ? boolean : never;
    statedValue: T extends Tractor | Trailer ? number | null : never;
    experience: T extends Driver ? number : never;
    isSpare: T extends Tractor | Trailer ? boolean : never;
    hasUniqueDeductible: T extends Tractor | Trailer ? boolean : never;
    isOwnerOperator: T extends Driver ? boolean : never;
    employer: T extends Driver ? GlobalCertificateHolder | null : never;
    employerId: T extends Driver ? number | null : never;
    additionalInterests: PolicyAdditionalInterest[];
};

export interface MonthlyReportCoveragePremium {
    minimumPremium: number;
    minimumPremiumApplied: number;
    proratedPremiumApplied: number;
    subtotalPremium: number;
    totalPremium: number;
    rates: {
        description: string;
        basis: Coverage["basis"];
        rate: number;
        factor: number;
        total: number;
        prorated: number;
        isModification: boolean;
    }[];
}

export interface MonthlyReportData {
    month: number;
    year: number;
    startLayer: PolicyLayer;
    endLayer: PolicyLayer;

    /** Premium calculated from the composite rates of the coverages */
    subtotalPremium: number;

    /** `subtotalPremium` + any minimium monthly premiums */
    totalPremium: number;

    premiumByCoverage: Map<CoverageOption.Id, MonthlyReportCoveragePremium>;

    /** The whole-policy's minimum premium */
    minimumPremium: number;

    /** The amount of premium, deducted from the total premium due, that covers any proration due to the month including days in which the policy was not active */
    proratedPremiumApplied: number;

    /** The amount of premium, added to the total premium due, which covers any minimum monthly premiums (which could be from either a specific coverage or the policy as a whole) */
    minimumPremiumApplied: number;

    isUnitReporter: boolean;
    isMileageReporter: boolean;
    isRevenueReporter: boolean;
    isPayrollReporter: boolean;
    isAdvancedReporter: boolean;
    isDriverReporter: boolean;

    equipment: {
        tractors: MonthlyReportDataUnit<Tractor>[];
        trailers: MonthlyReportDataUnit<Trailer>[];
        drivers: MonthlyReportDataUnit<Driver>[];

        activeTractors: MonthlyReportDataUnit<Tractor>[];
        activeTrailers: MonthlyReportDataUnit<Trailer>[];
        activeDrivers: MonthlyReportDataUnit<Driver>[];
    };
}

interface Change {
    date: Date;
    unitId: number;
    changesByCoverage: Map<CoverageOption.Id, EquipmentComparison.IChangeMapValue<EquipmentComparison.EquipmentType>>;
    isNewToPolicy: boolean;
    isRemovedFromPolicy: boolean;
}

export interface MonthlyReportChanges {
    tractors: Change[];
    trailers: Change[];
    drivers: Change[];
}

export interface MonthlyReportPayroll {
    policyId: string;
    year: number;
    month: number;
    coverageOptionId: CoverageOption.Id;
    description: string;
    amount: number;
}
