import { Coverage, CoverageOption, MonthlyReportData } from "@deathstar/types/northstar";
import { classNames, Collapse } from "@deathstar/ui";
import { ChevronRightIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "@material-ui/core";
import { Fragment, useState } from "react";

export function MonthlyReportCalculations({ data }: { data: MonthlyReportData }) {
    const [expanded, setExpanded] = useState<Set<CoverageOption.Id>>(new Set());

    return (
        <table className="cols-spacing-2 w-full text-sm row-p-2 row-spacing-1 lg:col-spacing-6">
            <thead>
                <tr className="text-xs text-stone-600 small-caps">
                    <th className="text-left font-normal">Coverage</th>
                    <th className="text-right font-normal">Est. total</th>
                </tr>
            </thead>
            <tbody>
                {Array.from(data.premiumByCoverage.entries()).map(([coverageOptionId, { subtotalPremium, rates }], i) => (
                    <Fragment key={coverageOptionId}>
                        <tr
                            className={classNames(rates.length > 0 && "cursor-pointer", i % 2 === 0 && "bg-stone-50")}
                            onClick={() => {
                                if (rates.length > 0) {
                                    setExpanded((old) => {
                                        const newExpanded = new Set(old);
                                        if (old.has(coverageOptionId)) {
                                            newExpanded.delete(coverageOptionId);
                                        } else {
                                            newExpanded.add(coverageOptionId);
                                        }
                                        return newExpanded;
                                    });
                                }
                            }}
                        >
                            <td className="text-left">
                                <ChevronRightIcon
                                    className={classNames(
                                        "mr-2 hidden h-4 w-4 transition-transform md:inline",
                                        expanded.has(coverageOptionId) && "rotate-90",
                                        rates.length === 0 && "opacity-0"
                                    )}
                                />
                                {CoverageOption.getMetadata(coverageOptionId).name}
                            </td>
                            <td className="text-right tabular-nums">
                                {subtotalPremium.toLocaleString("en-us", { style: "currency", currency: "USD" })}
                            </td>
                        </tr>
                        {rates.length > 0 && (
                            <tr className="hidden md:table-row">
                                <td colSpan={2} className="!py-0 !pl-0 !pr-0">
                                    <Collapse isOpen={expanded.has(coverageOptionId)} containerProps={{ className: "w-full" }}>
                                        <table className="w-full border-l bg-blue-50/50 text-xs font-light text-stone-600 !col-spacing-4 row-p-2 row-spacing-1 lg:col-spacing-6">
                                            <thead>
                                                <tr className="text-xs text-stone-600 small-caps">
                                                    <th className="text-left font-normal"></th>
                                                    <th className="text-left font-normal">Basis</th>
                                                    <th className="text-right font-normal">Value</th>
                                                    <th className="text-right font-normal">Rate</th>
                                                    <th className="text-right font-normal"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {rates.map((rate) => (
                                                    <tr key={`${coverageOptionId}-${rate.basis}-${rate.description}`}>
                                                        <td className="text-left">
                                                            <span className="text-stone-500">
                                                                {rate.description || CoverageOption.getMetadata(coverageOptionId).name}
                                                            </span>
                                                        </td>
                                                        <td className="text-left">{rate.basis}</td>
                                                        <td className="text-right tabular-nums">
                                                            {rate.factor.toLocaleString(
                                                                "en-us",
                                                                rate.basis === Coverage.Basis.REVENUE ||
                                                                    rate.basis === Coverage.Basis.VALUES ||
                                                                    rate.basis === Coverage.Basis.OTHER
                                                                    ? {
                                                                          style: "currency",
                                                                          currency: "USD",
                                                                          maximumFractionDigits: 0,
                                                                      }
                                                                    : {}
                                                            )}
                                                        </td>
                                                        <td className="text-right tabular-nums">
                                                            {(rate.basis === Coverage.Basis.VALUES || rate.basis === Coverage.Basis.REVENUE
                                                                ? rate.rate * 100
                                                                : rate.rate
                                                            ).toLocaleString("en-us", {
                                                                maximumFractionDigits: 12,
                                                            })}{" "}
                                                            <span className="text-stone-400">
                                                                {rate.basis === Coverage.Basis.MILEAGE
                                                                    ? "per mile"
                                                                    : rate.basis === Coverage.Basis.REVENUE ||
                                                                      rate.basis === Coverage.Basis.VALUES
                                                                    ? "per $100"
                                                                    : rate.basis === Coverage.Basis.UNITS
                                                                    ? `per ${
                                                                          coverageOptionId === CoverageOption.Id.OCCUPATIONAL_ACCIDENT ||
                                                                          coverageOptionId === CoverageOption.Id.CONTINGENT_LIABILITY
                                                                              ? "driver"
                                                                              : "unit"
                                                                      }`
                                                                    : null}
                                                            </span>
                                                        </td>
                                                        <td className="text-right tabular-nums">
                                                            {rate.total.toLocaleString("en-us", {
                                                                style: "currency",
                                                                currency: "USD",
                                                            })}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </Collapse>
                                </td>
                            </tr>
                        )}
                    </Fragment>
                ))}
            </tbody>
            <tfoot className="border-t">
                <tr className="text-stone-500">
                    <td className="text-right tabular-nums" colSpan={2}>
                        <div className="flex justify-end gap-4">
                            <p>Subtotal</p>
                            <div className="w-36">
                                {data.subtotalPremium.toLocaleString("en-us", { style: "currency", currency: "USD" })}
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className="text-right tabular-nums" colSpan={2}>
                        <div className="flex justify-end gap-4 text-lg">
                            <p>Total due</p>
                            <div className="w-36">{data.totalPremium.toLocaleString("en-us", { style: "currency", currency: "USD" })}</div>
                        </div>
                        {data.minimumPremiumApplied > 0 && (
                            <div className="flex justify-end">
                                <Tooltip
                                    title={
                                        <div className="font-normal">
                                            <p>This policy has the following minimum premiums:</p>
                                            {data.minimumPremium > 0 && (
                                                <p>
                                                    <span className="font-medium">Policy minimum premium:</span>{" "}
                                                    {data.minimumPremium.toLocaleString("en-us", {
                                                        style: "currency",
                                                        currency: "USD",
                                                        maximumFractionDigits: 2,
                                                    })}
                                                </p>
                                            )}
                                            {Array.from(data.premiumByCoverage.entries())
                                                .filter(([_, c]) => c.minimumPremium > 0)
                                                .map(([id, c]) => (
                                                    <p key={id}>
                                                        <span className="font-medium">{CoverageOption.getMetadata(id).name}:</span>{" "}
                                                        {c.minimumPremium.toLocaleString("en-us", {
                                                            style: "currency",
                                                            currency: "USD",
                                                            maximumFractionDigits: 2,
                                                        })}
                                                    </p>
                                                ))}
                                        </div>
                                    }
                                >
                                    <div className="flex items-center gap-2 text-xs text-stone-500">
                                        <p>Minimum premium applied</p>
                                        <InformationCircleIcon className="h-4 w-4" />
                                    </div>
                                </Tooltip>
                            </div>
                        )}
                        {data.proratedPremiumApplied > 0 && (
                            <div className="flex justify-end">
                                <Tooltip
                                    title={
                                        <div className="font-normal">
                                            <p>This month's premium has been prorated</p>
                                            <p>
                                                <span className="font-medium">Deducted amount:</span>{" "}
                                                {data.proratedPremiumApplied.toLocaleString("en-us", {
                                                    style: "currency",
                                                    currency: "USD",
                                                    maximumFractionDigits: 2,
                                                })}
                                            </p>
                                        </div>
                                    }
                                >
                                    <div className="flex items-center gap-2 text-xs text-stone-500">
                                        <p>Proration applied</p>
                                        <InformationCircleIcon className="h-4 w-4" />
                                    </div>
                                </Tooltip>
                            </div>
                        )}
                    </td>
                </tr>
            </tfoot>
        </table>
    );
}
