import { OOProgramMember } from "../waypoint";
import type { Account } from "./Account";
import { CoverageLinkedDriver } from "./CoverageLinkedDriver";
import { GlobalCertificateHolder } from "./GlobalCertificateHolder";
import { PendingChange } from "./PendingChange";
import { AccountEquipmentStatus, Tractor } from "./Tractor";
import { Trailer } from "./Trailer";

export class Driver {
    id: number;

    createDate: Date;

    updateDate: Date;

    submit: boolean;

    isDeleted: boolean;

    accountId: string;

    name: string | null;

    state: string | null;

    license: string | null;

    dob: string | null;

    exp: number | null;

    doh: string | null;

    ownerOperator: boolean;

    isOwner: boolean;

    comments: string | null;

    createdByGuid: string;
    createdByWaypointUserId: string;
    wpUserConfirmationMeetsGuidelines: boolean;

    account?: Account;

    coverageLinks?: CoverageLinkedDriver[];
    tractors?: Tractor[];
    trailers?: Trailer[];

    policyUpcomingDate?: string | null | undefined;
    policyDoh?: string | null | undefined;
    policyOwnerOperator?: boolean | null | undefined;
    policyExp?: number | null | undefined;
    statuses?: AccountEquipmentStatus[];
    coverages?: { policyId: string; coverageOptionId: number; effectiveDate: string; isUpcoming: boolean }[];

    // global certificate holder id, used for OOs and ICs to create certificates where their employers are certificate holders
    employerId?: number;
    employer?: GlobalCertificateHolder;

    ownerOperatorMembership?: OOProgramMember;

    pendingChanges?: PendingChange[];

    toString(): string {
        const { name, dob, license } = this;
        const _parts: (number | string)[] = [];
        if (name) _parts.push(name);
        if (dob) _parts.push(dob);
        if (license) _parts.push(license);

        return _parts.join(" ");
    }
}
