import { BusinessAuto, CoverageOption, InlandMarineEquipment as Tool, Tractor, Trailer } from "@deathstar/types/northstar";
import { DriverRow, EquipmentRow } from "@deathstar/types/waypoint";
import { Popover, PrimaryCell, useSnackbar } from "@deathstar/ui";
import {
    ArrowPathIcon,
    BackspaceIcon,
    BuildingLibraryIcon,
    CheckIcon,
    DocumentTextIcon,
    EllipsisVerticalIcon,
    PencilSquareIcon,
    ShieldCheckIcon,
} from "@heroicons/react/24/outline";
import { Tooltip } from "@material-ui/core";
import { AccessorColumnDef, CellContext, DisplayColumnDef } from "@tanstack/react-table";
import moment from "moment";
import { useState } from "react";
import api from "../../api/api";
import { EquipmentTypeString } from "../../api/queries/equipment";
import { NewUnitDialog } from "./NewUnitDialog";
import RemoveUnitDialog from "./RemoveUnitDialog";

export interface EquipmentTableMeta {
    openDriverLinker(unitId: number): void;
}

function createMobileCell(type: "tractors" | "trailers" | "autos") {
    return function MobileCell(info: CellContext<EquipmentRow<Tractor | Trailer | BusinessAuto>, unknown>) {
        const row = info.row.original;

        const [changeDialogOpen, setChangeDialogOpen] = useState(false);
        const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

        const attributes = [];
        if ((row as EquipmentRow<Tractor>).policySpare) {
            attributes.push("Spare");
        }
        if (row.policyOwnerOperator) {
            attributes.push("IC Owned");
        }

        return (
            <>
                <Popover>
                    <Popover.Content>
                        <AutoIdButton type={type} row={info.row.original} />
                        <Popover.Item
                            onClick={() => {
                                setChangeDialogOpen(true);
                            }}
                            disabled={!!info.row.original.pendingChanges?.length}
                        >
                            <PencilSquareIcon className="h-5 w-5" />
                            <span>Update{info.row.original.pendingChanges?.length ? " (pending)" : ""}</span>
                        </Popover.Item>
                    </Popover.Content>
                    <Popover.Button className="w-full space-y-1 text-left text-stone-600">
                        <div className="flex justify-between gap-2">
                            <p className="line-clamp-1 font-medium text-stone-800">
                                Unit{" "}
                                {(row as EquipmentRow<Tractor>).unitNumber ||
                                    (row as EquipmentRow<Tractor>).vin?.slice(-4)?.toUpperCase() ||
                                    `[${row.id}]`}
                            </p>
                            {row.pendingChanges?.length ? (
                                <p className="w-max whitespace-nowrap rounded-full bg-yellow-50 px-2 text-yellow-700">
                                    <span className="border-b border-dashed border-stone-300">Pending changes</span>
                                </p>
                            ) : null}
                        </div>
                        <p className="uppercase tabular-nums">{row.vin || "[No VIN provided]"}</p>
                        <div className="flex gap-2">
                            <p className="text-sm">{row.year}</p>
                            <p className="text-sm">{row.make}</p>
                            <p className="text-sm capitalize">{row.type?.name}</p>
                        </div>
                        <div className="flex gap-2">
                            <p className="text-sm tabular-nums">
                                {row.policyAcv
                                    ? "ACV"
                                    : row.policyValue?.toLocaleString("en-us", {
                                          style: "currency",
                                          currency: "USD",
                                          maximumFractionDigits: 0,
                                      })}
                            </p>
                            {attributes.length ? `(${attributes.join(", ")})` : null}
                        </div>
                        <div>
                            <div className="flex gap-1">
                                <ShieldCheckIcon className="h-4 w-4 text-waypoint-blue" />
                                Coverages:
                            </div>
                            <div className="ml-5 flex flex-wrap gap-x-2 gap-y-1">
                                {row.coverages?.filter((c) => !c.isUpcoming).length ? (
                                    <span className="text-stone-600">
                                        {row.coverages
                                            .filter(
                                                (c) =>
                                                    !c.isUpcoming &&
                                                    c.coverageOptionId !== CoverageOption.Id.INDEPENDENT_CONTRACTOR_POLICY_MONITORING
                                            )
                                            .map((c) => CoverageOption.getMetadata(c.coverageOptionId).name)
                                            .sort()
                                            .join(", ")}
                                    </span>
                                ) : (
                                    <span className="text-stone-500">[Not covered]</span>
                                )}
                            </div>
                        </div>
                        {row.additionalInterests?.length ? (
                            <div>
                                <div className="flex gap-1">
                                    <BuildingLibraryIcon className="h-4 w-4" />
                                    Additional interests:
                                </div>
                                <div className="ml-5">
                                    {row.additionalInterests.map((ai) => (
                                        <span key={ai.id}>
                                            {ai.additionalInterest?.name || "[Unknown]"}
                                            {" - "}
                                            {[
                                                ai.isAdditionalInsured && "Additional Insured",
                                                ai.isLossPayee && "Loss Payee",
                                                ai.isMortgagee && "Mortgagee",
                                                ai.isPrimaryAndNonContributory && "Primary and Non-Contributory",
                                                ai.isWaiverOfSubrogation && "Waiver of Subrogation",
                                            ]
                                                .filter(Boolean)
                                                .join(", ")}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        ) : null}
                    </Popover.Button>
                </Popover>
                <NewUnitDialog
                    open={changeDialogOpen}
                    onClose={() => setChangeDialogOpen(false)}
                    unitType={type}
                    defaultValues={{
                        atDate: moment().format("YYYY-MM-DD"),
                        coverages: info.row.original.coverages,
                        vin: info.row.original.vin ?? undefined,
                        year: info.row.original.year ?? undefined,
                        make: info.row.original.make ?? undefined,
                        value: info.row.original.policyValue ?? undefined,
                        typeId: info.row.original.typeId ?? undefined,
                        unitNumber: info.row.original.unitNumber ?? undefined,
                        ownerOperator: (info.row.original as EquipmentRow<Tractor | Trailer>).policyOwnerOperator ?? undefined,
                        apdLossPayees:
                            (info.row.original as EquipmentRow<Tractor | Trailer>).additionalInterests
                                ?.filter((ai) => ai.isLossPayee)
                                .map((ai) => ({
                                    additionalInterestId: ai.additionalInterest!.id,
                                    remove: false,
                                    name: ai.additionalInterest?.name || "",
                                    street: ai.additionalInterest?.street || "",
                                    street2: ai.additionalInterest?.street2 || "",
                                    city: ai.additionalInterest?.city || "",
                                    state: ai.additionalInterest?.state || "",
                                    zip: ai.additionalInterest?.zip || "",
                                    email: ai.additionalInterest?.email || "",
                                    fax: ai.additionalInterest?.fax || "",
                                })) || [],
                    }}
                    editingId={info.row.original.id}
                />
                <RemoveUnitDialog
                    open={removeDialogOpen}
                    onClose={() => {
                        setRemoveDialogOpen(false);
                    }}
                    accountId={info.row.original.accountId}
                    unitId={info.row.original.id}
                    unitType={type}
                    coverages={info.row.original.coverages}
                    defaultDate={moment().format("YYYY-MM-DD")}
                />
            </>
        );
    };
}

const mobileTractorCell: DisplayColumnDef<EquipmentRow<Tractor>> = {
    id: "mobile-only",
    cell: createMobileCell("tractors") as never,
    header: () => null,
    meta: { responsive: true, classes: { header: "hidden" } },
};

const mobileTrailerCell: DisplayColumnDef<EquipmentRow<Trailer>> = {
    id: "mobile-only",
    cell: createMobileCell("trailers") as never,
    meta: { responsive: true, classes: { header: "hidden" } },
};

const mobileBusinessAutoCell: DisplayColumnDef<EquipmentRow<BusinessAuto>> = {
    id: "mobile-only",
    cell: createMobileCell("autos") as never,
    meta: { responsive: true, classes: { header: "hidden" } },
};

const mobileToolCell: DisplayColumnDef<EquipmentRow<Tool>> = {
    id: "mobile-only",
    cell: function MobileToolCell(info) {
        const row = info.row.original;

        const [changeDialogOpen, setChangeDialogOpen] = useState(false);
        const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

        return (
            <>
                <Popover>
                    <Popover.Content>
                        <Popover.Item
                            onClick={() => {
                                setChangeDialogOpen(true);
                            }}
                            disabled={!!info.row.original.pendingChanges?.length}
                        >
                            <PencilSquareIcon className="h-5 w-5" />
                            <span>Update{info.row.original.pendingChanges?.length ? " (pending)" : ""}</span>
                        </Popover.Item>
                    </Popover.Content>
                    <Popover.Button className="w-full space-y-1 text-left text-stone-600">
                        <div className="flex justify-between gap-2">
                            <p className="line-clamp-1 font-medium text-stone-800">
                                {row.description || row.serialNumber?.slice(-4) || `[${row.id}]`}
                            </p>
                            {row.pendingChanges?.length ? (
                                <p className="w-max whitespace-nowrap rounded-full bg-yellow-50 px-2 text-yellow-700">
                                    <span className="border-b border-dashed border-stone-300">Pending changes</span>
                                </p>
                            ) : null}
                        </div>
                        <p className="tabular-nums">{row.serialNumber || "[No S/N provided]"}</p>
                        <div className="flex gap-2">
                            <p className="text-sm">{row.year}</p>
                            <p className="text-sm capitalize">{row.make}</p>
                            <p className="text-sm capitalize">{row.model}</p>
                        </div>
                        <p className="text-sm tabular-nums">
                            {row.policyAcv
                                ? "ACV"
                                : row.policyValue?.toLocaleString("en-us", {
                                      style: "currency",
                                      currency: "USD",
                                      maximumFractionDigits: 0,
                                  })}
                        </p>
                        <div className="flex gap-2">
                            {row.coverages?.filter((c) => !c.isUpcoming).length ? (
                                <span className="text-stone-600">
                                    {row.coverages
                                        .filter(
                                            (c) =>
                                                !c.isUpcoming &&
                                                c.coverageOptionId !== CoverageOption.Id.INDEPENDENT_CONTRACTOR_POLICY_MONITORING
                                        )
                                        .map((c) => CoverageOption.getMetadata(c.coverageOptionId).name)
                                        .sort()
                                        .join(", ")}
                                </span>
                            ) : (
                                <span className="text-stone-500">No coverages</span>
                            )}
                        </div>
                    </Popover.Button>
                </Popover>
                <NewUnitDialog
                    open={changeDialogOpen}
                    onClose={() => setChangeDialogOpen(false)}
                    unitType="tools"
                    defaultValues={{
                        atDate: moment().format("YYYY-MM-DD"),
                        coverages: info.row.original.coverages,
                        year: info.row.original.year ?? undefined,
                        description: info.row.original.description,
                        make: info.row.original.make ?? undefined,
                        model: info.row.original.model ?? undefined,
                        serialNumber: info.row.original.serialNumber ?? undefined,
                        value: info.row.original.policyValue ?? undefined,
                    }}
                    editingId={info.row.original.id}
                />
                <RemoveUnitDialog
                    open={removeDialogOpen}
                    onClose={() => {
                        setRemoveDialogOpen(false);
                    }}
                    accountId={info.row.original.accountId}
                    unitId={info.row.original.id}
                    unitType="tools"
                    coverages={info.row.original.coverages}
                    defaultDate={moment().format("YYYY-MM-DD")}
                />
            </>
        );
    },
    meta: { responsive: true, classes: { header: "hidden" } },
};

const upcomingDate: AccessorColumnDef<EquipmentRow<Tractor | Trailer | BusinessAuto | Tool> | DriverRow> = {
    id: "upcomingDate",
    accessorFn: (row) => row.policyUpcomingDate,
    header: () => null,
    cell: (info) => {
        if (info.getValue()) {
            const m = moment(info.getValue() as string | null);
            return (
                <Tooltip title={`Unit will be added on ${info.getValue()}`}>
                    <span className="cursor-pointer">{m.fromNow()}</span>
                </Tooltip>
            );
        }
        return null;
    },
    sortingFn: "datetime",
    enableGlobalFilter: false,
};

const pending: AccessorColumnDef<EquipmentRow<Tractor | Trailer | BusinessAuto | Tool> | DriverRow> = {
    id: "pending",
    accessorFn: (row) => !!row.pendingChanges,
    header: () => null,
    cell: (info) => {
        return info.row.original.pendingChanges?.length ? (
            <Tooltip
                title={
                    <div>
                        <p className="text-sm text-stone-500">Your account manager has been notified to:</p>
                        <ul>
                            {info.row.original.pendingChanges.map((change) => (
                                <li key={change.id}>{change.descriptionShort}</li>
                            ))}
                        </ul>
                    </div>
                }
                classes={{ tooltip: "bg-white shadow-lg rounded p-2 text-stone-800 font-normal border" }}
            >
                <p className="w-max whitespace-nowrap rounded-full bg-yellow-50 px-2 text-yellow-700">
                    <span className="border-b border-dashed border-stone-300">Pending changes</span>
                </p>
            </Tooltip>
        ) : null;
    },
    enableGlobalFilter: false,
};

const unitNumber: AccessorColumnDef<EquipmentRow<Tractor | Trailer | BusinessAuto>> = {
    id: "unitNumber",
    accessorFn: (row) => row.unitNumber,
    header: "Unit",
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
    cell: PrimaryCell,
};

const year: AccessorColumnDef<EquipmentRow<Tractor | Trailer | BusinessAuto | Tool>> = {
    id: "year",
    accessorFn: (row) => row.year,
    header: "Year",
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const make: AccessorColumnDef<EquipmentRow<Tractor | Trailer | BusinessAuto>> = {
    id: "make",
    accessorFn: (row) => row.make,
    header: "Make",
    meta: { classes: { cell: "capitalize" } },
};

const model: AccessorColumnDef<EquipmentRow<Tool>> = {
    id: "model",
    accessorFn: (row) => row.model,
    header: "Model",
    meta: { classes: { cell: "capitalize" } },
};

const type: AccessorColumnDef<EquipmentRow<Tractor | Trailer | BusinessAuto>> = {
    id: "type",
    accessorFn: (row) => row.type?.name,
    header: "Type",
    meta: { classes: { cell: "capitalize" } },
    sortingFn: "alphanumeric",
};

const vin: AccessorColumnDef<EquipmentRow<Tractor | Trailer | BusinessAuto>> = {
    id: "vin",
    accessorFn: (row) => row.vin,
    header: "VIN",
    meta: { classes: { cell: "uppercase font-mono" } },
    sortingFn: "alphanumeric",
};

const serialNumber: AccessorColumnDef<EquipmentRow<Tool>> = {
    id: "serialNumber",
    accessorFn: (row) => row.serialNumber,
    header: "Serial #",
    meta: { classes: { cell: "uppercase font-mono" } },
    sortingFn: "alphanumeric",
};

const description: AccessorColumnDef<EquipmentRow<Tool>> = {
    id: "description",
    accessorFn: (row) => row.description,
    header: "Description",
    meta: { classes: { cell: "text-stone-700" } },
};

const value: AccessorColumnDef<EquipmentRow<Tractor | Trailer | BusinessAuto | Tool>> = {
    id: "value",
    accessorFn: (row) =>
        row.policyAcv
            ? "ACV"
            : row.policyValue?.toLocaleString("en-us", {
                  currency: "USD",
                  style: "currency",
                  maximumFractionDigits: 0,
              }),
    header: "Value",
    meta: { align: "right", classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
    enableGlobalFilter: false,
};

const ownerOperator: AccessorColumnDef<DriverRow> = {
    id: "owner-operator",
    accessorFn: (row) => row.policyOwnerOperator,
    header: "IC owned",
    cell: (info) => <span>{info.getValue() ? <CheckIcon className="h-4 w-4" /> : null}</span>,
};

const coverages: AccessorColumnDef<EquipmentRow<Tractor | Trailer | BusinessAuto | Tool>> = {
    id: "coverages",
    accessorFn: (row) =>
        row.coverages
            ?.filter((c) => c.coverageOptionId !== CoverageOption.Id.INDEPENDENT_CONTRACTOR_POLICY_MONITORING)
            .sort((a, b) =>
                CoverageOption.getMetadata(a.coverageOptionId).defaultAbbreviation.localeCompare(
                    CoverageOption.getMetadata(b.coverageOptionId).defaultAbbreviation
                )
            )
            .map((c) => CoverageOption.getMetadata(c.coverageOptionId).defaultAbbreviation)
            .join(", "),
    cell: (info) => {
        const coverages = info.row.original.coverages?.filter((c) => !c.isUpcoming);
        if (coverages?.length) {
            const sortedCoverages = coverages
                .filter((c) => c.coverageOptionId !== CoverageOption.Id.INDEPENDENT_CONTRACTOR_POLICY_MONITORING)
                .sort((a, b) =>
                    CoverageOption.getMetadata(a.coverageOptionId).defaultAbbreviation.localeCompare(
                        CoverageOption.getMetadata(b.coverageOptionId).defaultAbbreviation
                    )
                );
            return (
                <Tooltip
                    classes={{ tooltip: "font-normal" }}
                    title={
                        coverages?.length ? (
                            <ul>
                                {sortedCoverages.map((c) => (
                                    <li key={c.policyId + c.coverageOptionId}>
                                        {CoverageOption.getMetadata(c.coverageOptionId).name}:{" "}
                                        <span className="text-emerald-600">Active</span>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            ""
                        )
                    }
                >
                    <div className="flex w-max flex-wrap gap-1 border-b border-dashed border-stone-400">
                        {sortedCoverages.map((c) => (
                            <span key={c.policyId + c.coverageOptionId}>
                                {CoverageOption.getMetadata(c.coverageOptionId).defaultAbbreviation}
                            </span>
                        ))}
                    </div>
                </Tooltip>
            );
        } else {
            return <span className="font-medium">[Not covered]</span>;
        }
    },
    header: "Active coverages",
    enableGlobalFilter: false,
    sortingFn: "alphanumeric",
};

const additionalInterests: DisplayColumnDef<EquipmentRow<Tractor | Trailer | BusinessAuto | Tool>> = {
    id: "additionalInterests",
    cell: (info) => {
        const additionalInterests = info.row.original.additionalInterests;
        if (additionalInterests?.length) {
            return (
                <Tooltip
                    title={
                        <ul>
                            {additionalInterests.map((ai) => (
                                <li key={ai.id}>
                                    {ai.additionalInterest?.name || "[Unknown]"} -{" "}
                                    {[
                                        ai.isAdditionalInsured && "Additional Insured",
                                        ai.isLossPayee && "Loss Payee",
                                        ai.isMortgagee && "Mortgagee",
                                        ai.isPrimaryAndNonContributory && "Primary and Non-Contributory",
                                        ai.isWaiverOfSubrogation && "Waiver of Subrogation",
                                    ]
                                        .filter(Boolean)
                                        .join(", ")}
                                </li>
                            ))}
                        </ul>
                    }
                >
                    <BuildingLibraryIcon className="h-4 w-4" />
                </Tooltip>
            );
        } else {
            return null;
        }
    },
    header: "",
    enableGlobalFilter: false,
};

function AutoIdButton({ row, type }: { row: EquipmentRow<Tractor | Trailer | BusinessAuto>; type: "tractors" | "trailers" | "autos" }) {
    const { mutate: getAutoId, isPending } = api.equipment.useGetAutoId(type, row.accountId, {
        onSuccess: (data) => {
            const url = window.URL.createObjectURL(data);
            window.open(url, "_blank");
        },
        onError: () => {
            useSnackbar.add("Could not get Auto ID. Please try again later.", {
                variant: "error",
            });
        },
    });

    return (
        <Popover.Item
            onClick={() => getAutoId(row.id)}
            disabled={
                isPending ||
                !row.coverages?.filter(
                    (c) => !c.isUpcoming && c.coverageOptionId !== CoverageOption.Id.INDEPENDENT_CONTRACTOR_POLICY_MONITORING
                ).length
            }
            className="whitespace-nowrap"
        >
            {isPending ? <ArrowPathIcon className="h-5 w-5 animate-spin text-stone-400" /> : <DocumentTextIcon className="h-5 w-5" />}
            <span>
                Open auto ID
                {!row.coverages?.filter(
                    (c) => !c.isUpcoming && c.coverageOptionId !== CoverageOption.Id.INDEPENDENT_CONTRACTOR_POLICY_MONITORING
                ).length
                    ? " (no coverages)"
                    : ""}
            </span>
        </Popover.Item>
    );
}

function createActionsColumn(type: EquipmentTypeString): DisplayColumnDef<EquipmentRow<Tractor | Trailer | BusinessAuto | Tool>> {
    const actionsCell: DisplayColumnDef<EquipmentRow<Tractor | Trailer | BusinessAuto | Tool>> = {
        id: "actions",
        cell: function ActionsCell(info: CellContext<EquipmentRow<Tractor | Trailer | BusinessAuto | Tool>, void>) {
            const [changeDialogOpen, setChangeDialogOpen] = useState(false);
            const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

            return (
                <>
                    <Popover>
                        <Popover.Content>
                            {type !== "tools" && <AutoIdButton type={type} row={info.row.original as EquipmentRow<Tractor>} />}
                            <Popover.Item
                                onClick={() => {
                                    setChangeDialogOpen(true);
                                }}
                                disabled={!!info.row.original.pendingChanges?.length}
                            >
                                <PencilSquareIcon className="h-5 w-5" />
                                <span>Update{info.row.original.pendingChanges?.length ? " (pending)" : ""}</span>
                            </Popover.Item>
                            <Popover.Item
                                onClick={() => {
                                    setRemoveDialogOpen(true);
                                }}
                                disabled={!!info.row.original.pendingChanges?.length}
                            >
                                <BackspaceIcon className="h-5 w-5" />
                                <span>Remove</span>
                            </Popover.Item>
                        </Popover.Content>
                        <Popover.Button className="rounded p-1 hover:bg-stone-100">
                            <EllipsisVerticalIcon className="h-5 w-5" />
                        </Popover.Button>
                    </Popover>
                    <NewUnitDialog
                        open={changeDialogOpen}
                        onClose={() => setChangeDialogOpen(false)}
                        unitType={type}
                        defaultValues={{
                            atDate: moment().format("YYYY-MM-DD"),
                            coverages: info.row.original.coverages,
                            vin: (info.row.original as EquipmentRow<Tractor>).vin ?? undefined,
                            year: info.row.original.year ?? undefined,
                            description: (info.row.original as EquipmentRow<Tool>).description,
                            make: info.row.original.make ?? undefined,
                            model: (info.row.original as EquipmentRow<Tool>).model ?? undefined,
                            serialNumber: (info.row.original as EquipmentRow<Tool>).serialNumber ?? undefined,
                            value: info.row.original.policyValue ?? undefined,
                            typeId: (info.row.original as EquipmentRow<Tractor>).typeId ?? undefined,
                            unitNumber: (info.row.original as EquipmentRow<Tractor>).unitNumber ?? undefined,
                            ownerOperator: (info.row.original as EquipmentRow<Tractor | Trailer>).policyOwnerOperator ?? undefined,
                            apdLossPayees:
                                (info.row.original as EquipmentRow<Tractor | Trailer>).additionalInterests
                                    ?.filter((ai) => ai.isLossPayee)
                                    .map((ai) => ({
                                        additionalInterestId: ai.additionalInterest!.id,
                                        remove: false,
                                        name: ai.additionalInterest?.name || "",
                                        street: ai.additionalInterest?.street || "",
                                        street2: ai.additionalInterest?.street2 || "",
                                        city: ai.additionalInterest?.city || "",
                                        state: ai.additionalInterest?.state || "",
                                        zip: ai.additionalInterest?.zip || "",
                                        email: ai.additionalInterest?.email || "",
                                        fax: ai.additionalInterest?.fax || "",
                                    })) || [],
                        }}
                        editingId={info.row.original.id}
                    />
                    <RemoveUnitDialog
                        open={removeDialogOpen}
                        onClose={() => {
                            setRemoveDialogOpen(false);
                        }}
                        accountId={info.row.original.accountId}
                        unitId={info.row.original.id}
                        unitType={type}
                        coverages={info.row.original.coverages}
                        defaultDate={moment().format("YYYY-MM-DD")}
                    />
                </>
            );
        },
    };
    return actionsCell;
}

export const linkedDriversColumn: AccessorColumnDef<EquipmentRow<Tractor | Trailer>> = {
    id: "drivers",
    header: "Driver(s)",
    accessorFn: (row) => row.drivers,
    cell: function LinkedDriverCell(info) {
        const { data: drivers } = api.drivers.useFind(info.row.original.accountId);
        const meta = info.table.options.meta as EquipmentTableMeta | undefined;
        const linked = info.row.original.drivers?.map((t1) => drivers?.find((t2) => t1.id === t2.id)).filter(Boolean) as DriverRow[];

        return (
            <Tooltip
                classes={{ tooltip: "bg-white shadow-lg rounded p-2 text-stone-800 font-normal border" }}
                title={
                    linked?.length ? (
                        <div className="space-y-4">
                            {linked.map((driver) => (
                                <div key={driver.id}>
                                    <div className="flex justify-between gap-4">
                                        <p>{driver.name}</p>
                                        {driver.policyOwnerOperator && <p className="text-blue-600 small-caps">IC</p>}
                                    </div>
                                    <p>#{driver.license}</p>
                                </div>
                            ))}
                        </div>
                    ) : (
                        ""
                    )
                }
            >
                <button
                    className="whitespace-nowrap rounded px-2 py-1 text-left ring-blue-700 hover:bg-stone-50 focus:bg-stone-100 focus:ring-1"
                    onClick={() => {
                        meta?.openDriverLinker(info.row.original.id);
                    }}
                >
                    {linked?.length ? (
                        <span>{linked.map((d) => d.name?.split(" ")[0] || "[Unknown]").join(", ")}</span>
                    ) : (
                        <span className="italic text-stone-400">Unassigned</span>
                    )}
                </button>
            </Tooltip>
        );
    },
    enableSorting: false,
    enableGlobalFilter: false,
};

export const ownerOperatorAPDFirstNamedInsureds: AccessorColumnDef<EquipmentRow<Tractor | Trailer>> = {
    id: "firstNamedInsured",
    header: "Named Insured (APD)",
    accessorFn: (row) => row.ownerOperatorAPDFirstNamedInsureds || [],
    cell: (info) => {
        const value = info.getValue() as string[] | undefined;

        if (value?.length) {
            if (value.length > 1) {
                return (
                    <Tooltip
                        title={
                            <div>
                                {value.map((v, i) => (
                                    <p key={i}>{v}</p>
                                ))}
                            </div>
                        }
                    >
                        <span className="overflow-ellipsis whitespace-nowrap">
                            {value[0]} <span className="text-blue-600">+{value.length - 1}</span>
                        </span>
                    </Tooltip>
                );
            } else {
                return <span className="overflow-ellipsis whitespace-nowrap">{value[0]}</span>;
            }
        }
        return null;
    },
    enableSorting: true,
    enableGlobalFilter: true,
};

const tractorActions = createActionsColumn("tractors");
const trailerActions = createActionsColumn("trailers");
const businessAutoActions = createActionsColumn("autos");
const toolActions = createActionsColumn("tools");

export default {
    mobileTractorCell,
    mobileTrailerCell,
    mobileBusinessAutoCell,
    mobileToolCell,
    upcomingDate,
    pending,
    unitNumber,
    year,
    make,
    model,
    type,
    vin,
    value,
    ownerOperator,
    coverages,
    additionalInterests,
    serialNumber,
    description,
    tractorActions,
    trailerActions,
    businessAutoActions,
    linkedDriversColumn,
    ownerOperatorAPDFirstNamedInsureds,
    toolActions,
};
