import { Tractor, Trailer } from "@deathstar/types/northstar";
import { EquipmentRow } from "@deathstar/types/waypoint";
import { Checkbox, InputField, Tabs } from "@deathstar/ui";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { CircularProgress, Dialog } from "@material-ui/core";
import { matchSorter } from "match-sorter";
import { useMemo, useState } from "react";
import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";

function UnitLinker({
    open,
    currentValue,
    onChange,
    onClose,
    data,
}: {
    open: boolean;
    currentValue: { id: number }[];
    onChange(value: { id: number }[]): unknown;
    onClose(): void;
    data: EquipmentRow<Tractor | Trailer>[] | undefined;
}) {
    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState<"company" | "ic" | "all">("all");
    const filtered = useMemo(
        () =>
            matchSorter(
                (data || []).filter((r) =>
                    filter === "all" ? true : filter === "company" ? !r.policyOwnerOperator : r.policyOwnerOperator
                ),
                search,
                { keys: ["unitNumber", "year", "make", (x) => x.type?.name || "", "vin"] }
            ),
        [data, search, filter]
    );

    return (
        <Dialog open={open} onClose={onClose} classes={{ paper: "p-4 w-screen max-w-4xl" }}>
            <Tabs
                tabs={[
                    { label: () => <div className="min-w-16 px-2">All</div>, value: "all", buttonProps: { className: "z-[1] grow" } },
                    {
                        label: () => <div className="min-w-16 px-2">Company owned</div>,
                        value: "company",
                        buttonProps: { className: "z-[1] grow" },
                    },
                    {
                        label: () => <div className="min-w-16 px-2">IC owned</div>,
                        value: "ic",
                        buttonProps: { className: "z-[1] grow" },
                    },
                ]}
                value={filter}
                onChange={setFilter}
                className="mb-1 rounded-md bg-stone-100 py-1 text-sm shadow-inner"
                borderClasses="bg-white border border-waypoint-blue z-[0] shadow rounded-md"
            />
            <InputField
                startAdornment={<MagnifyingGlassIcon className="h-4 w-4 text-stone-400" />}
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                classes={{ inputContainer: "py-1", root: "mb-4 sticky top-2", input: "placeholder:!text-stone-400" }}
                placeholder="Search..."
            />
            <table className="table-auto text-sm col-spacing-4 row-p-2 row-spacing-2">
                <thead>
                    <tr>
                        <th></th>
                        <th className="text-left text-xs font-light uppercase text-gray-500">Unit #</th>
                        <th className="text-left text-xs font-light uppercase text-gray-500">Year</th>
                        <th className="text-left text-xs font-light uppercase text-gray-500">Make</th>
                        <th className="text-left text-xs font-light uppercase text-gray-500">Type</th>
                        <th className="text-left text-xs font-light uppercase text-gray-500">VIN</th>
                    </tr>
                </thead>
                <tbody>
                    {filtered.map((tractor) => {
                        const isSelected = currentValue.some((t) => t.id === tractor.id);
                        return (
                            <tr
                                key={tractor.id}
                                className="cursor-pointer hover:bg-gray-100 [&>td:first-child]:rounded-l [&>td:last-child]:rounded-r"
                                onClick={async () => {
                                    if (isSelected) {
                                        onChange(currentValue.filter((t) => t.id !== tractor.id));
                                    } else {
                                        onChange([...currentValue, { id: tractor.id }]);
                                    }
                                }}
                            >
                                <td>
                                    <Checkbox classes={{ input: "w-4 h-4" }} checked={isSelected} readOnly />
                                </td>
                                <td>{tractor.unitNumber}</td>
                                <td>{tractor.year}</td>
                                <td>{tractor.make}</td>
                                <td>{tractor.type?.name}</td>
                                <td>{tractor.vin}</td>
                            </tr>
                        );
                    })}
                    {!data ? (
                        <tr>
                            <td colSpan={6}>
                                <div className="flex w-full items-center justify-center">
                                    <CircularProgress className="h-4 w-4" />
                                </div>
                            </td>
                        </tr>
                    ) : (
                        !filtered.length && (
                            <tr>
                                <td colSpan={6} className="text-center text-stone-500">
                                    No units found
                                </td>
                            </tr>
                        )
                    )}
                </tbody>
            </table>
        </Dialog>
    );
}

export function TractorLinker({
    open,
    currentValue,
    onChange,
    onClose,
}: {
    open: boolean;
    currentValue: { id: number }[];
    onChange(value: { id: number }[]): unknown;
    onClose(): void;
}) {
    const accountId = useAccountId();
    const { data } = api.equipment.useFindTractors(accountId!);

    return <UnitLinker open={open} currentValue={currentValue} onChange={onChange} onClose={onClose} data={data} />;
}

export function TrailerLinker({
    open,
    currentValue,
    onChange,
    onClose,
}: {
    open: boolean;
    currentValue: { id: number }[];
    onChange(value: { id: number }[]): unknown;
    onClose(): void;
}) {
    const accountId = useAccountId();
    const { data } = api.equipment.useFindTrailers(accountId!);

    return <UnitLinker open={open} currentValue={currentValue} onChange={onChange} onClose={onClose} data={data} />;
}
