import { Driver } from "@deathstar/types/northstar";
import { PrimaryButton, useSnackbar } from "@deathstar/ui";
import { CircularProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import api from "../../api/api";
import { UnauthenticatedError, UnauthorizedError } from "../../api/util/exceptions";
import { Dialog } from "../../components/dialog/Dialog";

export default function RemoveDriverDialog({
    accountId,
    unitId,
    coverages,
    open,
    onClose,
    defaultDate,
}: {
    accountId: string;
    unitId: number;
    coverages: Driver["coverages"];
    open: boolean;
    onClose(): void;
    defaultDate?: string;
}) {
    const [date, setDate] = useState<string | null>(null);
    const remove = api.drivers.useUpdate(accountId, {
        onSuccess: () => {
            onClose();
        },
        onError: (e) => {
            if (e instanceof UnauthenticatedError || e instanceof UnauthorizedError) {
                useSnackbar.add("You do not have permission to remove this driver.", {
                    variant: "error",
                });
            } else {
                useSnackbar.add("Could not remove driver. Please try again later.", {
                    variant: "error",
                });
            }
        },
    });

    useEffect(() => {
        if (open) {
            setDate(defaultDate ?? null);
        }
    }, [open, defaultDate]);

    return (
        <Dialog
            open={open}
            onClose={() => {
                if (!remove.isPending) {
                    onClose();
                }
            }}
        >
            <div className="space-y-6 p-8">
                <p className="text-lg font-medium">Remove driver from all coverages?</p>
                <div className="flex flex-col items-center">
                    <p className="text-stone-500">Date of removal</p>
                    <input
                        type="date"
                        value={date || ""}
                        onChange={(e) => setDate(e.target.value || null)}
                        className="rounded border border-stone-300 p-2"
                    />
                </div>
                <div className="flex items-end justify-end gap-8">
                    <button className="p-2 text-stone-600 hover:bg-stone-50" disabled={remove.isPending} onClick={() => onClose()}>
                        Cancel
                    </button>
                    <PrimaryButton
                        disabled={remove.isPending}
                        onClick={() => {
                            remove.mutate({
                                id: unitId,
                                coverages: coverages?.map((c) => ({
                                    ...c,
                                    remove: true,
                                })),
                                atDate: date || undefined,
                            });
                        }}
                    >
                        {remove.isPending && <CircularProgress size="1rem" classes={{ svg: "text-white" }} />}
                        Remove
                    </PrimaryButton>
                </div>
            </div>
        </Dialog>
    );
}
