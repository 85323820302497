import { Message } from "../ahoy";
import { DOTEligibilityData } from "../customers/hdvi";
import { AuthorityType } from "../FMCSA";
import { Call } from "./Call";
import { Competitor } from "./Competitor";
import { Contact } from "./Contact";
import { User } from "./User";
import { Widget } from "./Widget";

export type TruckRange = "1-5" | "6-9" | "10-25" | "26-50" | "51-75" | "76-100" | "100+";
export const truckRanges: TruckRange[] = ["1-5", "6-9", "10-25", "26-50", "51-75", "76-100", "100+"];

export type SafetyReviewRating = "Satisfactory" | "Conditional" | "Unsatisfactory" | "Not Rated";

export type ProspectStatus = "Lead" | "Suspect" | "Target" | "In-Pursuit" | "Engaged" | "Active" | "Client";
export const prospectStatuses: ProspectStatus[] = ["Lead", "Suspect", "Target", "In-Pursuit", "Engaged", "Active", "Client"];

export type IssCategory = "Pass" | "Optional" | "Inspect";

export const alerts: number[] = [0, 1, 2, 3, 4, 5, 6, 7];

export const defaultFilters: Filters = {
    page: 1,
    DotNumbers: [],
    DotNumberStats: [],
    DotNumbersIgnore: [],
    AccountIds: [],
    trucks: ["6-9", "10-25", "26-50", "51-75", "76-100", "100+"],
    insurance: [],
    zipCode: [],
    states: [],
    effectiveMonths: [],
    alerts: [],
    issCategory: [],
    audits: [],
    undistributed: false,
    users: [],
    status: ["Lead"],
    hide: {
        greatWest: false,
        chicago: false,
        badCargo: false,
        movers: false,
        noInsurance: false,
        ic: false,
    },
    show: {
        upcomingCancel: false,
        captive: false,
        reverted: false,
    },
};

export const licensedStates = [
    "MI",
    "OH",
    "IL",
    "IN",
    "IA",
    "KY",
    "MN",
    "ND",
    "PA",
    "SD",
    "WI",
    "KS",
    "MO",
    "NC",
    "TN",
    "TX",
    "WV",
    "VA",
    "NE",
];

export interface Filters {
    page: number;
    DotNumbers: number[];
    DotNumberStats: {
        status: Prospect["status"];
        dot: number;
    }[];
    DotNumbersIgnore: number[];
    AccountIds: string[];
    trucks: TruckRange[];
    insurance: string[];
    states: StateAbbr[];
    effectiveMonths: string[];
    alerts: number[];
    issCategory: IssCategory[];
    audits: SafetyReviewRating[];
    zipCode: string[];
    undistributed: boolean;
    users: string[];
    status: ProspectStatus[];
    hide: {
        greatWest: boolean;
        chicago: boolean;
        badCargo: boolean;
        movers: boolean;
        noInsurance: boolean;
        ic: boolean;
    };
    show: {
        upcomingCancel: boolean;
        captive: boolean;
        reverted: boolean;
    };
}

export type ProspectingStats = {
    total: number;
    suspect: number;
    target: number;
    inPursuit: number;
    engaged: number;
    active: number;
    client: number;
    trucks: Record<string, number>;
    insurance: Record<string, number>;
    states: Record<string, number>;
    months: Record<string, number>;
    undistributed: number;
    unclaimed: number;
    inactive: number[];
};

export type Prospecting = {
    page: number;
    snapshot: string;
    prospects: {
        DotNumber: number;
        AccountId: string;
        Status: ProspectStatus;
        hdvi: DOTEligibilityData;
        LastEmail: { date: string; subject: string };
        LastCall: { date: string; comments: string };
        LastText: { date: string; message: string };
        Name: string;
        NameDba: string;
        Street: string;
        City: string;
        State: string;
        Zip: string;
        CellNumber: number;
        TelNumber: number;
        CompanyRep1: string;
        SafetyReviewRating: "Satisfactory" | "Conditional" | "Unsatisfactory";
        IsHazmatCarrier: boolean;
        IsHouseHoldGoods: boolean;
        TotalTrailers: number;
        TotalPowerUnits: number;
        TotalApplicableCrashes: number;
        TruckCategory: TruckRange;
        GeneralFreight: boolean;
        Household: boolean;
        Metalsheet: boolean;
        MotorVehicle: boolean;
        DriveTow: boolean;
        LogPole: boolean;
        BuildingMaterial: boolean;
        MobileHome: boolean;
        LargeMachine: boolean;
        Produce: boolean;
        LiquidGas: boolean;
        Intermodal: boolean;
        Passengers: boolean;
        OilField: boolean;
        Livestock: boolean;
        GrainFeed: boolean;
        CoalCoke: boolean;
        Meat: boolean;
        Garbage: boolean;
        UsMail: boolean;
        Chemical: boolean;
        DryBulk: boolean;
        ColdFood: boolean;
        Beverages: boolean;
        PaperProducts: boolean;
        Utility: boolean;
        FarmSupplies: boolean;
        Construction: boolean;
        Waterwell: boolean;
        CargoOther: boolean;
        OtherCargo: string;
        BipdPrimaryInsuranceCompany: string;
        BrokerAuthorityStartDate: Date;
        ContractAuthorityStartDate: Date;
        CommonAuthorityStartDate: Date;
        BipdEffectiveDate: Date;
        BipdEffectiveMonth: string;
        BipdCancelEffectiveDate: Date;
        BipdPolicyNumber: string;
        EmailAddress: string;
        TotalDrivers: number;
        TotalCdlDrivers: number;
        Alerts: number;
        IssGroupNumber: number;
        Latitude: number;
        Longitude: number;
    }[];
    inactive: number[];
};

export const keywordsToRemove = [
    "A MUTUAL INSURANCE COMPANY", // List the longer, more specific phrases FIRST
    "Insurance Risk Retention Group",
    "PROPERTY AND CASUALTY CO.",
    "GENERAL INS CO OF MICHIGAN",
    "MUTUAL INSURANCE COMPANY OF MI",
    "INSURANCE CO., INC.",
    "GROUP INSURANCE RRG",
    "Risk Retention Group, Inc.",
    "INS. CO. OF PITSBGH. PA",
    "OF SOUTH CAROLINA",
    "OF HAWAII,",
    "OF MID-AMERICA",
    "Insurance Co.",
    "INSURANCE COMPANY",
    "INDEMNITY COMPANY",
    "DIRECT INSURANCE COMPANY",
    "HOMESTATE INSURANCE COMPAN",
    "CASUALTY COMPANY",
    "PROPERTY & CASUALTY",
    "Company",
    "Exchange",
    "INSURANCE CO.",
    "SOUTHEASTERN",
    "Ins Co.",
    "EXCHANGE",
    "MICHIGAN",
    "Insurance",
    "INSURANCE",
    "Preferred",
    "PREFERRED",
    ", A Mutual",
    "INC.",
    "MUTUAL CAS. INS. CO.",
    "INS CO.",
    "CASUALTY CO.",
    "MUTUAL INS.",
    "COMPANY",
    "Brokers INC",
    "AND CASUALTY",
    "NORTHERN",
];

export const cargoList = [
    { emoji: "📦", name: "General Freight", key: "GeneralFreight" },
    { emoji: "🏠", name: "Household Goods", key: "Household" },
    { emoji: "🗜️", name: "Metal, Sheets, Coils", key: "Metalsheet" },
    { emoji: "🚗", name: "Motor Vehicles", key: "MotorVehicle" },
    { emoji: "🏗️", name: "Driveaway, Towaway", key: "DriveTow" },
    { emoji: "🌳", name: "Logs, Poles, Lumber", key: "LogPole" },
    { emoji: "🔨", name: "Building Materials", key: "BuildingMaterial" },
    { emoji: "⛺", name: "Mobile Homes", key: "MobileHome" },
    { emoji: "⚙️", name: "Machinery, Large objects", key: "LargeMachine" },
    { emoji: "🍏", name: "Fresh Produce", key: "Produce" },
    { emoji: "💦 💨", name: "Liquids, Gases", key: "LiquidGas" },
    { emoji: "🚢", name: "Intermodal Containers", key: "Intermodal" },
    { emoji: "👨‍👩‍👦", name: "Passengers", key: "Passengers" },
    { emoji: "🛢️", name: "Oilfield equipment", key: "OilField" },
    { emoji: "🐄", name: "Livestock", key: "Livestock" },
    { emoji: "🌾", name: "Grain, Feed, Hay", key: "GrainFeed" },
    { emoji: "🔥", name: "Coal, Coke", key: "CoalCoke" },
    { emoji: "🥩", name: "Meat", key: "Meat" },
    { emoji: "🗑️", name: "Garbage, Trash", key: "Garbage" },
    { emoji: "📬", name: "U/S. Mail", key: "UsMail" },
    { emoji: "🧪", name: "Chemicals", key: "Chemical" },
    { emoji: "🌵", name: "Commodities Dry Bulk", key: "DryBulk" },
    { emoji: "🥶", name: "Refrigerated foods", key: "ColdFood" },
    { emoji: "🥤", name: "Beverages", key: "Beverages" },
    { emoji: "🧻", name: "Paper products", key: "PaperProducts" },
    { emoji: "⚡", name: "Utility", key: "Utility" },
    { emoji: "🚜", name: "Farm supplies", key: "FarmSupplies" },
    { emoji: "🚧", name: "Construction", key: "Construction" },
    { emoji: "🚰", name: "Water, Well", key: "Waterwell" },
    { emoji: "🔘", name: "Other", key: "CargoOther" },
];

export interface Unit {
    UnitId?: number;
    UnitTypeId?: number;
    UnitMake?: string;
    UnitLicense?: string;
    UnitLicenseState?: string;
    UnitVehicleIdNumber?: string;
}

export interface FMCSAProspect {
    DotNumber: number;
    Name: string;
    NameDba: string;
    IsHazmatCarrier: boolean;
    BrokerAuthority: AuthorityType;
    ContractAuthority: AuthorityType;
    CommonAuthority: AuthorityType;
    EntityTypes: string[];
    Street: string;
    City: string;
    State: string;
    Zip: string;
    CellNumber: number;
    TelNumber: number;
    CompanyRep1: string;
    SafetyReviewRating: "Satisfactory" | "Conditional" | "Unsatisfactory";
    TotalTrailers: number;
    TotalPowerUnits: number;
    TotalDrivers: number;
    TotalApplicableCrashes: number;
    BipdPrimaryInsuranceCompany: string;
    BipdEffectiveDate: string;
    EmailAddress: string;
    IssGroupNumber: number;
    Units?: Unit[];
}

export interface Prospect extends FMCSAProspect {
    dot: string;
    status: ProspectStatus;
    revertedDate?: string;
    accountId?: string;
    agentId?: string;
    agent?: User;

    emails: Email[];
    calls: Call[];
    messages: Message[];

    contacts: Contact[];
    competitors: CompetitorProspect[];

    tasks: Widget[];
}

export type CompetitorProspect = {
    competitorId: number;
    dot: string;
    description: string;
    relatedCompetitor: Competitor;
    relatedProspect: Prospect;
};

export type Email = {
    id: string;
    createdDate: string;
    emailRecipients: EmailRecipients[];
    subject: string;
    bodyPreview: string;
    attachments: boolean;
    type: "Inbound" | "Outbound";
};

export type EmailRecipients = {
    emailId: string;
    identifier: string;
    type: "from" | "to" | "cc" | "bcc";
};

export type FMCSAContact = {
    DotNumber: number;
    CellNumber: number | null;
    TelNumber: number | null;
    EmailAddress: string;
    CompanyRep1: string;
};

export const STATES = {
    AK: "AK",
    AL: "AL",
    AR: "AR",
    AZ: "AZ",
    CA: "CA",
    CO: "CO",
    CT: "CT",
    DC: "DC",
    DE: "DE",
    FL: "FL",
    GA: "GA",
    HI: "HI",
    IA: "IA",
    ID: "ID",
    IL: "IL",
    IN: "IN",
    KS: "KS",
    KY: "KY",
    LA: "LA",
    MA: "MA",
    MD: "MD",
    ME: "ME",
    MI: "MI",
    MN: "MN",
    MO: "MO",
    MS: "MS",
    MT: "MT",
    NC: "NC",
    ND: "ND",
    NE: "NE",
    NH: "NH",
    NJ: "NJ",
    NM: "NM",
    NV: "NV",
    NY: "NY",
    OH: "OH",
    OK: "OK",
    OR: "OR",
    PA: "PA",
    RI: "RI",
    SC: "SC",
    SD: "SD",
    TN: "TN",
    TX: "TX",
    UT: "UT",
    VA: "VA",
    VT: "VT",
    WA: "WA",
    WI: "WI",
    WV: "WV",
    WY: "WY",
};

export const STATES_ARRAY = Object.values(STATES);

export type StateAbbr = keyof typeof STATES;
export type StateName = (typeof STATES)[StateAbbr];
export type State = { abbr: StateAbbr; name: StateName };

export const STATE_FIPS: Record<string, { abbr: string; name: string }> = {
    "02": { abbr: "AK", name: "Alaska" },
    "01": { abbr: "AL", name: "Alabama" },
    "05": { abbr: "AR", name: "Arkansas" },
    "60": { abbr: "AS", name: "American Samoa" },
    "04": { abbr: "AZ", name: "Arizona" },
    "06": { abbr: "CA", name: "California" },
    "08": { abbr: "CO", name: "Colorado" },
    "09": { abbr: "CT", name: "Connecticut" },
    "11": { abbr: "DC", name: "District of Columbia" },
    "10": { abbr: "DE", name: "Delaware" },
    "12": { abbr: "FL", name: "Florida" },
    "13": { abbr: "GA", name: "Georgia" },
    "15": { abbr: "HI", name: "Hawaii" },
    "19": { abbr: "IA", name: "Iowa" },
    "16": { abbr: "ID", name: "Idaho" },
    "17": { abbr: "IL", name: "Illinois" },
    "18": { abbr: "IN", name: "Indiana" },
    "20": { abbr: "KS", name: "Kansas" },
    "21": { abbr: "KY", name: "Kentucky" },
    "22": { abbr: "LA", name: "Louisiana" },
    "25": { abbr: "MA", name: "Massachusetts" },
    "23": { abbr: "ME", name: "Maine" },
    "26": { abbr: "MI", name: "Michigan" },
    "27": { abbr: "MN", name: "Minnesota" },
    "28": { abbr: "MS", name: "Mississippi" },
    "30": { abbr: "MT", name: "Montana" },
    "37": { abbr: "NC", name: "North Carolina" },
    "38": { abbr: "ND", name: "North Dakota" },
    "31": { abbr: "NE", name: "Nebraska" },
    "33": { abbr: "NH", name: "New Hampshire" },
    "34": { abbr: "NJ", name: "New Jersey" },
    "35": { abbr: "NM", name: "New Mexico" },
    "32": { abbr: "NV", name: "Nevada" },
    "36": { abbr: "NY", name: "New York" },
    "39": { abbr: "OH", name: "Ohio" },
    "40": { abbr: "OK", name: "Oklahoma" },
    "41": { abbr: "OR", name: "Oregon" },
    "42": { abbr: "PA", name: "Pennsylvania" },
    "72": { abbr: "PR", name: "Puerto Rico" },
    "44": { abbr: "RI", name: "Rhode Island" },
    "46": { abbr: "SD", name: "South Dakota" },
    "47": { abbr: "TN", name: "Tennessee" },
    "48": { abbr: "TX", name: "Texas" },
    "49": { abbr: "UT", name: "Utah" },
    "51": { abbr: "VA", name: "Virginia" },
    "78": { abbr: "VI", name: "Virgin Islands" },
    "50": { abbr: "VT", name: "Vermont" },
    "53": { abbr: "WA", name: "Washington" },
    "55": { abbr: "WI", name: "Wisconsin" },
    "54": { abbr: "WV", name: "West Virginia" },
    "56": { abbr: "WY", name: "Wyoming" },
    "29": { abbr: "MO", name: "Missouri" },
    "45": { abbr: "SC", name: "South Carolina" },
    "66": { abbr: "GU", name: "Guam" },
    "69": { abbr: "MP", name: "Northern Marianas" },
    "24": { abbr: "MD", name: "Maryland" },
};

export const excludedZipCodes = [
    "60660",
    "60661",
    "60666",
    "60668",
    "60669",
    "60675",
    "60677",
    "60686",
    "60687",
    "60688",
    "60690",
    "60693",
    "60695",
    "60696",
    "60699",
    "60706",
    "60707",
    "60712",
    "60714",
    "60803",
    "60804",
    "60805",
    "60827",
    "60901",
    "60910",
    "60913",
    "60914",
    "60915",
    "60917",
    "60919",
    "60922",
    "60935",
    "60940",
    "60941",
    "60944",
    "60950",
    "60954",
    "60958",
    "60961",
    "60964",
    "60969",
    "61038",
    "60481",
    "60482",
    "60484",
    "60487",
    "60490",
    "60491",
    "60499",
    "60501",
    "60502",
    "60503",
    "60504",
    "60505",
    "60506",
    "60507",
    "60510",
    "60511",
    "60512",
    "60513",
    "60514",
    "60515",
    "60516",
    "60517",
    "60519",
    "60521",
    "60522",
    "60523",
    "60525",
    "60526",
    "60527",
    "60532",
    "60534",
    "60536",
    "60537",
    "60538",
    "60540",
    "60541",
    "60542",
    "60543",
    "60544",
    "60545",
    "60546",
    "60548",
    "60554",
    "60555",
    "60558",
    "60559",
    "60560",
    "60561",
    "60563",
    "60564",
    "60565",
    "60566",
    "60567",
    "60568",
    "60585",
    "60586",
    "60601",
    "60602",
    "60603",
    "60604",
    "60605",
    "60606",
    "60607",
    "60608",
    "60609",
    "60610",
    "60611",
    "60612",
    "60613",
    "60614",
    "60615",
    "60616",
    "60617",
    "60618",
    "60619",
    "60620",
    "60621",
    "60622",
    "60623",
    "60624",
    "60625",
    "60626",
    "60628",
    "60629",
    "60630",
    "60631",
    "60632",
    "60633",
    "60634",
    "60636",
    "60637",
    "60638",
    "60639",
    "60640",
    "60641",
    "60642",
    "60643",
    "60644",
    "60645",
    "60646",
    "60647",
    "60649",
    "60651",
    "60652",
    "60653",
    "60654",
    "60655",
    "60656",
    "60657",
    "60659",
    "60162",
    "60163",
    "60164",
    "60165",
    "60169",
    "60171",
    "60172",
    "60173",
    "60174",
    "60175",
    "60176",
    "60177",
    "60179",
    "60180",
    "60181",
    "60183",
    "60184",
    "60185",
    "60186",
    "60187",
    "60188",
    "60189",
    "60190",
    "60191",
    "60192",
    "60193",
    "60194",
    "60195",
    "60196",
    "60201",
    "60202",
    "60203",
    "60204",
    "60208",
    "60290",
    "60301",
    "60302",
    "60304",
    "60305",
    "60401",
    "60402",
    "60403",
    "60404",
    "60406",
    "60407",
    "60408",
    "60409",
    "60410",
    "60411",
    "60412",
    "60415",
    "60416",
    "60417",
    "60418",
    "60419",
    "60420",
    "60421",
    "60422",
    "60423",
    "60424",
    "60425",
    "60426",
    "60428",
    "60429",
    "60430",
    "60431",
    "60432",
    "60433",
    "60434",
    "60435",
    "60436",
    "60437",
    "60438",
    "60439",
    "60440",
    "60441",
    "60442",
    "60443",
    "60444",
    "60445",
    "60446",
    "60447",
    "60448",
    "60449",
    "60450",
    "60451",
    "60452",
    "60453",
    "60455",
    "60456",
    "60457",
    "60458",
    "60459",
    "60461",
    "60462",
    "60463",
    "60464",
    "60465",
    "60466",
    "60467",
    "60468",
    "60469",
    "60471",
    "60472",
    "60473",
    "60474",
    "60475",
    "60476",
    "60477",
    "60478",
    "60479",
    "60480",
    "60002",
    "60004",
    "60005",
    "60007",
    "60008",
    "60010",
    "60012",
    "60013",
    "60014",
    "60015",
    "60016",
    "60017",
    "60018",
    "60019",
    "60020",
    "60021",
    "60022",
    "60025",
    "60026",
    "60029",
    "60030",
    "60031",
    "60033",
    "60034",
    "60035",
    "60038",
    "60039",
    "60040",
    "60041",
    "60042",
    "60043",
    "60044",
    "60045",
    "60046",
    "60047",
    "60048",
    "60050",
    "60051",
    "60053",
    "60056",
    "60060",
    "60061",
    "60062",
    "60064",
    "60067",
    "60068",
    "60069",
    "60070",
    "60071",
    "60072",
    "60073",
    "60074",
    "60075",
    "60076",
    "60077",
    "60081",
    "60083",
    "60084",
    "60085",
    "60087",
    "60088",
    "60089",
    "60090",
    "60091",
    "60093",
    "60096",
    "60097",
    "60098",
    "60099",
    "60101",
    "60102",
    "60103",
    "60104",
    "60106",
    "60107",
    "60108",
    "60109",
    "60110",
    "60116",
    "60117",
    "60118",
    "60119",
    "60120",
    "60121",
    "60123",
    "60124",
    "60126",
    "60128",
    "60130",
    "60131",
    "60132",
    "60133",
    "60134",
    "60136",
    "60137",
    "60138",
    "60139",
    "60140",
    "60142",
    "60143",
    "60144",
    "60147",
    "60148",
    "60151",
    "60152",
    "60153",
    "60154",
    "60155",
    "60156",
    "60157",
    "60159",
    "60160",
];

export const sampleProspects: Prospecting["prospects"] = [
    {
        DotNumber: 1125,
        AccountId: "1",
        Status: "Lead",
        LastCall: { date: "2021-07-01T00:00:00.000Z", comments: "Left voicemail" },
        LastEmail: { date: "2021-07-01T00:00:00.000Z", subject: "Re: Quote Request" },
        LastText: { date: "2021-07-01T00:00:00.000Z", message: "Hey, just following up on the quote" },
        Name: "THE UNION SAND & SUPPLY CORP",
        NameDba: null,
        hdvi: null,
        Street: "5585 CANAL ROAD",
        City: "VALLEY VIEW",
        State: "OH",
        Zip: "44125",
        BipdEffectiveMonth: "JAN",
        CellNumber: 2165424488,
        TelNumber: 2165424488,
        CompanyRep1: "JAMES BRASDOVICH",
        SafetyReviewRating: null,
        IsHazmatCarrier: true,
        BrokerAuthorityStartDate: new Date("2021-07-01"),
        ContractAuthorityStartDate: new Date("2021-07-01"),
        CommonAuthorityStartDate: new Date("2021-07-01"),
        IsHouseHoldGoods: false,
        TotalTrailers: 0,
        TotalPowerUnits: 1,
        TotalApplicableCrashes: 0,
        TruckCategory: "1-5",
        GeneralFreight: true,
        Household: false,
        Metalsheet: true,
        MotorVehicle: false,
        DriveTow: false,
        LogPole: false,
        BuildingMaterial: true,
        MobileHome: false,
        LargeMachine: false,
        Produce: false,
        LiquidGas: false,
        Intermodal: false,
        Passengers: false,
        OilField: false,
        Livestock: false,
        GrainFeed: false,
        CoalCoke: false,
        Meat: false,
        Garbage: false,
        UsMail: false,
        Chemical: false,
        DryBulk: false,
        ColdFood: false,
        Beverages: false,
        PaperProducts: false,
        Utility: false,
        FarmSupplies: false,
        Construction: true,
        Waterwell: false,
        CargoOther: false,
        OtherCargo: null,
        BipdPrimaryInsuranceCompany: "Great West Casualty Co.",
        BipdEffectiveDate: null,
        BipdCancelEffectiveDate: null,
        BipdPolicyNumber: null,
        EmailAddress: "JBRASDOVICH@ALLEGA.COM",
        TotalDrivers: 1,
        TotalCdlDrivers: 1,
        Alerts: 0,
        IssGroupNumber: 15,
        Longitude: -81.621,
        Latitude: 41.398,
    },
    {
        DotNumber: 1145,
        AccountId: "1",
        Status: "Lead",
        LastCall: { date: "2021-07-01T00:00:00.000Z", comments: "Left voicemail" },
        LastEmail: { date: "2021-07-01T00:00:00.000Z", subject: "Re: Quote Request" },
        LastText: { date: "2021-07-01T00:00:00.000Z", message: "Hey, just following up on the quote" },
        Name: "THE VICTORY WHITE METAL CO",
        NameDba: null,
        Street: "3027 EAST 55TH STREET",
        City: "CLEVELAND",
        State: "OH",
        hdvi: null,
        Zip: "44127-1353",
        BipdEffectiveMonth: "JAN",
        CellNumber: 2165424488,
        TelNumber: 2165424488,
        CompanyRep1: "JAMES BRASDOVICH",
        SafetyReviewRating: null,
        IsHazmatCarrier: false,
        IsHouseHoldGoods: false,
        TotalTrailers: 0,
        TotalPowerUnits: 1,
        TotalApplicableCrashes: 0,
        TruckCategory: "1-5",
        GeneralFreight: true,
        Household: false,
        Metalsheet: false,
        MotorVehicle: false,
        DriveTow: false,
        LogPole: false,
        BuildingMaterial: false,
        MobileHome: false,
        LargeMachine: false,
        Produce: false,
        LiquidGas: false,
        BrokerAuthorityStartDate: new Date("2021-07-01"),
        ContractAuthorityStartDate: new Date("2021-07-01"),
        CommonAuthorityStartDate: new Date("2021-07-01"),
        Intermodal: false,
        Passengers: false,
        OilField: false,
        Livestock: false,
        GrainFeed: false,
        CoalCoke: false,
        Meat: false,
        Garbage: false,
        UsMail: false,
        Chemical: false,
        DryBulk: false,
        ColdFood: false,
        Beverages: false,
        PaperProducts: false,
        Utility: false,
        FarmSupplies: false,
        Construction: false,
        Waterwell: false,
        CargoOther: false,
        OtherCargo: null,
        BipdPrimaryInsuranceCompany: "Canal Insurance",
        BipdEffectiveDate: null,
        BipdCancelEffectiveDate: null,
        BipdPolicyNumber: null,
        EmailAddress: "DCALDWELL@VWMC.COM",
        TotalDrivers: 2,
        TotalCdlDrivers: 2,
        Alerts: 0,
        IssGroupNumber: 15,
        Longitude: -81.621,
        Latitude: 41.398,
    },
    {
        DotNumber: 1163,
        AccountId: "1",
        Status: "Lead",
        LastCall: { date: "2021-07-01T00:00:00.000Z", comments: "Left voicemail" },
        LastEmail: { date: "2021-07-01T00:00:00.000Z", subject: "Re: Quote Request" },
        LastText: { date: "2021-07-01T00:00:00.000Z", message: "Hey, just following up on the quote" },
        Name: "WALLOVER OIL COMPANY INCORPORATED",
        NameDba: null,
        Street: "21845 DRAKE ROAD",
        City: "STRONGSVILLE",
        State: "OH",
        Zip: "44149",
        hdvi: null,
        CellNumber: 2165424488,
        TelNumber: 2165424488,
        CompanyRep1: "JAMES BRASDOVICH",
        SafetyReviewRating: "Satisfactory",
        IsHazmatCarrier: true,
        BrokerAuthorityStartDate: new Date("2021-07-01"),
        ContractAuthorityStartDate: new Date("2021-07-01"),
        CommonAuthorityStartDate: new Date("2021-07-01"),
        IsHouseHoldGoods: false,
        TotalTrailers: 2,
        BipdEffectiveMonth: "JAN",
        TotalPowerUnits: 6,
        TruckCategory: "1-5",
        TotalApplicableCrashes: 0,
        GeneralFreight: false,
        Household: false,
        Metalsheet: false,
        MotorVehicle: false,
        DriveTow: false,
        LogPole: false,
        BuildingMaterial: false,
        MobileHome: false,
        LargeMachine: false,
        Produce: false,
        LiquidGas: true,
        Intermodal: false,
        Passengers: false,
        OilField: false,
        Livestock: false,
        GrainFeed: false,
        CoalCoke: false,
        Meat: false,
        Garbage: false,
        UsMail: false,
        Chemical: false,
        DryBulk: false,
        ColdFood: false,
        Beverages: false,
        PaperProducts: false,
        Utility: false,
        FarmSupplies: false,
        Construction: false,
        Waterwell: false,
        CargoOther: true,
        OtherCargo: "LUBRICANTS",
        BipdPrimaryInsuranceCompany: null,
        BipdEffectiveDate: null,
        BipdCancelEffectiveDate: null,
        BipdPolicyNumber: null,
        EmailAddress: "MARK@TRUCKINGCONSULTANT.COM",
        TotalDrivers: 2,
        TotalCdlDrivers: 2,
        Alerts: 0,
        IssGroupNumber: 15,
        Longitude: -81.621,
        Latitude: 41.398,
    },
    {
        DotNumber: 1174,
        AccountId: "1",
        Status: "Lead",
        LastCall: { date: "2021-07-01T00:00:00.000Z", comments: "Left voicemail" },
        LastEmail: { date: "2021-07-01T00:00:00.000Z", subject: "Re: Quote Request" },
        LastText: { date: "2021-07-01T00:00:00.000Z", message: "Hey, just following up on the quote" },
        Name: "THE WHITACRE ENGINEERING CO",
        NameDba: "WHITACRE REBAR",
        Street: null,
        City: null,
        State: "OH",
        hdvi: null,
        Zip: null,
        CellNumber: 2165424488,
        TelNumber: 2165424488,
        CompanyRep1: "JAMES BRASDOVICH",
        SafetyReviewRating: "Satisfactory",
        IsHazmatCarrier: false,
        IsHouseHoldGoods: false,
        TotalTrailers: 25,
        TotalPowerUnits: 5,
        GeneralFreight: false,
        BipdEffectiveMonth: "JAN",
        TotalApplicableCrashes: 0,
        BrokerAuthorityStartDate: new Date("2021-07-01"),
        ContractAuthorityStartDate: new Date("2021-07-01"),
        CommonAuthorityStartDate: new Date("2021-07-01"),
        Household: false,
        Metalsheet: true,
        MotorVehicle: false,
        DriveTow: false,
        TruckCategory: "1-5",
        LogPole: false,
        BuildingMaterial: true,
        MobileHome: false,
        LargeMachine: false,
        Produce: false,
        LiquidGas: false,
        Intermodal: false,
        Passengers: false,
        OilField: false,
        Livestock: false,
        GrainFeed: false,
        CoalCoke: false,
        Meat: false,
        Garbage: false,
        UsMail: false,
        Chemical: false,
        DryBulk: false,
        ColdFood: false,
        Beverages: false,
        PaperProducts: false,
        Utility: false,
        FarmSupplies: false,
        Construction: false,
        Waterwell: false,
        CargoOther: false,
        OtherCargo: null,
        BipdPrimaryInsuranceCompany: null,
        BipdEffectiveDate: null,
        BipdCancelEffectiveDate: null,
        BipdPolicyNumber: null,
        EmailAddress: "cbyer@wrebar.com",
        TotalDrivers: 1,
        TotalCdlDrivers: 1,
        Alerts: 0,
        IssGroupNumber: 15,
        Longitude: -81.621,
        Latitude: 41.398,
    },
];
