import { CustomerContact, Phone } from "./Contact";
import { CoverageOption } from "./CoverageOption";
import { User } from "./User";

export class Customer {
    id?: number;
    naic?: string;
    name?: string;
    type: "MGA" | "Writing";
    addresses?: CustomerAddress[];
    phones?: Phone[];
    agentId?: string;
    agent?: User;
    "appetiteUnits1-3"?: UnitAppetite;
    "appetiteUnits4-9"?: UnitAppetite;
    "appetiteUnits10-25"?: UnitAppetite;
    "appetiteUnits26-50"?: UnitAppetite;
    "appetiteUnits51-75"?: UnitAppetite;
    "appetiteUnits76-100"?: UnitAppetite;
    "appetiteUnits100+"?: UnitAppetite;
    appetiteStates?: string[];
    appetiteRadius?: RadiusAppetite[];
    appetiteDriverRetention?: number;
    appetiteMotorCarrierQuality?: "Clean" | "Fuzzy" | "Bad";
    coverages?: CoverageOption["id"][];
    writers?: CustomerRelation[];
    contacts?: CustomerContact[];
}

export type CustomerRelation = {
    parentCustomerId: number;
    childCustomerId: number;
};

type RadiusAppetite = "Short" | "Regional" | "Long";
export const radiusAppetite: RadiusAppetite[] = ["Short", "Regional", "Long"];

export type UnitAppetite = "Focused" | "Not Focused" | "Unlikely";
export const unitAppetite: UnitAppetite[] = ["Focused", "Not Focused", "Unlikely"];

type MCQualityAppetite = "Clean" | "Fuzzy" | "Bad";
export const mcQualityAppetite: MCQualityAppetite[] = ["Clean", "Fuzzy", "Bad"];

export type CustomerAddress = {
    id?: number;
    description: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    country?: string;
    zipCode?: string;
    isPrimaryAddress: boolean;
};

export type NewCustomer = Omit<Customer, "contacts">;

/* This is AMS typing that is used for PolicyBoard */

export class AMSCustomer {
    static Code = {
        Navigator: "!GP",
        Acuity: {
            Parent: "!!7",
            Writing: "!!9",
        },
        Northland: {
            Parent_CRC: "!MU",
            Parent_RT_Specialty: "!G$",
            Parent_JM_Wilson: "!G:",
            Parent_Truckers: "!H-",
            Parent_Erickson_Larsen: "!K\\",
        },
        AMWins: "!F)",
        CentralMutual: "!KD",
        GreatAmerican: "!7S",
        GreatAmericanInsuranceCompany: "!7T",
        GreatWest: "!H;",
        Lancer: {
            Parent_Sunfront: '!H"',
            Parent_Transcom: "!L9",
        },
        NationalInterstate: "!/0",
        Travelers: "!3F",
        Logistiq: "!GE",
    } as const;

    id: string;
    code: string;
    name?: string;
    type: AMSCustomer.Type;
    naic?: string;
    parentCompanyCode?: string;
    addresses: AMSCustomer.Address[];
}

export namespace AMSCustomer {
    export enum Type {
        BROKER = "Broker",
        WRITING = "Writing",
        INSURANCE = "Insurance",
        SUBSCRIPTION = "Subscription",
        UNKNOWN = "Unknown",
    }

    export class Address {
        id: string;
        description: string;
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        country?: string;
        zipCode?: string;
        areaCode?: string;
        phone?: string;
        extensions?: string;
        email?: string;
        isPrimaryAddress: boolean;
    }
}
