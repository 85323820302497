import { MotorCarrier } from "@deathstar/motor-carrier";
import { classNames, DataTable } from "@deathstar/ui";
import { Table as ReactTable } from "@tanstack/react-table";
import { animated, useSpring } from "react-spring";
import DataQIcon from "../../assets/icons/dataq-icon.png";
import { PrintableTable } from "../Components/PrintableTable";
import { PrintSectionName } from "../Components/PrintSectionName";
import { Section } from "../Components/Section";
import { TitleGroup } from "../Components/TitleGroup";
import { Map as InspectionMap, type MapController } from "../Map/Map";
import { getTotalMinutesOfInspection } from "../utils/getTotalMinutesOfInspection";
import Columns from "./Columns";
import { hideableColumns } from "./hideableColumns";

const AnimatedDataTable = animated(DataTable<MotorCarrier.IInspection>);

function Table(options: {
    data: MotorCarrier.IInspection[];
    table: ReactTable<MotorCarrier.IInspection>;
    resetTableFilter: () => void;
}): JSX.Element {
    return (
        <>
            <WebTable {...options} className="print:hidden" />
            <PrintTable {...options} className="hidden print:block" />
        </>
    );
}

function WebTable({
    data,
    table,
    resetTableFilter,
    className,
}: {
    data: MotorCarrier.IInspection[];
    table: ReactTable<MotorCarrier.IInspection>;
    resetTableFilter: () => void;
    className?: string;
}): JSX.Element {
    const spring = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        pause: !data,
    });

    return (
        <div className={classNames("flex flex-col gap-2 overflow-auto p-1", className)}>
            <AnimatedDataTable
                style={spring}
                table={table}
                hideableColumns={hideableColumns}
                onClearFilters={resetTableFilter}
                onRowClick={(insp) => {
                    table.resetColumnFilters();
                    table.resetPagination();
                    table.setColumnFilters((columnFilters) => {
                        if (columnFilters.map((f) => f.id).includes(Columns.reportNumber.id as string)) {
                            columnFilters = columnFilters.map((f) => {
                                if (f.id === Columns.reportNumber.id) {
                                    return {
                                        id: Columns.reportNumber.id as string,
                                        value: insp.get("ReportNumber"),
                                    };
                                }
                                if (f.id === Columns.latitude.id) {
                                    return {
                                        id: Columns.latitude.id as string,
                                        value: insp.get("CountyLatitude"),
                                    };
                                }
                                return f;
                            });
                        } else {
                            columnFilters.push(
                                {
                                    id: Columns.reportNumber.id as string,
                                    value: insp.get("ReportNumber"),
                                },
                                {
                                    id: Columns.latitude.id as string,
                                    value: insp.get("CountyLatitude"),
                                }
                            );
                        }
                        return columnFilters;
                    });
                }}
            />
        </div>
    );
}

function getPrintIcons(insp: MotorCarrier.IInspection): JSX.Element | string {
    const icons: { src: string; key: string }[] = [];

    if (!insp.isValid) {
        icons.push({ src: DataQIcon, key: "DataQ Icon" });
    }
    if (insp.isClean) {
        icons.push({ src: "clean", key: "clean" });
    }
    if (insp.isOutOfService) {
        icons.push({ src: "oos", key: "oos" });
    }

    return !icons.length ? (
        ""
    ) : (
        <div className="flex items-center">
            {icons.map((ic) => {
                if (ic.src === "clean") {
                    return (
                        <div className="flex justify-center rounded-xl bg-green-500 px-2 text-white">
                            <span>CLEAN</span>
                        </div>
                    );
                }

                if (ic.src === "oos") {
                    return (
                        <div className="flex justify-center rounded-xl bg-danger px-2 text-white">
                            <span>OOS</span>
                        </div>
                    );
                }

                return <img className="h-4 w-4" src={ic.src} key={ic.key} alt="" />;
            })}
        </div>
    );
}

function PrintTable({ data, className }: { data: MotorCarrier.IInspection[]; className?: string }): JSX.Element {
    return (
        <div className={className}>
            <PrintableTable
                columnHeaders={["_icons", "date", "st", "vin", "vin (trailer)", "location", "minutes"]}
                data={data.map((insp) => {
                    return {
                        key: insp.id,
                        _icons: getPrintIcons(insp),
                        date: insp.date.format("mm/dd/YY"),
                        st: insp.get("ReportState"),
                        vin: insp.get("UnitVin").slice(-4),
                        "vin (trailer)": insp.get("Unit2Vin")?.slice(-4) || "",
                        location: insp.get("LocationDescription") || "",
                        minutes: getTotalMinutesOfInspection(insp),
                    };
                })}
            />
        </div>
    );
}

function Map({ mapController, table }: { mapController: MapController; table: ReactTable<MotorCarrier.IInspection> }): JSX.Element {
    const tableState = table.getState();
    return (
        <InspectionMap
            className="print:max-h-[450px]"
            mapController={mapController}
            selectedLatitude={parseFloat(
                tableState.globalFilter || (tableState.columnFilters.find((f) => f.id === Columns.latitude.id)?.value as string)
            )}
            options={{
                getStateColor: (_state) => "#0c324d",
                getStateLabel: (state) => state.abbr,
            }}
        />
    );
}

export function Inspections({
    mapController,
    table,
    data,
    resetTableFilter,
}: {
    mapController: MapController;
    table: ReactTable<MotorCarrier.IInspection>;
    data: MotorCarrier.IInspection[];
    resetTableFilter: () => void;
}): JSX.Element {
    return (
        <div className="page relative">
            <PrintSectionName name="Inspections" />

            <Section data-page-break="after">
                <TitleGroup title="Inspections" description="All inspections within the report range." />
                <Map mapController={mapController} table={table} />
                <Table data={data} table={table} resetTableFilter={resetTableFilter} />
            </Section>
        </div>
    );
}
