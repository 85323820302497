import { Coverage, Driver } from "@deathstar/types/northstar";
import { DriverDto } from "@deathstar/types/waypoint";
import { Checkbox, classNames, InputField, PrimaryButton, useSnackbar } from "@deathstar/ui";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import { CircularProgress } from "@material-ui/core";
import { debounce } from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";
import { Dialog, DialogProps } from "../../components/dialog/Dialog";
import CoverageSelector from "../equipment/CoverageSelector";

export function NewDriverDialog({
    open,
    onClose,
    dialogProps,
    defaultValues,
    editingId,
    coverageFilter,
    getExistingDriver,
}: {
    open: boolean;
    onClose(): void;
    dialogProps?: Partial<Omit<DialogProps, "open" | "onClose" | "children">>;
    defaultValues?: Partial<DriverDto>;
    editingId?: number;
    coverageFilter?: (coverage: Coverage) => boolean;
    /**
     * This is used to check if the License entered matches any existing driver. We cannot just query Blazar for this since
     * the context matters (i.e. if this is used on the Monthly Report, the coverages on the driver are different than the coverages
     * that would be returned from just querying the current active drivers list).
     *
     * If a match is found, it is assumed that the user wants to edit the existing driver, and the form is reset with the existing
     * driver's data.
     *
     * If no match is found, a query is made to Blazar to see if the license matches any non-active driver on the account. Then,
     * if a match is found, the form is reset with the existing driver's data.
     */
    getExistingDriver?: (vin: string) => Driver | undefined;
}) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            {...dialogProps}
            className={classNames("w-screen p-4 md:max-w-[1080px]", dialogProps?.className)}
        >
            <NewDriverForm
                onClose={onClose}
                defaultValues={defaultValues}
                editingId={editingId}
                coverageFilter={coverageFilter}
                getExistingDriver={getExistingDriver}
            />
        </Dialog>
    );
}

function NewDriverForm({
    onClose,
    defaultValues,
    editingId,
    coverageFilter,
    getExistingDriver,
}: {
    onClose(): void;
    defaultValues?: Partial<DriverDto>;
    editingId?: number;
    coverageFilter?: (coverage: Coverage) => boolean;
    getExistingDriver?: (vin: string) => Driver | undefined;
}) {
    const accountId = useAccountId();
    const create = api.drivers.useCreate(accountId!, {
        onSuccess: () => {
            onClose();
        },
        onError: (error) => {
            console.error(error);
            if (error.status === 403) {
                useSnackbar.add("You do not have permission to manage drivers.", { variant: "error" });
            } else {
                useSnackbar.add("Could not add driver. Please try again later.", { variant: "error" });
            }
        },
    });
    const update = api.drivers.useUpdate(accountId!, {
        onSuccess: () => {
            onClose();
        },
        onError: (error) => {
            console.error(error);
            if (error.status === 403) {
                useSnackbar.add("You do not have permission to manage drivers.", { variant: "error" });
            } else {
                useSnackbar.add("Could not update driver. Please try again later.", { variant: "error" });
            }
        },
    });

    const form = useForm<DriverDto>({
        defaultValues: {
            coverages: [],
            ...defaultValues,
        },
    });

    const license = form.watch("license");
    const [existingDriverId, setExistingDriverId] = useState<number | null>(null);
    const isEditing = !!editingId || !!existingDriverId;

    const checkExistingDriver = useMemo(
        () =>
            debounce((license: string) => {
                const driver = getExistingDriver!(license); // getExistingDriver is required if editingId is not provided, this will crash otherwise
                if (driver) {
                    setExistingDriverId(driver.id);
                    useSnackbar.add("This driver already exists - switched to edit mode", {
                        variant: "info",
                    });
                    form.reset({
                        coverages: driver.coverages,
                        name: driver.name ?? undefined,
                        state: driver.state ?? undefined,
                        license: driver.license ?? undefined,
                        dob: driver.dob ?? undefined,
                        doh: driver.policyDoh ?? undefined,
                        exp: driver.policyExp ?? undefined,
                        ownerOperator: driver.policyOwnerOperator ?? undefined,
                    });
                } else {
                    api.drivers.findByLicense(accountId!, license).then((driver) => {
                        if (driver) {
                            useSnackbar.add("This driver already exists - switched to edit mode", {
                                variant: "info",
                            });
                            setExistingDriverId(driver.id!);
                            form.reset({
                                coverages: [],
                                name: driver.name ?? undefined,
                                state: driver.state ?? undefined,
                                license: driver.license ?? undefined,
                                dob: driver.dob ?? undefined,
                                doh: driver.doh ?? undefined,
                                exp: driver.exp ?? undefined,
                                ownerOperator: driver.policyOwnerOperator ?? undefined,
                            });
                        }
                    });
                }
            }, 300),
        [accountId, form, getExistingDriver]
    );

    useEffect(() => {
        if (!editingId && license && license.length > 4 && !existingDriverId) {
            checkExistingDriver(license);
        }
    }, [accountId, checkExistingDriver, editingId, existingDriverId, form, license]);

    return (
        <form
            onSubmit={form.handleSubmit((data) => {
                if (isEditing) {
                    if (!update.isPending) {
                        const dirtyFields = Object.fromEntries(
                            Object.entries(data).filter(
                                ([key]) =>
                                    // @ts-expect-error a bit of a hack
                                    form.formState.dirtyFields[key]
                            )
                        );
                        update.mutate({
                            id: editingId || existingDriverId!,
                            ...dirtyFields,
                        });
                    }
                } else {
                    if (!create.isPending) {
                        create.mutate(data);
                    }
                }
            })}
            className="w-full space-y-4 overflow-auto text-sm"
        >
            <FormProvider {...form}>
                <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
                    <div className="col-span-2">
                        <div>
                            <p className="text-lg font-medium text-waypoint-blue-dark">Details</p>
                            <div className="grid grid-cols-12 gap-x-4 gap-y-2">
                                <InputField
                                    className="col-span-8 md:col-span-4"
                                    classes={{ inputContainer: "py-2", input: "disabled:opacity-50" }}
                                    required
                                    label="License #"
                                    {...form.register("license", { required: "Please enter a license" })}
                                    disabled={isEditing}
                                    placeholder="A123456789"
                                />
                                <InputField
                                    className="col-span-8 md:col-span-5"
                                    classes={{ inputContainer: "py-2", input: "disabled:opacity-50" }}
                                    required
                                    label="Name"
                                    {...form.register("name", { required: "Please enter a name" })}
                                    placeholder="John Doe"
                                />
                                <InputField
                                    className="col-span-4 md:col-span-4"
                                    classes={{ inputContainer: "py-2", input: "disabled:opacity-50" }}
                                    required
                                    label="State"
                                    {...form.register("state", {
                                        required: "Please enter a state",
                                        validate: (value) => (value && value.length === 2) || "Please enter a 2-letter state code",
                                    })}
                                    placeholder="MI"
                                />
                                <InputField
                                    className="col-span-6 md:col-span-4"
                                    classes={{ inputContainer: "py-2", input: "disabled:opacity-50" }}
                                    required
                                    label="Date of birth"
                                    {...form.register("dob", { required: "Please enter a date of birth" })}
                                    type="date"
                                />
                                <InputField
                                    className="col-span-6 md:col-span-4"
                                    classes={{ inputContainer: "py-2", input: "disabled:opacity-50" }}
                                    required
                                    label="Date hired"
                                    {...form.register("doh")}
                                    type="date"
                                />
                                <InputField
                                    className="col-span-6 md:col-span-4"
                                    classes={{ inputContainer: "py-2", input: "disabled:opacity-50" }}
                                    label="Years experience"
                                    required
                                    {...form.register("exp", { setValueAs: (value) => (value ? parseInt(value) : null) })}
                                    type="number"
                                    min={0}
                                />

                                <div className="col-span-full space-y-2">
                                    <Checkbox
                                        classes={{ input: "w-4 h-4", root: "text-sm" }}
                                        label="Independent contractor"
                                        {...form.register("ownerOperator")}
                                    />
                                    {!isEditing && (
                                        <Checkbox
                                            classes={{ input: "w-4 h-4" }}
                                            required
                                            label={
                                                <span>
                                                    I confirm this driver meets my insurance company's driver guidelines
                                                    <span className="text-sm text-red-600"> *</span>
                                                </span>
                                            }
                                            {...form.register("wpUserConfirmationMeetsGuidelines", { required: true })}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-span-1">
                        <p className="text-lg font-medium text-waypoint-blue-dark">Coverage</p>
                        <CoverageSelector dataType={"drivers"} isEditing={isEditing} coverageFilter={coverageFilter} />
                    </div>
                </div>
            </FormProvider>

            <div className="flex items-end justify-between pt-4">
                <div>
                    <InputField
                        type="date"
                        label={isEditing ? "Make changes effective on" : "Add to coverage on"}
                        classes={{ inputContainer: "py-2", input: "disabled:opacity-50" }}
                        {...form.register("atDate", {
                            required: "Please select a date",
                            validate: (value) => {
                                const m = moment(value, "YYYY-MM-DD", true);
                                if (!m.isValid()) {
                                    return "Invalid date";
                                }
                                if (m.isAfter(moment(), "day")) {
                                    return "Date cannot be in the future";
                                }

                                if (moment().date() >= 15 && m.isBefore(moment(), "month")) {
                                    return "Date is too far in the past";
                                }

                                if (moment().date() < 15 && m.isBefore(moment().subtract(1, "month"), "month")) {
                                    return "Date is too far in the past";
                                }

                                return true;
                            },
                        })}
                    />
                </div>
                {isEditing ? (
                    <PrimaryButton type="submit" disabled={update.isPending || !form.formState.isDirty} className="w-32">
                        {update.isPending ? (
                            <>
                                <span>Loading</span>
                                <CircularProgress size="1rem" classes={{ circle: "text-white" }} />
                            </>
                        ) : (
                            <>
                                <span>Update</span>
                                <ArrowRightCircleIcon className="h-4 w-4" />
                            </>
                        )}
                    </PrimaryButton>
                ) : (
                    <PrimaryButton
                        type="submit"
                        disabled={create.isPending || !form.watch("wpUserConfirmationMeetsGuidelines")}
                        className="w-32"
                    >
                        {create.isPending ? (
                            <>
                                <span>Loading</span>
                                <CircularProgress size="1rem" classes={{ circle: "text-white" }} />
                            </>
                        ) : (
                            <>
                                <span>Create</span>
                                <ArrowRightCircleIcon className="h-4 w-4" />
                            </>
                        )}
                    </PrimaryButton>
                )}
            </div>
            {Object.values(form.formState.errors).length > 0 && (
                <p className="text-red-600">
                    {Object.values(form.formState.errors)
                        .map((error) => error.message)
                        .join("; ")}
                </p>
            )}
        </form>
    );
}
