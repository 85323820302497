import { InputField, Popover, useSnackbar } from "@deathstar/ui";
import {
    ArrowDownTrayIcon,
    MagnifyingGlassCircleIcon,
    PaperAirplaneIcon,
    QuestionMarkCircleIcon,
    UserIcon,
} from "@heroicons/react/24/outline";
import { matchSorter } from "match-sorter";
import moment from "moment";
import { useMemo, useState } from "react";
import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";
import { UnauthenticatedError, UnauthorizedError } from "../../api/util/exceptions";
import { Dialog } from "../../components/dialog/Dialog";

export default function CertificateHistoryDialog({
    open,
    onClose,
    certificateId,
}: {
    open: boolean;
    onClose(): void;
    certificateId: number;
}) {
    const accountId = useAccountId();
    const { data } = api.certificates.useHistory(accountId!, certificateId);
    const [search, setSearch] = useState("");
    const filtered = useMemo(
        () => (search ? matchSorter(data || [], search, { keys: ["originalHolderName", "emailTo"] }) : data || []),
        [data, search]
    );
    return (
        <Dialog open={open} onClose={onClose}>
            <div className="overflow-auto px-12 py-4 text-sm">
                <div className="mb-2 flex items-center justify-between gap-4">
                    <InputField
                        startAdornment={<MagnifyingGlassCircleIcon className="h-5 w-5 text-stone-400" />}
                        classes={{ inputContainer: "!bg-stone-100 py-2", input: "placeholder:text-stone-400", root: "w-full" }}
                        placeholder="Search..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <Popover className="flex flex-col items-center">
                        <Popover.Button>
                            <QuestionMarkCircleIcon className="h-5 w-5 text-stone-500 transition-colors hover:text-stone-700" />
                        </Popover.Button>
                        <Popover.Content>
                            <div className="max-w-prose p-4 text-sm font-light text-stone-700">
                                <p>
                                    This certificate's send and download history is shown below. Due to the complexities of email logistics,{" "}
                                    <span className="font-medium">undelivered emails</span> - e.g. due to incorrect email addresses or
                                    outages in email services - <span className="font-medium">are not tracked or marked</span> in this data.
                                </p>
                            </div>
                        </Popover.Content>
                    </Popover>
                </div>
                {filtered.map((r) => (
                    <div
                        key={r.id}
                        className="cursor-pointer space-y-2 border-b border-stone-200 p-2 transition-colors hover:bg-blue-50/50"
                        onClick={async () => {
                            try {
                                const res = await api.certificates.getHistoryPdf(accountId!, certificateId, r.id);
                                const url = URL.createObjectURL(await res.blob());
                                window.open(url, "_blank");
                            } catch (e) {
                                if (e instanceof UnauthenticatedError || e instanceof UnauthorizedError) {
                                    useSnackbar.add("You do not have permission to do this.", { variant: "error" });
                                } else {
                                    useSnackbar.add("Could not open certificate. Please try again later.", { variant: "error" });
                                }
                            }
                        }}
                    >
                        <div className="flex items-center justify-between gap-8">
                            <div className="text-sm font-semibold">{r.originalHolderName}</div>
                            <div className="text-xs tabular-nums text-stone-500">{moment(r.createDate).format("MM/DD/YY hh:mm a")}</div>
                        </div>
                        <div className="text-xs font-light text-stone-700">
                            <div className="flex items-center gap-1">
                                <UserIcon className="h-3 w-3 text-waypoint-blue" />
                                <span>{r.createdByNorthstarUser ? "Navigator" : r.createdByWaypointUser?.displayName || "[Unknown]"}</span>
                            </div>
                            {r.emailTo ? (
                                <div className="flex items-center gap-1">
                                    <PaperAirplaneIcon className="h-3 w-3 text-waypoint-orange" />
                                    <span>
                                        Sent to <span className="tracking-wide">{r.emailTo}</span>
                                    </span>
                                </div>
                            ) : (
                                <div className="flex items-center gap-1">
                                    <ArrowDownTrayIcon className="h-3 w-3 text-waypoint-orange" />
                                    <span>Downloaded</span>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
                {filtered.length === 0 && (
                    <div className="py-4 text-center text-stone-500">
                        <p>Nothing was found</p>
                    </div>
                )}
            </div>
        </Dialog>
    );
}
