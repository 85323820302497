import { BlazarError } from "@deathstar/types/blazar";
import { Account } from "@deathstar/types/northstar";
import { BillingInformation, getSubscriptionTierName, Subscription, SubscriptionTier } from "@deathstar/types/waypoint";
import { CheckCircleIcon, PlusIcon } from "@heroicons/react/24/outline";
import { PencilIcon } from "@heroicons/react/24/solid";
import { CircularProgress, Dialog, MenuItem, Select, Tooltip } from "@material-ui/core";
import { UseMutationResult } from "@tanstack/react-query";
import { capitalize } from "lodash";
import { forwardRef, useState } from "react";
import { ActionButton } from "../actionButton/ActionButton";
import { BillingInfoFormData, BillingProfileForm } from "../billingProfileForm/billingProfileForm";
import { useSnackbar } from "../hooks/useSnackbar";

export interface IOptions {
    account: Account;
    billingInfo: BillingInformation;
    subscription: Subscription;
    createCustomer: UseMutationResult<BillingInformation, BlazarError<unknown>, void, unknown>;
    stripeDashboardLink: string;
    isLoading: boolean;
    isError: boolean;
    onSubmitBillingProfileChange: (data: BillingInfoFormData) => Promise<void>;
    onAutoMaticPaymentsChange: (value: boolean) => Promise<void>;
    onWaypointsSubscriptionChange: (tier: SubscriptionTier) => Promise<void>;
}

export function Settings({
    account,
    isLoading,
    isError,
    billingInfo,
    subscription,
    createCustomer,
    stripeDashboardLink,
    onSubmitBillingProfileChange,
    onAutoMaticPaymentsChange,
    onWaypointsSubscriptionChange,
}: IOptions) {
    const [editing, setEditing] = useState(false);

    return (
        <div className="w-screen max-w-xl space-y-4">
            <div className="flex w-full justify-end gap-4">
                {billingInfo ? (
                    <ActionButton
                        onClick={() => {
                            setEditing(true);
                        }}
                    >
                        <PencilIcon className="h-4 w-4 shrink-0 grow-0" />
                        Edit profile
                    </ActionButton>
                ) : (
                    <ActionButton
                        onClick={async () => {
                            createCustomer.mutate();
                        }}
                    >
                        Create profile
                    </ActionButton>
                )}
                <ActionButton
                    onClick={async () => {
                        const link = `${stripeDashboardLink}/customers/${billingInfo?.customerId}`;
                        window.open(link, "_blank");
                    }}
                >
                    <PlusIcon className="h-4 w-4 shrink-0 grow-0" />
                    Add payment method
                </ActionButton>
                <Dialog open={editing} onClose={() => setEditing(false)}>
                    <BillingProfileForm
                        profile={billingInfo}
                        onSubmit={async (data: BillingInfoFormData) => {
                            await onSubmitBillingProfileChange(data);
                            setEditing(false);
                        }}
                    />
                </Dialog>
            </div>
            <div className="ml-4">
                {isLoading || createCustomer.isPending ? (
                    <div className="flex w-full items-center justify-center">
                        <CircularProgress />
                    </div>
                ) : isError ? (
                    <div>
                        <p className="text-red-600">An error occurred while loading</p>
                    </div>
                ) : billingInfo ? (
                    <div>
                        <table className="col-spacing-4 row-spacing-1">
                            <tbody>
                                <tr>
                                    <td className="text-gray-500">Email</td>
                                    <td>
                                        {billingInfo.email ? (
                                            <CopyButton>{billingInfo.email}</CopyButton>
                                        ) : (
                                            <span className="red-text-700">Email is missing</span>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-gray-500">Address</td>
                                    <td>
                                        {billingInfo.address ? (
                                            <CopyButton>
                                                {`${billingInfo.address.line1}${
                                                    billingInfo.address.line2 ? ", " + billingInfo.address.line2 : ""
                                                }, ${billingInfo.address.city}, ${billingInfo.address.state} ${
                                                    billingInfo.address.postal_code
                                                }`}
                                            </CopyButton>
                                        ) : (
                                            "-"
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-gray-500">Automatic payments enabled</td>
                                    <td>
                                        <Select
                                            value={billingInfo?.automaticBillingEnabled ? "true" : "false"}
                                            onChange={async (e) => {
                                                await onAutoMaticPaymentsChange(e.target.value === "true" ? true : false);
                                            }}
                                            variant="outlined"
                                            classes={{ root: "py-1" }}
                                            fullWidth
                                            displayEmpty
                                        >
                                            <MenuItem value="true">Yes</MenuItem>
                                            <MenuItem value="false">No</MenuItem>
                                        </Select>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-gray-500">Waypoint subscription</td>
                                    <td>
                                        <Select
                                            value={account.activeSubscriptionTier || ""}
                                            disabled={!!subscription}
                                            onChange={async (e) => {
                                                await onWaypointsSubscriptionChange((e.target.value as SubscriptionTier) || null);
                                            }}
                                            variant="outlined"
                                            classes={{ root: "py-1" }}
                                            fullWidth
                                            displayEmpty
                                        >
                                            <MenuItem value={""}>Not subscribed</MenuItem>
                                            <MenuItem value={SubscriptionTier.Standard}>
                                                {getSubscriptionTierName(SubscriptionTier.Standard)}
                                            </MenuItem>
                                            <MenuItem value={SubscriptionTier.Premium}>
                                                {getSubscriptionTierName(SubscriptionTier.Premium)}
                                            </MenuItem>
                                            <MenuItem value={SubscriptionTier.Enterprise}>
                                                {getSubscriptionTierName(SubscriptionTier.Enterprise)}
                                            </MenuItem>
                                        </Select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mt-4 w-max min-w-[300px] space-y-2">
                            <p className="text-base font-medium text-gray-500">Payment methods</p>

                            <table className="ml-4 space-y-4 col-spacing-2 row-spacing-1">
                                <tbody>
                                    {billingInfo.paymentMethods.length ? (
                                        billingInfo.paymentMethods.map((paymentMethod) => (
                                            <tr key={paymentMethod.id}>
                                                <td>
                                                    {paymentMethod.isDefault && (
                                                        <Tooltip title="Default payment method">
                                                            <CheckCircleIcon className="h-4 w-4 text-blue-600" />
                                                        </Tooltip>
                                                    )}
                                                </td>
                                                <td>{capitalize(paymentMethod.description)}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td>
                                                <p className="text-center text-orange-700">No payment methods were found</p>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ) : (
                    <div>
                        <p>No billing information found</p>
                    </div>
                )}
            </div>
        </div>
    );
}

const CopyButton = forwardRef<HTMLButtonElement, { children: string }>(({ children }, ref) => {
    return (
        <Tooltip title="Click to copy">
            <button
                className="text-left"
                ref={ref}
                onClick={() => {
                    navigator.clipboard
                        .writeText(children)
                        .then(() => {
                            useSnackbar.add("Copied!", { variant: "success" });
                        })
                        .catch(() => {
                            useSnackbar.add("Could not copy. Please try again.", {
                                variant: "warning",
                            });
                        });
                }}
            >
                {children}
            </button>
        </Tooltip>
    );
});
