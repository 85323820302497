import { Dialog, IconButton, IconButtonProps } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { Suspense, useContext } from "react";
import tw from "twin.macro";
import { PrimaryButton } from '../primaryButton/primaryButton';
import { SecondaryButton } from "../secondaryButton/secondaryButton";

interface ConfirmationDialogProps {
    open: boolean;
    onClose: () => void;
    children: React.ReactChild | React.ReactChild[];
}

const Context = React.createContext<{ open: boolean; onClose(): void }>({open: false, onClose: () => { /**/}});

export function ConfirmationDialog({ open, onClose, children }: ConfirmationDialogProps) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                style: {
                    padding: "1.5em",
                    display: "grid",
                    gap: "2em",
                },
            }}
        >
            <Suspense fallback={<div className="h-full w-full animate-pulse rounded bg-gray-100"></div>}>
                <Context.Provider value={{ open, onClose }}>{children}</Context.Provider>
            </Suspense>
        </Dialog>
    );
}

ConfirmationDialog.Title = (props: React.ClassAttributes<HTMLParagraphElement> & React.HTMLAttributes<HTMLParagraphElement>) => {
    return <p css={[tw`text-xl font-light text-navigator-blue`]} {...props}></p>;
};

ConfirmationDialog.TitleGroup = (props: React.ClassAttributes<HTMLDivElement> & React.HTMLAttributes<HTMLDivElement>) => {
    return <div css={[tw`flex justify-between`]} {...props}></div>;
};

ConfirmationDialog.CloseIconButton = (props: IconButtonProps) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { onClose } = useContext(Context);
    return (
        <IconButton
            onClick={onClose}
            size="small"
            css={[tw`flex-grow-0 flex-shrink-0 text-gray-400`]}
            children={<Close />}
            {...props}
        ></IconButton>
    );
};

ConfirmationDialog.CancelButton = (props: React.ClassAttributes<HTMLButtonElement> & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { onClose } = useContext(Context);
    return (
        <button onClick={onClose} children="Cancel" css={[tw`px-2 py-1 transition-colors rounded hover:bg-gray-100`]} {...props}></button>
    );
};

ConfirmationDialog.PrimaryButton = (
    props: Omit<React.ClassAttributes<HTMLButtonElement> & React.ButtonHTMLAttributes<HTMLButtonElement>, "ref">
) => {
    <button></button>;
    return <PrimaryButton children="Confirm" {...props}></PrimaryButton>;
};

ConfirmationDialog.DangerButton = (
    props: Omit<React.ClassAttributes<HTMLButtonElement> & React.ButtonHTMLAttributes<HTMLButtonElement>, "ref">
) => {
    return <SecondaryButton children="Confirm" css={[tw`text-red-600`]} {...props}></SecondaryButton>;
};

ConfirmationDialog.Description = (props: React.ClassAttributes<HTMLParagraphElement> & React.HTMLAttributes<HTMLParagraphElement>) => {
    return <p css={[tw`text-gray-500`]} {...props}></p>;
};

ConfirmationDialog.ActionGroup = (props: React.ClassAttributes<HTMLDivElement> & React.HTMLAttributes<HTMLDivElement>) => {
    return <div css={[tw`flex justify-end gap-4`]} {...props}></div>;
};
