import { Driver, GlobalCertificateHolder } from "@deathstar/types/northstar";
import { ActionButton, InputField, PrimaryButton, useSnackbar } from "@deathstar/ui";
import { CheckIcon, ChevronLeftIcon, MagnifyingGlassCircleIcon } from "@heroicons/react/24/outline";
import { CircularProgress, Dialog } from "@material-ui/core";
import { isEmail, isPhoneNumber, isPostalCode } from "class-validator";
import { matchSorter } from "match-sorter";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";
import { queryClient } from "../../util/queryClient";
import { stringifyAddress } from "../certificates/certificatesUtil";

export function OwnerOperatorEmployerLinker({
    open,
    currentValue,
    onChange,
    onClose,
    driver,
}: {
    open: boolean;
    currentValue: number | null;
    onChange(
        value: number | Pick<GlobalCertificateHolder, "name" | "street" | "street2" | "city" | "state" | "zip" | "email" | "fax"> | null
    ): unknown;
    onClose(): void;
    driver: Pick<Driver, "name">;
}) {
    const accountId = useAccountId();
    const close = () => {
        form.reset({ name: "", street: "", street2: "", city: "", state: "", zip: "", email: "", fax: "" });
        setCreating(false);
        onClose();
    };
    const { data: currentEmployers } = api.ownerOperatorEmployers.useGetForAccount(accountId!);
    const form = useForm<Pick<GlobalCertificateHolder, "id" | "name" | "street" | "street2" | "city" | "state" | "zip" | "email" | "fax">>({
        defaultValues: {
            id: undefined,
            name: "",
            street: "",
            street2: "",
            city: "",
            state: "",
            zip: "",
        },
    });
    const [creating, setCreating] = useState(false);

    const resetForm = () => {
        form.reset({
            id: undefined,
            name: "",
            street: "",
            street2: "",
            city: "",
            state: "",
            zip: "",
            email: "",
            fax: "",
        });
    };
    const [search, setSearch] = useState("");
    const filtered = useMemo(() => matchSorter(currentEmployers || [], search, { keys: ["name"] }), [currentEmployers, search]);

    return (
        <Dialog open={open} onClose={close} classes={{ paper: "p-8 w-screen max-w-lg" }}>
            {creating ? (
                <form
                    className="mt-2 grid grid-cols-6 gap-4"
                    onSubmit={form.handleSubmit((data) => {
                        if (data.id) {
                            api.ownerOperatorEmployers
                                .updateEmployer(accountId!, data.id, data)
                                .then(() => {
                                    queryClient.invalidateQueries({ queryKey: api.ownerOperatorEmployers.queryKeys.get(accountId!) });
                                    queryClient.invalidateQueries({ queryKey: api.certificates.queryKeys.find(accountId!) });
                                    resetForm();
                                    setCreating(false);
                                })
                                .catch((e) => {
                                    useSnackbar.add(e.message, { variant: "error" });
                                });
                        } else {
                            onChange(data);
                            close();
                        }
                    })}
                >
                    <div className="col-span-6">
                        <ActionButton
                            onClick={() => {
                                resetForm();
                                setCreating(false);
                            }}
                        >
                            <ChevronLeftIcon className="h-4 w-4" />
                            Back
                        </ActionButton>
                    </div>
                    <InputField
                        classes={{ root: "col-span-6" }}
                        label={
                            <div className="flex justify-between">
                                <span>
                                    Employer name
                                    <span className="text-sm text-red-600"> *</span>
                                </span>
                                <button
                                    className="text-blue-600 small-caps hover:text-blue-800"
                                    type="button"
                                    onClick={() => {
                                        form.setValue("name", driver.name || "");
                                    }}
                                >
                                    fill driver name
                                </button>
                            </div>
                        }
                        placeholder="Reliable Drivers Inc."
                        {...form.register("name", { required: true })}
                        autoFocus
                        error={!!form.formState.errors?.name}
                        helperText={form.formState.errors?.name?.message}
                    />
                    <InputField
                        classes={{ root: "col-span-full" }}
                        label="Street"
                        placeholder="100 N. Capitol Ave."
                        {...form.register("street", { required: true })}
                        required
                        error={!!form.formState.errors?.street}
                        helperText={form.formState.errors?.street?.message}
                    />
                    <InputField
                        classes={{ root: "col-span-full" }}
                        label="Street 2"
                        placeholder="Suite 200"
                        {...form.register("street2")}
                        error={!!form.formState.errors?.street2}
                        helperText={form.formState.errors?.street2?.message}
                    />
                    <InputField
                        classes={{ root: "col-span-3" }}
                        label="City"
                        placeholder="Lansing"
                        {...form.register("city", { required: true })}
                        required
                        error={!!form.formState.errors?.city}
                        helperText={form.formState.errors?.city?.message}
                    />
                    <InputField
                        classes={{ root: "col-span-3" }}
                        label="State/Province"
                        placeholder="MI"
                        {...form.register("state", {
                            required: true,
                            maxLength: 2,
                        })}
                        required
                        error={!!form.formState.errors?.state}
                        helperText={form.formState.errors?.state?.message}
                    />
                    <InputField
                        classes={{ root: "col-span-3" }}
                        label="Postal code"
                        placeholder="48933"
                        {...form.register("zip", { required: true, validate: (v) => isPostalCode(v, "any") || "Invalid postal code" })}
                        required
                        error={!!form.formState.errors?.zip}
                        helperText={form.formState.errors?.zip?.message}
                    />

                    <InputField
                        label="Email"
                        classes={{ root: "col-span-full" }}
                        {...form.register("email", {
                            validate: (v) => !v || isEmail(v) || "Invalid email",
                        })}
                        placeholder="jsmith@example.com"
                        error={!!form.formState.errors?.email}
                        helperText={form.formState.errors?.email?.message}
                    />

                    <InputField
                        label="Fax"
                        classes={{ root: "col-span-full" }}
                        {...form.register("fax", {
                            onChange: (e) => form.setValue("fax", e.target.value ? e.target.value.replace(/[^\d]/g, "") : e.target.value),
                            validate: (v) => !v || isPhoneNumber(v, "US") || "Invalid fax number",
                        })}
                        placeholder="16165551234"
                        error={!!form.formState.errors?.fax}
                        helperText={form.formState.errors?.fax?.message}
                    />
                    <div className="col-span-full flex justify-center">
                        <PrimaryButton type="submit">{form.watch("id") ? "Update" : "Create"} employer</PrimaryButton>
                    </div>
                </form>
            ) : (
                <>
                    <div className="mb-12 flex flex-col overflow-hidden p-1">
                        <p className="text-sm text-stone-500">Employers</p>
                        <InputField
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            startAdornment={<MagnifyingGlassCircleIcon className="h-4 w-4 text-stone-400" />}
                            classes={{ inputContainer: "py-1", root: "my-1 text-sm", input: "placeholder:text-stone-400" }}
                            placeholder="Search by name..."
                        />
                        <ul className="min-h-36 space-y-1 overflow-y-auto rounded border">
                            {currentEmployers ? (
                                filtered.map((employer) => (
                                    <li key={employer.id}>
                                        <div className="group flex w-full items-center overflow-hidden pr-2 hover:bg-blue-50">
                                            <button
                                                className="flex grow items-center gap-2 overflow-hidden px-2 py-1 text-left"
                                                onClick={() => {
                                                    if (currentValue === employer.id) {
                                                        onChange(null);
                                                    } else {
                                                        onChange(employer.id);
                                                    }
                                                }}
                                            >
                                                <div className="h-5 w-5 shrink-0">
                                                    {employer.id === currentValue ? (
                                                        <CheckIcon className="h-5 w-5 text-navigator-primary" />
                                                    ) : null}
                                                </div>
                                                <div className="flex flex-col">
                                                    <span className="text-navigator-primary-dark">{employer.name}</span>
                                                    <span className="text-sm text-stone-500">{stringifyAddress(employer)}</span>
                                                </div>
                                            </button>
                                            <button
                                                className="rounded border bg-white px-2 text-sm text-stone-600 opacity-0 transition-opacity hover:bg-stone-50 group-hover:opacity-100"
                                                onClick={() => {
                                                    form.reset(employer);
                                                    setCreating(true);
                                                }}
                                            >
                                                edit
                                            </button>
                                        </div>
                                    </li>
                                ))
                            ) : (
                                <li>
                                    <div className="flex w-full items-center justify-center">
                                        <CircularProgress />
                                    </div>
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className="mt-2 py-2">
                        <p className="text-center text-xs text-stone-500">Don't see who you're looking for?</p>
                        <button
                            onClick={() => {
                                setCreating(true);
                            }}
                            className="w-full py-2 text-navigator-primary-light hover:text-navigator-primary-dark"
                        >
                            Create a new employer
                        </button>
                    </div>
                </>
            )}
        </Dialog>
    );
}
