import type { Policy } from "./Policy";

namespace Enums {
    export enum AutoWithdrawalInstallments {
        YES = "Yes",
        NO = "No",
        SAME = "Same",
    }

    export enum DataSource {
        ON_FILE = "On-File",
        LAYER = "Layer",
        WAYPOINT = "Waypoint",
    }
}

export class PolicyAch {
    static AutoWithdrawalInstallments = Enums.AutoWithdrawalInstallments;
    static DataSource = Enums.DataSource;

    static isValid(
        ach: Pick<
            PolicyAch,
            | "dataSource"
            | "lastFourOfAcctNumber"
            | "processDate"
            | "amount"
            | "confirmationContactId"
            | "autoWithdrawalInstallments"
            | "purpose"
        >
    ): boolean {
        const valuesToCheck = Object.entries(ach).filter(([key, _val]) => {
            if (ach.dataSource === PolicyAch.DataSource.WAYPOINT) {
                return key !== "lastFourOfAcctNumber" && key !== "autoWithdrawalInstallments" && key !== "confirmationContactId";
            } else if (ach.dataSource === PolicyAch.DataSource.ON_FILE) {
                return key !== "lastFourOfAcctNumber";
            }
            return true;
        });

        return valuesToCheck.every(([_, v]) => v !== null);
    }

    id: number;
    policyId: string;
    policyCreatedDate: Date;
    policyLayerEffectiveDate: Date;
    policy: Policy;
    dataSource: PolicyAch.DataSource;
    lastFourOfAcctNumber: string;
    processDate: Date;
    amount: number;
    confirmationContactId: number;
    autoWithdrawalInstallments: PolicyAch.AutoWithdrawalInstallments;
    purpose: string;
}

export declare namespace PolicyAch {
    export type AutoWithdrawalInstallments = Enums.AutoWithdrawalInstallments;
    export type DataSource = Enums.DataSource;
}
