import { plainToClass, Type } from "class-transformer";
import { Attribute } from "./Attribute";
import { AttributeOption } from "./AttributeOption";
import { Comparison } from "./Comparison/Comparison";
import { Coverage } from "./Coverage";
import { CoverageOption } from "./CoverageOption";
import { Driver } from "./Driver";
import { EquipmentComparison } from "./PolicyLayer";

export class CoverageLinkedDriver implements EquipmentComparison.IIsComparable {
    #initialized = false;
    static init(options: { coverage?: Coverage; linkedDriver: CoverageLinkedDriver }): CoverageLinkedDriver {
        const { linkedDriver } = options;
        if (linkedDriver instanceof CoverageLinkedDriver && linkedDriver.#initialized) return linkedDriver;

        const e = plainToClass(CoverageLinkedDriver, linkedDriver);
        e.coverage = options.coverage;
        e.attributes = CoverageLinkedDriver.getAttributes(options);
        e.yearsExperience = CoverageLinkedDriver.getYearsExperience(options);
        e.isOwnerOperator = CoverageLinkedDriver.getIsOwnerOperator(options);
        e.dateOfHire = CoverageLinkedDriver.getDateOfHire(options);

        e.#initialized = true;
        return e;
    }

    static getAttributes({ coverage, linkedDriver }: { coverage?: Coverage; linkedDriver: CoverageLinkedDriver }): Attribute[] {
        if (!coverage) return [];
        return coverage.attributes.filter((a) => a.driverId === linkedDriver.driverId);
    }

    static hasAttribute({
        coverage,
        linkedDriver,
        attributeOptionId,
    }: {
        coverage?: Coverage;
        linkedDriver: CoverageLinkedDriver;
        attributeOptionId: AttributeOption.Id;
    }): boolean {
        const attributes = CoverageLinkedDriver.getAttributes({ coverage, linkedDriver });
        return attributes.some((a) => a.attributeOptionId === attributeOptionId);
    }

    static #getAttributes({ coverage, linkedDriver }: { coverage?: Coverage; linkedDriver: CoverageLinkedDriver }): Attribute[] {
        return linkedDriver.attributes || CoverageLinkedDriver.getAttributes({ coverage, linkedDriver });
    }

    static getYearsExperience(options: { coverage?: Coverage; linkedDriver: CoverageLinkedDriver }): number | null {
        const attr = CoverageLinkedDriver.#getAttributes(options).find(
            (a) => a.attributeOptionId === AttributeOption.Id.DRIVER_YEARS_EXPERIENCE
        );
        return attr?.valueNumber || null;
    }

    static getIsOwnerOperator(options: { coverage?: Coverage; linkedDriver: CoverageLinkedDriver }): boolean {
        return CoverageLinkedDriver.hasAttribute({ ...options, attributeOptionId: AttributeOption.Id.UNIT_OWNER_OPERATOR });
    }

    static getDateOfHire(options: { coverage?: Coverage; linkedDriver: CoverageLinkedDriver }): string | null {
        const attr = CoverageLinkedDriver.#getAttributes(options).find((a) => a.attributeOptionId === AttributeOption.Id.DRIVER_HIRE_DATE);
        return attr?.valueText || null;
    }

    static compare({
        base,
        compare,
        comparisonLayer,
        baseLayer,
    }: Comparison.IOptions<CoverageLinkedDriver>): Map<CoverageOption.Id, CoverageLinkedDriver.IComparisonReturn> {
        const map = new Map<CoverageOption.Id, CoverageLinkedDriver.IComparisonReturn>();

        if (compare) {
            for (const [cvgOptId, linkedTractor] of Object.entries(compare) as unknown as [CoverageOption.Id, CoverageLinkedDriver][]) {
                const from = base?.[cvgOptId];
                const to = linkedTractor;
                map.set(cvgOptId, CoverageLinkedDriver.#compare({ base: from, compare: to, comparisonLayer, baseLayer }));
            }
        }

        if (base) {
            for (const [cvgOptId, linkedTractor] of Object.entries(base) as unknown as [CoverageOption.Id, CoverageLinkedDriver][]) {
                if (map.has(cvgOptId)) continue;
                const from = linkedTractor;
                const to = compare?.[cvgOptId];
                map.set(cvgOptId, CoverageLinkedDriver.#compare({ base: from, compare: to, comparisonLayer, baseLayer }));
            }
        }

        return map;
    }

    static #compare({
        base,
        compare,
        comparisonLayer,
        baseLayer,
    }: Partial<Comparison.IOptions.Entity<CoverageLinkedDriver>>): CoverageLinkedDriver.IComparisonReturn {
        const comparison = new Comparison(CoverageLinkedDriver, base, compare) as CoverageLinkedDriver.IComparisonReturn;
        comparison.setField("driverId", compare?.driverId || base?.driverId);
        comparison.setField("driver", compare?.driver || base?.driver);

        if (!base) {
            if (baseLayer) {
                const allPreviousBaIds = new Set(
                    baseLayer.policy.coverages
                        .map((cvg) => cvg.linkedDrivers)
                        .flat()
                        .map((l) => l.driverId)
                );

                compare.comparison = {
                    isNewToPolicy: !allPreviousBaIds.has(compare.driverId),
                };
            }

            return comparison.setNew({
                obj: compare,
                description: `Add driver ${compare?.toString()}`,
                subComparison: comparison as Comparison<unknown>,
                label: "Driver",
            });
        }

        if (!compare) {
            if (comparisonLayer) {
                const allPreviousBaIds = new Set(
                    comparisonLayer.policy.coverages
                        .map((cvg) => cvg.linkedDrivers)
                        .flat()
                        .map((l) => l.driverId)
                );

                base.comparison = {
                    isDeletedFromPolicy: !allPreviousBaIds.has(base.driverId),
                };
            }

            return comparison.setDelete({
                obj: base,
                description: `Delete driver ${base?.driver?.name}`,
                subComparison: comparison as Comparison<unknown>,
                label: "Driver",
            });
        }

        [
            {
                key: "yearsExperience",
                label: "Years of Experience",
                transform: (v) => v,
            },
            {
                key: "isOwnerOperator",
                label: "IC",
                transform: (v) => (v ? "Yes" : "No"),
                getActionLabel: (v): Comparison.ActionLabel => (v ? "add" : "remove"),
            },
            {
                key: "dateOfHire",
                label: "Date of Hire",
                transform: (v) => v,
            },
        ].forEach(({ key, label, transform, getActionLabel }) => {
            if (base[key] !== compare[key]) {
                comparison.addDiff({
                    type: "change",
                    description: `Update ${label} from ${transform(base[key])} to ${transform(compare[key])}`,
                    label,
                    actionLabel: getActionLabel?.(compare[key]),
                    priority: null,
                    fieldName: key as keyof CoverageLinkedDriver,
                    isArrayField: false,
                    value: {
                        from: base[key],
                        to: compare[key],
                        base,
                        compare,
                    },
                });
            }
        });

        return comparison;
    }

    id: number;
    createdDate: Date;

    driverId: number;
    @Type(() => Driver)
    driver?: Driver;

    attributes?: Attribute[];
    yearsExperience?: number;
    isOwnerOperator?: boolean;
    dateOfHire?: string;

    coverageId: number;
    coverageCreatedDate: Date;
    coverage?: Coverage;

    comparison?: {
        isNewToPolicy?: boolean;
        isDeletedFromPolicy?: boolean;
    };

    toString(): string {
        return this.driver?.toString() || `CoverageLinkedDriver: ${this.driverId}`;
    }

    compare(
        comparisonLinkedDriver: CoverageLinkedDriver,
        options?: Comparison.getBaseAndComparisonObjects.IOptions
    ): Comparison<CoverageLinkedDriver> {
        return CoverageLinkedDriver.#compare(
            Comparison.getBaseAndComparisonObjects(
                {
                    initiatorEntity: this,
                    comparisonEntity: comparisonLinkedDriver,
                },
                options
            )
        );
    }
}

export namespace CoverageLinkedDriver {
    export interface IComparisonReturn extends Comparison<CoverageLinkedDriver> {
        driverId: number;
        driver?: Driver;
    }
}
