import type { Account } from "./Account";
import { Driver } from "./Driver";
import type { EquipmentType } from "./EquipmentType";
import { PendingChange } from "./PendingChange";
import { PolicyAdditionalInterest } from "./PolicyAdditionalInterest";
import { Property } from "./Property";
import { User } from "./User";
import { Vehicle } from "./Vehicle";

export enum VinStatus {
    Unchecked = 0,
    Valid = 1,
    Invalid = 2,
}

export class Tractor {
    id: number;
    createDate: Date;
    updateDate: Date;
    createdByGuid: string;
    createdBy: User;
    submit: boolean;
    isDeleted: boolean;
    accountId: string;
    year: number | null;
    make: string | null;
    typeId: EquipmentType["id"];
    type: EquipmentType;
    vin: string | null;
    acv: boolean;
    comprehensive: boolean;
    collision: boolean;
    value: number | null;
    ownerOperator: boolean | null;
    vinValid: number | null;
    spare: boolean;
    unitNumber: string | null;
    comments: string | null;
    createdByWaypointUserId: string;
    wpUserConfirmationIsNotPpvVanPickup: boolean;

    account?: Account;
    additionalInterests?: PolicyAdditionalInterest[];

    propertyId: number;
    property?: Property;

    drivers?: Driver[];
    pendingChanges?: PendingChange[];

    policyUpcomingDate?: string | null | undefined;
    policyValue?: number | null | undefined;
    policyOwnerOperator?: boolean | null | undefined;
    policySpare?: boolean | null | undefined;
    policyAcv?: boolean | null | undefined;
    policyComprehensive?: boolean | null | undefined;
    policyCollision?: boolean | null | undefined;
    statuses?: AccountEquipmentStatus[];

    coverages?: { policyId: string; coverageOptionId: number; effectiveDate: string; isUpcoming: boolean }[];

    ownerOperatorAPDFirstNamedInsureds?: string[];

    toString(): string {
        return Vehicle.toString(this);
    }
}

export enum AccountEquipmentStatus {
    Covered = 1,
    UpcomingCovered = 2,
    Submission = 3,
    Hidden = 4,
}

export function formatVIN(vin: string | null | undefined): string {
    return (vin || "").replace(/[^a-zA-Z0-9]/g, "").toUpperCase();
}
