import { Type } from "class-transformer";
import { IsBoolean, IsDate, IsEnum, IsOptional, IsString, MaxLength } from "class-validator";
import type { Policy } from "./Policy";
import type { User } from "./User";

export enum PolicyDocumentType {
    Endorsement = "endo",
    Policy = "policy",
    Audit = "audit",
    Invoice = "invoice",
    Proposal = "proposal",
    Signed_Application = "signed-app",
    Quote_As_Sold = "quote-as-sold",
    MCS_90 = "mcs-90",
    Other = "other",
}
export class PolicyDocument {
    static Type = PolicyDocumentType;

    id: string;

    @IsString({ groups: ["CREATE"] })
    policyId: string;

    @IsDate({ groups: ["CREATE"] })
    @Type(() => Date)
    policyCreatedDate: Date;

    policy?: Policy;

    @IsString({ groups: ["CREATE"] })
    @MaxLength(255)
    name?: string;

    @IsOptional()
    @IsString()
    @MaxLength(255)
    description?: string;

    @IsEnum(PolicyDocumentType)
    type: PolicyDocumentType;

    @IsOptional()
    @IsBoolean()
    visibleToClient?: boolean;

    createdDate: Date;
    createdByGuid: string;
    createdBy: User;
    deletedDate?: Date;
    deletedByGuid?: string;
    deletedBy?: User;
}

export interface PolicyDocumentEmailBody {
    ids: string[];
    contactId: number;
}
