import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";
import { NTIA } from "../../util/constants";

export default function OOProgramMembershipCard() {
    const accountId = useAccountId();
    const { data: organization } = api.organization.useById(accountId!);

    if (organization?.ooProgramHolder) {
        return (
            <div className="space-y-3 rounded border border-waypoint-blue-light/50 p-4">
                <div className="flex items-baseline gap-6">
                    <span className="text-lg font-medium">Independent Contractor Program</span>
                    <span className="h-max shrink-0 grow-0 rounded border border-pink-100 bg-pink-50 px-2 py-1 text-sm leading-3 text-pink-700 small-caps">
                        Membership verified
                    </span>
                </div>
                <p className="text-sm font-light">
                    You are a member of <span className="font-bold">{organization.ooProgramHolder.company.name}'s</span> Independent
                    Contractor Program
                </p>
                <p className="ml-8 text-sm text-stone-500">
                    If you have any questions or concerns or wish to leave this program, please call your agent at{" "}
                    <a href={`tel:${NTIA.phone}`} className="underline">
                        {NTIA.phoneFormatted}
                    </a>
                </p>
            </div>
        );
    }

    return null;
}
