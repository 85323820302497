import { IftaQuarterUpload } from "@deathstar/types/northstar";
import { PlusCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { Dialog } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Alert } from "../alert/alert";
import { PrimaryButton } from "../primaryButton/primaryButton";

const labels = [
    "Thinking...",
    "Processing your request...",
    "Crunching data...",
    "One moment, please...",
    "Analyzing...",
    "Hold tight, working on it...",
    "Almost there...",
    "Loading response...",
    "Give me a second...",
    "Formulating an answer...",
    "Hang on, calculating...",
    "Bringing up results...",
    "Decoding the info...",
    "Piecing things together...",
    "Just a moment...",
    "AI is on it...",
    "Working my magic...",
    "Stay tuned, response incoming...",
    "Refining the answer...",
    "Fetching details...",
];

export function IFTAUploadDialog({
    open,
    onClose,
    isLoading,
    filesLength,
    results,
}: {
    open: boolean;
    onClose(): void;
    isLoading: boolean;
    filesLength: number;
    results: IftaQuarterUpload[] | null;
}) {
    const [index, setIndex] = useState(() => Math.floor(Math.random() * labels.length));

    useEffect(() => {
        const int = setInterval(() => {
            setIndex((i) => (i + 1) % labels.length);
        }, 10_000);
        return () => clearInterval(int);
    }, []);

    return (
        <Dialog open={open} classes={{ paper: "p-6 w-screen !max-w-lg" }}>
            <div className="flex flex-col items-center gap-6">
                <p className="w-full text-start">
                    Uploading {filesLength} file{filesLength > 1 && "s"}
                </p>
                {isLoading && (
                    <div className="flex flex-col items-center gap-4">
                        {/* from https://samherbert.net/svg-loaders/ */}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 57 57"
                            className="stroke-navigator-blue"
                        >
                            <g fill="none" fillRule="evenodd">
                                <g transform="translate(1 1)" strokeWidth="2">
                                    <circle cx="5" cy="50" r="5">
                                        <animate
                                            attributeName="cy"
                                            begin="0s"
                                            dur="2.2s"
                                            values="50;5;50;50"
                                            calcMode="linear"
                                            repeatCount="indefinite"
                                        />
                                        <animate
                                            attributeName="cx"
                                            begin="0s"
                                            dur="2.2s"
                                            values="5;27;49;5"
                                            calcMode="linear"
                                            repeatCount="indefinite"
                                        />
                                    </circle>
                                    <circle cx="27" cy="5" r="5">
                                        <animate
                                            attributeName="cy"
                                            begin="0s"
                                            dur="2.2s"
                                            from="5"
                                            to="5"
                                            values="5;50;50;5"
                                            calcMode="linear"
                                            repeatCount="indefinite"
                                        />
                                        <animate
                                            attributeName="cx"
                                            begin="0s"
                                            dur="2.2s"
                                            from="27"
                                            to="27"
                                            values="27;49;5;27"
                                            calcMode="linear"
                                            repeatCount="indefinite"
                                        />
                                    </circle>
                                    <circle cx="49" cy="50" r="5">
                                        <animate
                                            attributeName="cy"
                                            begin="0s"
                                            dur="2.2s"
                                            values="50;50;5;50"
                                            calcMode="linear"
                                            repeatCount="indefinite"
                                        />
                                        <animate
                                            attributeName="cx"
                                            from="49"
                                            to="49"
                                            begin="0s"
                                            dur="2.2s"
                                            values="49;5;27;49"
                                            calcMode="linear"
                                            repeatCount="indefinite"
                                        />
                                    </circle>
                                </g>
                            </g>
                        </svg>
                        <p className="text-gray-500">{labels[index]}</p>
                    </div>
                )}
                {results && (
                    <div className="space-y-4">
                        {results.map((result) => (
                            <div key={result.fileName}>
                                <div className="flex gap-4">
                                    {!result.iftaQuarters.length ? (
                                        <p className="font-medium text-red-600">Failure</p>
                                    ) : result.error ? (
                                        <p className="font-medium text-yellow-600">Partial success</p>
                                    ) : (
                                        <p className="font-medium text-emerald-600">Success</p>
                                    )}
                                    <p className="text-sm text-gray-500">{result.fileName}</p>
                                </div>
                                {(result.error || !result.iftaQuarters.length) && (
                                    <div className="flex items-center gap-2">
                                        <XCircleIcon className="h-4 w-4 text-red-600" />
                                        {result.error || "An unknown error occurred"}
                                    </div>
                                )}
                                {result.iftaQuarters.length > 0 && (
                                    <div className="flex items-center gap-2">
                                        <PlusCircleIcon className="h-4 w-4 text-emerald-600" />
                                        {result.iftaQuarters.map((q) => (
                                            <span className="rounded-full bg-gray-100 px-1 text-lg leading-none small-caps">
                                                {q.year} Q{q.quarter}
                                            </span>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}
                {!isLoading &&
                    (results?.some((r) => r.iftaQuarters.length) ? (
                        <>
                            <Alert variant="info" className="w-full">
                                Navigator AI does it's best to read and enter the uploaded PDFs but it's not perfect.{" "}
                                <span className="font-bold">Always make sure to review all created quarters.</span>
                            </Alert>
                            <PrimaryButton onClick={onClose}>I understand</PrimaryButton>
                        </>
                    ) : (
                        <PrimaryButton onClick={onClose}>Close</PrimaryButton>
                    ))}
            </div>
        </Dialog>
    );
}
