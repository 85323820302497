import { BusinessAuto, Coverage, Tractor, Trailer } from "@deathstar/types/northstar";
import { BusinessAutoDto, ToolDto, TractorDto, TrailerDto } from "@deathstar/types/waypoint";
import { ActionButton, Checkbox, classNames, InputField, PrimaryButton, useSnackbar } from "@deathstar/ui";
import { ArrowRightCircleIcon, BuildingLibraryIcon } from "@heroicons/react/24/outline";
import { CircularProgress } from "@material-ui/core";
import { capitalize, orderBy } from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { FieldError, FormProvider, useForm, useFormContext } from "react-hook-form";
import api from "../../api/api";
import { EquipmentTypeString } from "../../api/queries/equipment";
import { useAccountId } from "../../api/useAccountId";
import { Dialog, DialogProps } from "../../components/dialog/Dialog";
import AddressSelector from "./AddressSelector";
import CoverageSelector from "./CoverageSelector";
import { LossPayeeSelect } from "./LossPayeeSelect";

export function NewUnitDialog({
    open,
    onClose,
    unitType,
    dialogProps,
    defaultValues,
    editingId,
    coverageFilter,
    getExistingUnit,
}: {
    open: boolean;
    onClose(): void;
    unitType: EquipmentTypeString;
    dialogProps?: Partial<Omit<DialogProps, "open" | "onClose" | "children">>;
    defaultValues?: Partial<TractorDto | TrailerDto | BusinessAutoDto | ToolDto>;
    editingId?: number;
    coverageFilter?: (coverage: Coverage) => boolean;
    /**
     * This is used to check if the VIN entered matches any existing unit. We cannot just query Blazar for this since
     * the context matters (i.e. if this is used on the Monthly Report, the coverages on the unit are different than the coverages
     * that would be returned from just querying the current active equipment list).
     *
     * If a match is found, it is assumed that the user wants to edit the existing unit, and the form is reset with the existing
     * unit's data.
     *
     * If no match is found, a query is made to Blazar to see if the license matches any non-active unit on the account. Then,
     * if a match is found, the form is reset with the existing unit's data.
     */
    getExistingUnit?: (vin: string) => Tractor | Trailer | BusinessAuto | undefined;
}) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            {...dialogProps}
            className={classNames("w-screen p-4 md:max-w-[1080px]", dialogProps?.className)}
        >
            <NewUnitForm
                onClose={onClose}
                unitType={unitType}
                defaultValues={defaultValues}
                editingId={editingId}
                coverageFilter={coverageFilter}
                getExistingUnit={getExistingUnit}
            />
        </Dialog>
    );
}

function NewUnitForm({
    onClose,
    unitType,
    defaultValues,
    editingId,
    coverageFilter,
    getExistingUnit,
}: {
    onClose(): void;
    unitType: EquipmentTypeString;
    defaultValues?: Partial<TractorDto | TrailerDto | BusinessAutoDto | ToolDto>;
    editingId?: number;
    coverageFilter?: (coverage: Coverage) => boolean;
    getExistingUnit?: (vin: string) => Tractor | Trailer | BusinessAuto | undefined;
}) {
    const accountId = useAccountId();
    const create = api.equipment.useCreate(unitType, accountId!, {
        onSuccess: () => {
            onClose();
        },
        onError: (error) => {
            console.error(error);
            if (error.status === 403) {
                useSnackbar.add("You do not have permission to manage equipment.", { variant: "error" });
            } else {
                useSnackbar.add("Could not add unit. Please try again later.", { variant: "error" });
            }
        },
    });
    const update = api.equipment.useUpdate(unitType, accountId!, {
        onSuccess: () => {
            onClose();
        },
        onError: (error) => {
            console.error(error);
            if (error.status === 403) {
                useSnackbar.add("You do not have permission to manage equipment.", { variant: "error" });
            } else {
                useSnackbar.add("Could not update unit. Please try again later.", { variant: "error" });
            }
        },
    });
    const form = useForm<TractorDto | TrailerDto | BusinessAutoDto | ToolDto>({
        defaultValues: {
            coverages: [],
            apdLossPayees: [],
            ...defaultValues,
        },
    });
    const [addingLossPayees, setAddingLossPayees] = useState(false);

    const vin = form.watch("vin");
    const [existingUnitId, setExistingUnitId] = useState<number | null>(null);
    const isEditing = !!editingId || !!existingUnitId;

    useEffect(() => {
        if (!editingId && unitType !== "tools" && vin && vin.length === 17 && !existingUnitId) {
            // first check if the unit exists in the current context's equipment list
            // this would be the table's data from the Monthly Report or from the Equipment page
            const unit = getExistingUnit!(vin); // getExistingUnit is required if editingId is not provided, this will crash otherwise
            if (unit) {
                setExistingUnitId(unit.id);
                useSnackbar.add("This unit already exists - switched to edit mode", {
                    variant: "info",
                });
                form.reset({
                    coverages: unit.coverages,
                    apdLossPayees:
                        unit.additionalInterests
                            ?.filter((ai) => ai.isLossPayee)
                            .map((ai) => ({
                                additionalInterestId: ai.additionalInterest!.id,
                                remove: false,
                                name: ai.additionalInterest?.name || "",
                                street: ai.additionalInterest?.street || "",
                                street2: ai.additionalInterest?.street2 || "",
                                city: ai.additionalInterest?.city || "",
                                state: ai.additionalInterest?.state || "",
                                zip: ai.additionalInterest?.zip || "",
                                email: ai.additionalInterest?.email || "",
                                fax: ai.additionalInterest?.fax || "",
                            })) ?? [],
                    vin: unit.vin ?? undefined,
                    year: unit.year ?? undefined,
                    make: unit.make ?? undefined,
                    value: unit.policyValue ?? undefined,
                    typeId: unit.typeId ?? undefined,
                    unitNumber: unit.unitNumber ?? undefined,
                    ownerOperator: unit.policyOwnerOperator ?? undefined,
                    propertyId: unit.propertyId,
                });
            } else {
                // otherwise if no match is found, check if the unit exists on the account at all
                api.equipment.findByVIN(unitType, accountId!, vin).then((unit) => {
                    if (unit) {
                        useSnackbar.add("This unit already exists - switched to edit mode", {
                            variant: "info",
                        });
                        setExistingUnitId(unit.id!);
                        form.reset({
                            coverages: [],
                            apdLossPayees: [],
                            vin: unit.vin ?? undefined,
                            year: unit.year ?? undefined,
                            make: unit.make ?? undefined,
                            value: unit.value ?? undefined,
                            typeId: unit.typeId ?? undefined,
                            unitNumber: unit.unitNumber ?? undefined,
                            ownerOperator: unit.ownerOperator ?? undefined,
                            propertyId: unit.propertyId,
                        });
                    }
                });
            }
        }
    }, [accountId, editingId, existingUnitId, form, getExistingUnit, unitType, vin]);

    return (
        <form
            onSubmit={form.handleSubmit((data) => {
                if (isEditing) {
                    if (!update.isPending) {
                        const dirtyFields = Object.fromEntries(
                            Object.entries(data).filter(
                                ([key]) =>
                                    // @ts-expect-error a bit of a hack
                                    form.formState.dirtyFields[key]
                            )
                        );
                        update.mutate({
                            id: editingId || existingUnitId!,
                            atDate: data.atDate,
                            ...dirtyFields,
                        });
                    }
                } else {
                    if (!create.isPending) {
                        create.mutate(data);
                    }
                }
            })}
            className="w-full space-y-4 overflow-auto text-sm"
        >
            <FormProvider {...form}>
                <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
                    <div className="md:col-span-2">
                        {unitType === "tools" ? (
                            <ToolFormFields isEditing={isEditing} />
                        ) : (
                            <UnitFormFields unitType={unitType} isEditing={isEditing} />
                        )}
                    </div>

                    <div className="col-span-1">
                        <p className="text-lg font-medium text-waypoint-blue-dark">Coverage</p>
                        <CoverageSelector dataType={unitType} isEditing={isEditing} coverageFilter={coverageFilter} />
                    </div>
                    {(unitType === "tractors" || unitType === "trailers") && (
                        <>
                            <ActionButton type="button" onClick={() => setAddingLossPayees(true)} className="rounded-lg !py-2">
                                <BuildingLibraryIcon className="h-5 w-5" />
                                <div className="flex w-full items-center justify-between">
                                    Add/Remove loss payees
                                    <span className="text-blue-600">{form.watch("apdLossPayees")!.length}</span>
                                </div>
                            </ActionButton>
                            <LossPayeeSelect open={addingLossPayees} onClose={() => setAddingLossPayees(false)} />
                        </>
                    )}
                </div>
            </FormProvider>

            <div className="flex items-end justify-between pt-4">
                <div>
                    <InputField
                        type="date"
                        label={isEditing ? "Make changes effective on" : "Add to coverage on"}
                        classes={{ inputContainer: "py-2", input: "disabled:opacity-50" }}
                        {...form.register("atDate", {
                            required: "Please select a date",
                            validate: (value) => {
                                const m = moment(value, "YYYY-MM-DD", true);
                                if (!m.isValid()) {
                                    return "Invalid date";
                                }
                                if (m.isAfter(moment(), "day")) {
                                    return "Date cannot be in the future";
                                }

                                if (moment().date() >= 15 && m.isBefore(moment(), "month")) {
                                    return "Date is too far in the past";
                                }

                                if (moment().date() < 15 && m.isBefore(moment().subtract(1, "month"), "month")) {
                                    return "Date is too far in the past";
                                }

                                return true;
                            },
                        })}
                    />
                </div>
                {isEditing ? (
                    <PrimaryButton type="submit" disabled={update.isPending || !form.formState.isDirty} className="w-32">
                        {update.isPending ? (
                            <>
                                <span>Loading</span>
                                <CircularProgress size="1rem" classes={{ circle: "text-white" }} />
                            </>
                        ) : (
                            <>
                                <span>Update</span>
                                <ArrowRightCircleIcon className="h-4 w-4" />
                            </>
                        )}
                    </PrimaryButton>
                ) : (
                    <PrimaryButton
                        type="submit"
                        disabled={create.isPending || (unitType === "tractors" && !form.watch("wpUserConfirmationIsNotPpvVanPickup"))}
                        className="w-32"
                    >
                        {create.isPending ? (
                            <>
                                <span>Loading</span>
                                <CircularProgress size="1rem" classes={{ circle: "text-white" }} />
                            </>
                        ) : (
                            <>
                                <span>Create</span>
                                <ArrowRightCircleIcon className="h-4 w-4" />
                            </>
                        )}
                    </PrimaryButton>
                )}
            </div>
            {Object.values(form.formState.errors).length > 0 && (
                <p className="text-red-600">
                    {Object.entries(form.formState.errors)
                        .map(([key, error]) => {
                            if (key === "apdLossPayees") {
                                return (error as (Record<string, FieldError> | undefined)[])
                                    .map(
                                        (lp, i) =>
                                            lp &&
                                            `Loss Payee #${i + 1}: ${Object.values(lp)
                                                .map((e) => e.message)
                                                .join(", ")}`
                                    )
                                    .filter(Boolean)
                                    .join("; ");
                            } else {
                                return (error as FieldError).message;
                            }
                        })
                        .join("; ")}
                </p>
            )}
        </form>
    );
}

function TypeField({ unitType }: { unitType: EquipmentTypeString }) {
    const form = useFormContext<TractorDto | TrailerDto | BusinessAutoDto | ToolDto>();
    const accountId = useAccountId();
    const { data: types } = api.equipment.useFindTypes(accountId!, { select: (types) => orderBy(types, "name") });
    const filteredTypes = useMemo(
        () =>
            orderBy(
                (types || []).filter(
                    (type) =>
                        (unitType === "tractors" && type.tractor && ["CT", "DT", "HT", "S", "TT", "OTR"].includes(type.code)) ||
                        (unitType === "trailers" &&
                            type.trailer &&
                            [
                                "CST",
                                "CRT",
                                "DST",
                                "FST",
                                "GRT",
                                "HST",
                                "GNT",
                                "LBT",
                                "LST",
                                "LOG",
                                "LGT",
                                "BST",
                                "MST",
                                "SNO",
                                "PST",
                                "RST",
                                "ROF",
                                "TSD",
                                "SST",
                                "VST",
                                "WFT",
                            ].includes(type.code)) ||
                        (unitType === "autos" && type.auto && type.code !== "ICL")
                ),
                "name"
            ),
        [types, unitType]
    );

    return (
        <div className="col-span-6">
            <label htmlFor="typeId">Type</label>
            <select
                id="typeId"
                {...(types ? form.register("typeId", { setValueAs: (value) => (value ? parseInt(value) : null) }) : {})}
                className="form-select w-full rounded-lg border-stone-300 text-sm enabled:cursor-pointer"
            >
                <option value="">Select a type</option>
                {filteredTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                        {capitalize(type.name)} - ({type.code.toUpperCase()})
                    </option>
                ))}
            </select>
        </div>
    );
}

function UnitFormFields({ unitType, isEditing }: { unitType: EquipmentTypeString; isEditing: boolean }) {
    const form = useFormContext<TractorDto | TrailerDto | BusinessAutoDto | ToolDto>();

    return (
        <div>
            <p className="text-lg font-medium text-waypoint-blue-dark">Details</p>
            <div className="grid grid-cols-12 gap-x-4 gap-y-2">
                <InputField
                    className="col-span-6"
                    classes={{ inputContainer: "py-2", input: "disabled:opacity-50" }}
                    required
                    label="VIN"
                    {...form.register("vin")}
                    onChange={(e) => {
                        const vin = e.target.value.replace(/[^0-9a-zA-Z]/g, "").toUpperCase();
                        form.setValue("vin", vin);
                    }}
                    minLength={17}
                    maxLength={17}
                    disabled={isEditing}
                />
                <InputField
                    className="col-span-3"
                    classes={{ inputContainer: "py-2", input: "disabled:opacity-50" }}
                    label="Unit #"
                    {...form.register("unitNumber")}
                />
                <InputField
                    className="col-span-3"
                    classes={{ inputContainer: "py-2", input: "disabled:opacity-50" }}
                    required={unitType !== "tools"}
                    label="Year"
                    {...form.register("year", { setValueAs: (value) => (value ? parseInt(value) : null) })}
                    type="number"
                    min={1900}
                    max={new Date().getFullYear() + 5}
                />
                <InputField
                    className="col-span-6"
                    classes={{ inputContainer: "py-2", input: "disabled:opacity-50" }}
                    required={unitType !== "tools"}
                    label="Make"
                    {...form.register("make")}
                    list="make-options"
                />
                <datalist id="make-options">
                    <option value="CAT"></option>
                    <option value="Caterpillar"></option>
                    <option value="Chevrolet"></option>
                    <option value="Dodge"></option>
                    <option value="Freightliner"></option>
                    <option value="Fontaine"></option>
                    <option value="Ford"></option>
                    <option value="GMC"></option>
                    <option value="Great Dane"></option>
                    <option value="Hyundai"></option>
                    <option value="International"></option>
                    <option value="Kenworth"></option>
                    <option value="Mercedes"></option>
                    <option value="Navistar"></option>
                    <option value="Nissan"></option>
                    <option value="Ottawa"></option>
                    <option value="Pacific"></option>
                    <option value="Peterbilt"></option>
                    <option value="Prostar"></option>
                    <option value="Sterling"></option>
                    <option value="Stoughton"></option>
                    <option value="Trailking"></option>
                    <option value="Vanguard"></option>
                    <option value="Volvo"></option>
                    <option value="Wabash"></option>
                    <option value="WesternStar"></option>
                </datalist>
                <TypeField unitType={unitType} />

                <InputField
                    className="col-span-6"
                    classes={{ inputContainer: "py-2", input: "disabled:opacity-50" }}
                    label="Value"
                    {...form.register("value", { setValueAs: (value) => (value ? parseInt(value) : null) })}
                    type="number"
                    min={0}
                    startAdornment="$"
                />
                <AddressSelector disabled={isEditing} />
                {(unitType === "tractors" || unitType === "trailers") && (
                    <div className="col-span-full space-y-2">
                        <Checkbox
                            classes={{ input: "w-4 h-4", root: "text-sm" }}
                            label="Owned by an independent contractor"
                            {...form.register("ownerOperator")}
                        />
                        {!isEditing && unitType === "tractors" && (
                            <Checkbox
                                classes={{ input: "w-4 h-4" }}
                                required
                                label={
                                    <span>
                                        I confirm this is not a private passenger, van, or pickup vehicle
                                        <span className="text-sm text-red-600"> *</span>
                                    </span>
                                }
                                {...form.register("wpUserConfirmationIsNotPpvVanPickup", { required: true })}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

function ToolFormFields({ isEditing }: { isEditing: boolean }) {
    const form = useFormContext<ToolDto>();

    return (
        <div>
            <p className="text-lg font-medium text-waypoint-blue-dark">Details</p>
            <div className="grid grid-cols-12 gap-x-4 gap-y-2">
                <InputField
                    className="col-span-6"
                    classes={{ inputContainer: "py-2", input: "disabled:opacity-50" }}
                    required
                    label="Description"
                    {...form.register("description")}
                />
                <InputField
                    className="col-span-6"
                    classes={{ inputContainer: "py-2", input: "disabled:opacity-50" }}
                    label="Serial #"
                    {...form.register("serialNumber")}
                />
                <InputField
                    className="col-span-2"
                    classes={{ inputContainer: "py-2", input: "disabled:opacity-50" }}
                    label="Year"
                    {...form.register("year", { setValueAs: (value) => (value ? parseInt(value) : null) })}
                    type="number"
                    min={1900}
                    max={new Date().getFullYear() + 5}
                />
                <InputField
                    className="col-span-5"
                    classes={{ inputContainer: "py-2", input: "disabled:opacity-50" }}
                    label="Make"
                    {...form.register("make")}
                    list="make-options"
                />
                <InputField
                    className="col-span-5"
                    classes={{ inputContainer: "py-2", input: "disabled:opacity-50" }}
                    label="Model"
                    {...form.register("model")}
                />

                <InputField
                    className="col-span-6"
                    classes={{ inputContainer: "py-2", input: "disabled:opacity-50" }}
                    label="Value"
                    {...form.register("value", { setValueAs: (value) => (value ? parseInt(value) : null) })}
                    type="number"
                    min={0}
                    startAdornment="$"
                />
                <AddressSelector disabled={isEditing} />
            </div>
        </div>
    );
}
