import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Button } from "@material-ui/core";
import { classNames } from "../classNames/classNames";
import { InputField, InputFieldProps } from "../inputField/inputField";
import type { DataTableProps } from "./DataTable";

export function Search<T>({
    table,
    onClearFilters,
    ...props
}: Pick<DataTableProps<T>, "table"> & Omit<InputFieldProps, "value" | "onChange" | "ref"> & { onClearFilters?: () => void }): JSX.Element {
    const tableState = table.getState();

    return (
        <div className="flex gap-2">
            {(tableState.columnFilters?.length > 0 || tableState.globalFilter) && (
                <Button
                    onClick={() => {
                        table.setGlobalFilter("");
                        table.setColumnFilters([]);
                        onClearFilters?.();
                    }}
                >
                    Clear Filters
                </Button>
            )}
            <InputField
                {...props}
                className={props.className}
                value={tableState.globalFilter || ""}
                onChange={(e) => table.setGlobalFilter(e.target.value)}
                startAdornment={<MagnifyingGlassIcon className="h-4 w-4 shrink-0 text-black/40" />}
                endAdornment={
                    <button type="button" onClick={() => table.setGlobalFilter("")}>
                        <XMarkIcon
                            className={classNames("h-4 w-4 shrink-0 text-black/40 hover:cursor-pointer hover:text-black", {
                                invisible: !tableState.globalFilter,
                            })}
                        />
                    </button>
                }
                type="search"
            />
        </div>
    );
}
